import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addUserSaga } from './saga';
import {
  AddUserState,
  IGroups,
  IUserCreation,
  IUserCreationPayload,
} from './types';

export const initialState: AddUserState = {
  getGroups: {
    loading: false,
    success: false,
    error: false,
  },
  createUser: {
    loading: false,
    success: false,
    errored: false,
  },
};

const slice = createSlice({
  name: 'addUser',
  initialState,
  reducers: {
    getGroups(state) {
      state.getGroups.loading = true;
      state.getGroups.success = false;
      state.getGroups.error = false;
      state.getGroups.data = undefined;
    },
    getGroupsSuccess(state, action: PayloadAction<IGroups[]>) {
      state.getGroups.loading = false;
      state.getGroups.success = true;
      state.getGroups.error = false;
      state.getGroups.data = action.payload;
    },
    getGroupsFailed(state, action: PayloadAction<any>) {
      state.getGroups.loading = false;
      state.getGroups.success = false;
      state.getGroups.error = true;
    },
    createUser(state, action: PayloadAction<IUserCreationPayload>) {
      state.createUser.loading = true;
      state.createUser.success = false;
      state.createUser.error = false;
      state.createUser.data = undefined;
    },
    createUserSuccess(state, action: PayloadAction<IUserCreation>) {
      state.createUser.loading = false;
      state.createUser.success = true;
      state.createUser.error = false;
      state.createUser.data = action.payload;
    },
    createUserFailed(state, action: PayloadAction<any>) {
      state.createUser.loading = false;
      state.createUser.success = false;
      state.createUser.errored = true;
      state.createUser.error = action.payload;
    },
  },
});

export const { actions: addUserActions } = slice;

export const useAddUserSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addUserSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddUserSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
