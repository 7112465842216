import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePublicLoginSlice } from './slice';
import { useMsal } from '@azure/msal-react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import logo from '../../../../assets/images/logo@3x.png';
import ReactGA from 'react-ga4';
import { loginRequest } from '../../../../azureConfig';

export default function PublicLogin() {
  const dispatch = useDispatch();
  const { actions } = usePublicLoginSlice();
  const { instance, inProgress } = useMsal();
  const { t } = useTranslation();
  const i18nKey = _.public.login;
  useEffect(() => {
    dispatch(actions.getVersion());
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [actions, dispatch]);

  const handleLoginPopup = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: '/',
      })
      .catch(error => {
        console.log('Error while opening popup', error);
      });
  };

  if (inProgress === 'login') {
    return <span>{t(i18nKey.inProgress)}</span>;
  } else {
    return (
      <div className="public-login">
        <img src={logo} className="logo" alt="elistair logo" />
        <p className="users">{t(i18nKey.users)}</p>
        <p className="title">{t(i18nKey.title)}</p>
        <h2 className="subtitle">{t(i18nKey.subtitle)}</h2>
        <svg
          onClick={handleLoginPopup}
          viewBox="0 0 36 36"
          className="circle-content"
        >
          <path
            className="circle-big"
            strokeDasharray="75, 100"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle-small"
            strokeDasharray="75, 100"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className="text">
            {t(i18nKey.ctaTitle)}
          </text>
        </svg>
      </div>
    );
  }
}
