import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { listUserActions as actions } from '.';

function* getUsers() {
  const requestURL = `/api/admin/users`;
  try {
    const response: any = (yield call(request, requestURL, {
      method: 'GET',
    })).data;
    let formatedResponse = response
      .filter(res => res.firstname !== undefined && res.lastname !== undefined)
      .map((item, index) => {
        return { idLocal: index, ...item };
      });

    yield put(actions.getUsersSuccess(formatedResponse));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getUsersFailed(err));
  }
}

export function* listUsersSaga() {
  yield takeLatest(actions.getUsers.type, getUsers);
}
