import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import request, { ValidationError } from 'utils/request';
import { dashboardActions as actions } from '.';
import {
  IOrderMediaByIdArray,
  IProduct,
  IVideo,
  MediasWithCode,
} from './types';

function* getProducts() {
  const requestURL = `/api/dashboard`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL)).data;
    yield put(actions.getProductsSuccess(response));
  } catch (err) {
    console.error('error', err);
    if (err instanceof ValidationError) {
      yield put(actions.getProductsFailed(err.toJSON()));
    } else {
      yield put(actions.getProductsFailed(err));
    }
  }
}

function* getGuidelineURL() {
  const requestURL = `/api/media/guidelineURL`;
  try {
    const response: {
      url: string;
    } = (yield call(request, requestURL)).data;
    yield put(actions.getGuidelineURLSuccess(response));
  } catch (err) {
    console.error('error', err);
    if (err instanceof ValidationError) {
      yield put(actions.getGuidelineURLFailed(err.toJSON()));
    } else {
      yield put(actions.getGuidelineURLFailed(err));
    }
  }
}

function* publishProduct(
  action: PayloadAction<{
    id: number;
    mediaFolders: MediasWithCode[];
    shouldUpdate: boolean;
  }>,
) {
  const { id, mediaFolders, shouldUpdate } = action.payload;

  let requestURL = `/api/products/finalize/create`;
  let body = JSON.stringify({
    productId: id,
    language: 'eng',
  });

  if (shouldUpdate) {
    let subfolderOrder = mediaFolders
      .map(e => {
        if (e.subfolder) {
          return e.subfolder.map((t, i) => ({
            id: t.id,
            mediaFolderId: e.id,
            order: i + 1,
          }));
        }
      })
      .filter(f => f)[0];

    requestURL = `/api/products/${id}/media-folders`;
    body = JSON.stringify({
      newOrder: mediaFolders.map((mf, i) => ({
        order: mf.order! + 1 || i,
        id: mf.id,
      })),
    });
    if (subfolderOrder?.length) {
      body = JSON.stringify({
        newOrder: mediaFolders.map((mf, i) => ({
          order: mf.order! + 1 || i,
          id: mf.id,
        })),
        subfolderOrder: subfolderOrder,
      });
    }
  }

  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: shouldUpdate ? 'PUT' : 'POST',
      body: body,
    })).data;
    yield put(actions.publishProductSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.publishProductFailed());
  }
}

function* addVideo(action: PayloadAction<IVideo>) {
  const requestURL = `/api/media/video`;
  try {
    const response: IProduct = (yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    })).data;
    yield put(actions.addVideoSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.addVideoFailed(err));
  }
}

function* deleteProduct(action: PayloadAction<string>) {
  const requestURL = `/api/products/${action.payload}`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: 'DELETE',
    })).data;
    yield put(actions.deleteProductSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteProductFailed());
  }
}

function* getProductDetail(productPayload: PayloadAction<string>) {
  const requestURL = `/api/dashboard/${productPayload.payload}`;
  try {
    const response: IProduct = (yield call(request, requestURL)).data;

    const mediaFolders = Object.keys(response.mediaFolders)
      ?.map(mf => ({
        ...response.mediaFolders[mf],
        code: mf,
      }))
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });

    mediaFolders?.map(mediaFolder => {
      mediaFolder?.medias?.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });
      mediaFolder?.subfolder?.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });
      mediaFolder?.subfolder?.map(mediaSubfolder =>
        mediaSubfolder?.medias?.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        }),
      );
    });

    response.mediaFolders = mediaFolders;

    yield put(actions.getProductDetailSuccess(response));
  } catch (err) {
    console.error('error', err);
    if (err instanceof ValidationError) {
      yield put(actions.getProductDetailFailed(err.toJSON()));
    } else {
      yield put(actions.getProductDetailFailed(err));
    }
  }
}

function* orderMedia(
  action: PayloadAction<{
    newOrder: IOrderMediaByIdArray[];
  }>,
) {
  let requestURL = `/api/media/update-order`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({ newOrder: action.payload.newOrder }),
    })).data;
    yield put(actions.orderMediaSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.orderMediaFailed());
  }
}

function* deleteMedia(
  action: PayloadAction<{
    productId: string;
    ids: Array<number>;
  }>,
) {
  let requestURL = `/api/media/delete`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: 'DELETE',
      body: JSON.stringify({
        productId: action.payload.productId,
        language: 'eng',
        mediaIds: action.payload.ids,
      }),
    })).data;
    yield put(actions.deleteMediaSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteMediaFailed());
  }
}
function* deleteSubFolder(
  action: PayloadAction<{
    productId: string;
    ids: Array<number>;
  }>,
) {
  let requestURL = `/api/media/delete-subFolder`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: 'DELETE',
      body: JSON.stringify({
        productId: action.payload.productId,
        language: 'eng',
        subFolderIds: action.payload.ids,
      }),
    })).data;
    yield put(actions.deleteSubFolderSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteSubFolderFailed());
  }
}

function* deleteFolder(
  action: PayloadAction<{
    productId: string;
    ids: Array<number>;
  }>,
) {
  let requestURL = `/api/media/delete-folder`;
  try {
    const response: Array<IProduct> = (yield call(request, requestURL, {
      method: 'DELETE',
      body: JSON.stringify({
        productId: action.payload.productId,
        language: 'eng',
        folderIds: action.payload.ids,
      }),
    })).data;
    yield put(actions.deleteFolderSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteFolderFailed());
  }
}
function* updateMediaFolderName(action: PayloadAction<any>) {
  let mediaFolderId = action.payload.mediaFolderId;
  let requestURL = `/api/media/${mediaFolderId}/update`;

  let body: any = { newName: action.payload.newName };
  if (action.payload.subFolderId) {
    body.subFolderId = action.payload.subFolderId;
  }
  try {
    const response: Array<any> = (yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })).data;

    let actionPayload = {
      payload: action.payload.productId,
      type: actions.getProductDetail.type,
    };
    yield put(actionPayload);
    yield put(actions.updateMediaFolderNameSuccess(response));
  } catch (err: any) {
    yield put(actions.updateMediaFolderNameFailed());
  }
}

export function* dashboardSaga() {
  yield takeEvery(actions.addVideo.type, addVideo);
  yield takeEvery(actions.deleteProduct.type, deleteProduct);
  yield takeEvery(actions.deleteMedia.type, deleteMedia);
  yield takeEvery(actions.deleteSubFolder.type, deleteSubFolder);
  yield takeEvery(actions.deleteFolder.type, deleteFolder);
  yield takeEvery(actions.publishProduct.type, publishProduct);
  yield takeEvery(actions.getProducts.type, getProducts);
  yield takeEvery(actions.getProductDetail.type, getProductDetail);
  yield takeEvery(actions.getGuidelineURL.type, getGuidelineURL);
  yield takeEvery(actions.orderMedia.type, orderMedia);
  yield takeEvery(actions.updateMediaFolderName.type, updateMediaFolderName);
}
