import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import ArrowUpIcon from 'assets/icons/arrow-up-small.svg';
import ArrowDownIcon from 'assets/icons/arrow-down-small.svg';
import { useOnClickOutside } from 'usehooks-ts';

export interface OptionType<T = any> {
  label: string;
  value: T;
  checked: boolean;
}
export interface IElistairSelect {
  data?: OptionType[];
  label?: string;
  value?: OptionType;
  className?: string;
  caption?: string;
  onChange: (v?: any) => void;
  errored?: boolean;
}

export default function MultiSelectFilter({
  data,
  label,
  value,
  className,
  errored,
  onChange,
}: IElistairSelect) {
  const [options, setOptions] = useState(data || []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  useOnClickOutside(ref, () => setIsDropdownOpen(false));

  return (
    <div className={cx('b-select2-container', className)} ref={ref}>
      <div>
        <button
          className={cx({ errored }, 'btn-select')}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          type="button"
        >
          <div className="b-select2-actions">
            <img
              alt="arrow"
              src={isDropdownOpen ? ArrowUpIcon : ArrowDownIcon}
              className="toggle-icon"
              width={17}
            />
          </div>
          {!value?.label && label && <span>{label}</span>}
        </button>
      </div>
      <ul className={cx({ closed: !isDropdownOpen }, 'dropdown-multi-select')}>
        {options.map((option, index) => (
          <li
            className={cx('select-open', 'row-item')}
            key={`${option.value}${index}`}
            onClick={() => {
              const updatedOptions = options.map(opt =>
                opt.value === option.value
                  ? { ...opt, checked: !opt.checked }
                  : opt,
              );
              setOptions(updatedOptions);
              onChange(updatedOptions);
            }}
          >
            <div className="b-checkbox-container">
              <input
                className="b-i-checkbox"
                type="checkbox"
                checked={option.checked}
                onChange={e => e.stopPropagation()} // Prevent the checkbox click from closing the dropdown
              />
              <label>
                {`${option.label.slice(0, 1)}${option.label
                  .slice(1, option.label.length)
                  .toLowerCase()}`}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
