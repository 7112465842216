import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.addUser || initialState;

export const selectAddUser = createSelector([selectSlice], state => state);

export const selectAddUserGroups = createSelector(
  [selectSlice],
  state => state.getGroups,
);
export const selectAddUserCreate = createSelector(
  [selectSlice],
  state => state.createUser,
);
