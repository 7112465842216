import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { listUsersSaga } from './saga';
import { IUser, ListUsersState } from './types';

export const initialState: ListUsersState = {
  listUsers: {
    loading: false,
    success: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: 'listUsers',
  initialState,
  reducers: {
    getUsers(state) {
      state.listUsers.loading = true;
      state.listUsers.success = false;
      state.listUsers.error = false;
      state.listUsers.data = undefined;
    },
    getUsersSuccess(state, action: PayloadAction<IUser[]>) {
      state.listUsers.loading = false;
      state.listUsers.success = true;
      state.listUsers.error = false;
      state.listUsers.data = action.payload;
    },
    getUsersFailed(state, action: PayloadAction<any>) {
      state.listUsers.loading = false;
      state.listUsers.success = false;
      state.listUsers.error = true;
    },
  },
});

export const { actions: listUserActions } = slice;

export const useListUsersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: listUsersSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddUserSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
