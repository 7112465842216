import {
  SilentRequest,
  InteractionStatus,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';

export function useConnexionHandle() {
  const { instance, inProgress, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const accessTokenRequest: SilentRequest = {
      scopes: [
        'https://ElistairB2C.onmicrosoft.com/659c78a8-450e-4c40-9ce3-77bd188bebfb/access_user',
      ],
      //@ts-ignore
      account: accounts[0],
    };
    if (
      !accessToken &&
      inProgress === InteractionStatus.None &&
      accounts.length
    ) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, accessToken]);

  return { accessToken, inProgress };
}
