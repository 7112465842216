import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import FormField from 'app/components/Form/FormField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { translations as _ } from 'locales/translations';
import Dropzone from 'react-dropzone';
import DropDocumentation from 'assets/icons/drop-documentation.svg';
import cx from 'classnames';
import Checkbox from 'app/components/Form/Checkbox';
import ClearLogoBlack from 'assets/icons/clear.svg';
import { useSupportSlice } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useUserInformationsSlice } from 'app/pages/user-informations/slice';
import { selectUserInformationsGet } from 'app/pages/user-informations/slice/selectors';
import { IDeviceType } from '../../slice/types';
import { toast } from 'react-toastify';
import { selectJiraRequest } from '../../slice/selectors';
import DocumentIcon from 'assets/icons/document-icon.svg';
import Loader from 'app/components/Loader';
import CloseSmallIcon from 'assets/icons/close-small.svg';
import FormMultilineField from 'app/components/Form/FormMultilineField';

interface SidebarOverlayProps {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  deviceType?: IDeviceType[];
}

export default function Sidebar({
  isOpen,
  setIsOpen,
  deviceType,
}: SidebarOverlayProps) {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const { handleSubmit } = methods;
  const [tmpAttachment, setTmpAttachment] = useState<
    { fileName: string; temporaryAttachmentId: string }[]
  >([]);
  const [isMultiuploadLoading, setIsMultiuploadLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [moduleSelected, setModuleSelected] = useState<IDeviceType[]>([]);
  const [previewFiles, setPreviewFiles] = useState<any[]>([]);
  const { data: user } = useSelector(selectUserInformationsGet);
  const { loading, success } = useSelector(selectJiraRequest);

  const { actions } = useSupportSlice();
  const { actions: actionsUser } = useUserInformationsSlice();
  useEffect(() => {
    dispatch(actionsUser.getUserInfos());
  }, []);

  useEffect(() => {
    if (success) {
      setIsOpen(false);
    }
  }, [success]);

  useEffect(() => {
    if (!isOpen) {
      methods.reset({ keepValues: false });
      setModuleSelected([]);
      setErrored(false);
      setPreviewFiles([]);
      setTmpAttachment([]);
    }
  }, [isOpen]);

  function onSubmit(data) {
    setErrored(false);
    const fieldValues = data;
    if (!moduleSelected.length) {
      return setErrored(true);
    }
    fieldValues.deviceType = moduleSelected;
    if (user) {
      fieldValues.userEmail = user.email;
    }
    if (tmpAttachment.length) {
      fieldValues.attachment = tmpAttachment;
    }
    dispatch(actions.createJiraTicket(fieldValues));
  }

  async function asyncForEachFile(array, callback) {
    setIsMultiuploadLoading(true);
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    setIsMultiuploadLoading(false);
  }

  async function onDropDocumentation(acceptedFiles) {
    const newVal = acceptedFiles.map(file => {
      return Object.assign(file, {
        preview: file.type.includes('image')
          ? URL.createObjectURL(file)
          : 'none',
      });
    });
    let result = previewFiles.concat(newVal);
    setPreviewFiles(result);

    asyncForEachFile(acceptedFiles, async f => {
      let form = new FormData();
      form.append('file', f, f.name);
      const requestURL = `${process.env.REACT_APP_API_URL}/api/support/request/postAttachment`;
      try {
        const credentials = localStorage.getItem('access_token');
        let response = await fetch(requestURL, {
          method: 'POST',
          body: form,
          credentials: 'include',
          headers: {
            enctype: 'multipart/form-data',
            Authorization: `Bearer ${credentials}`,
          },
        });
        let data = await response.json();
        if (data?.data?.temporaryAttachments?.[0]) {
          setTmpAttachment([
            ...tmpAttachment,
            data.data.temporaryAttachments[0],
          ]);
        } else if (data.errors) {
          let newPreview = previewFiles.filter(p => p.path !== f.name);
          setPreviewFiles(newPreview);
          toast.error(f.name + ': ' + data.errors[0].message);
        }
      } catch (e: any) {
        toast.error(f.name + ': ' + e.message);
      }
    });
  }

  async function deleteAttachment(file) {
    setTmpAttachment(tmpAttachment.filter(f => f.fileName !== file.name));
    setPreviewFiles(previewFiles.filter(p => p.path !== file.name));
  }

  function handleClickCheckbox(device: IDeviceType) {
    JSON.stringify(moduleSelected).includes(JSON.stringify(device))
      ? setModuleSelected(moduleSelected.filter(i => i.value !== device.value))
      : setModuleSelected([...moduleSelected, device]);
  }
  return (
    <>
      {isOpen ? (
        <div onClick={() => setIsOpen(false)} className="blurry-background " />
      ) : null}
      <div
        className={clsx('sidebar-container', {
          'sidebar-container-open': isOpen,
          'sidebar-container-close': !isOpen,
        })}
      >
        <div className="form-wrapper">
          <div className="sidebar-header">
            <h2>{t(i18nKey.addRequest)}</h2>
            <img
              alt="close sidebar"
              onClick={() => setIsOpen(false)}
              src={ClearLogoBlack}
            />
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField fieldName="companyName" required />
              <FormField fieldName="summary" required />
              <legend className="device-type-label">
                {t(_.global.form.deviceTypeLabel)} *
              </legend>
              <div className="container-module-name">
                {deviceType?.map(m => (
                  <Checkbox
                    key={m.id}
                    label={m.value}
                    id={m.id}
                    onClick={() => handleClickCheckbox(m)}
                  />
                ))}
              </div>
              <FormField fieldName="serialNumber" required type="number" />
              <FormField fieldName="secondSerialNumber" type="number" />
              <FormField fieldName="thirdSerialNumber" type="number" />
              <FormField fieldName="eventDate" type="date" />
              <FormMultilineField fieldName="description" required />
              <Dropzone
                onDrop={onDropDocumentation}
                accept={'image/*, video/*, .pdf'}
                multiple={true}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragAccept,
                  isDragReject,
                  isDragActive,
                }) => (
                  <div
                    className={cx('dropzone-documentation', {
                      isDragAccept,
                      isDragReject,
                      isDragActive,
                    })}
                    {...getRootProps()}
                  >
                    {isMultiuploadLoading && (
                      <div className="loading-multiple">
                        {t(_.support.mediaImport)}
                      </div>
                    )}
                    <input {...getInputProps()} />
                    <img
                      alt="upload documentation"
                      src={DropDocumentation}
                      className="upload-doc"
                    />
                    <div className="types-files">
                      <span>.jpeg</span>
                      <span>.png</span>
                      <span>.pdf</span>
                      <span>video</span>
                    </div>
                    <h4>{t(_.productEdit.documentation.drag)}</h4>
                    <h5>{t(_.productEdit.documentation.click)}</h5>
                  </div>
                )}
              </Dropzone>
              {previewFiles.length ? (
                <div className="preview-file-container">
                  {previewFiles?.map((f, index) => {
                    let type = f.type.includes('video')
                      ? 'video'
                      : f.type.includes('pdf')
                      ? 'pdf'
                      : 'doc';
                    return (
                      <div className="preview-card" key={f?.preview + index}>
                        <img
                          src={CloseSmallIcon}
                          className="delete-logo"
                          alt="delete-logo"
                          onClick={() => deleteAttachment(f)}
                          width={30}
                        />
                        <div className="preview">
                          <img
                            src={
                              f?.preview !== 'none' ? f.preview : DocumentIcon
                            }
                            width={f.preview === 'none' ? 40 : 80}
                            height={f.preview === 'none' ? 40 : 70}
                            alt="preview"
                          />
                          {f.preview === 'none' ? <p>{type}</p> : null}
                        </div>
                        <p>{f?.path}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {errored && (
                <span className="error">
                  {t(_.global.form.errors.missingDeviceTypeField)}
                </span>
              )}
              <div className="sidebar-cta-container">
                <button className="b-btn button">
                  {loading || isMultiuploadLoading ? (
                    <Loader size={20} />
                  ) : (
                    t(i18nKey.ctaSendRequest)
                  )}
                </button>
                <button
                  className="b-btn button secondary withBorder"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t(i18nKey.ctaCancel)}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}
