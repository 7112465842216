import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import usePrevious from 'utils/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchSlice } from './slice';
import SearchLogoWhite from 'assets/icons/search-white.svg';
import ClearLogoWhite from 'assets/icons/clear-white.svg';
import ClearLogoBlack from 'assets/icons/clear.svg';
import SearchLogoBlack from 'assets/icons/search-black.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { selectSearch } from './slice/selectors';
import { getColor } from '../../utils';

interface IHeaderSearchBar {
  value?: string;
  colorInputMobile?: boolean;
  colorSearchMobile?: boolean;
}

export default function HeaderSearchBar({ value }: IHeaderSearchBar) {
  const dispatch = useDispatch();
  const { actions } = useSearchSlice();
  let location = useLocation();
  const navigate = useHistory().push;
  const { search: searchedString } = useSelector(selectSearch);

  const { register, watch, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  let search = watch('search');
  let prevSearch = usePrevious(search);
  useEffect(() => {
    if (prevSearch !== search && search.length >= 3) {
      dispatch(actions.getSearchMedia(search));
    }
  }, [search]);

  function handleClearSearch() {
    setValue('search', '');
    dispatch(actions.clearSearch());
  }
  let clearIcon =
    getColor(location.pathname) === 'white' ? ClearLogoWhite : ClearLogoBlack;
  let searchIcon =
    getColor(location.pathname) === 'white' ? SearchLogoWhite : SearchLogoBlack;
  return (
    <div className="search">
      <input
        type="text"
        className="search-input"
        placeholder={'Search by keyword…'}
        defaultValue={searchedString}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            navigate('/search');
          }
        }}
        {...register('search')}
      />
      <div className="search-btn">
        {search ? (
          <img
            alt="settings"
            src={clearIcon}
            className="search-logo"
            onClick={() => handleClearSearch()}
          />
        ) : (
          <img alt="settings" className="search-logo" src={searchIcon} />
        )}
      </div>
    </div>
  );
}
