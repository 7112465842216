import CustomHeader from 'app/components/CustomHeader';
import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useListUsersSlice } from './slice';
import { ReactComponent as AddUserLogo } from 'assets/icons/add-user-white.svg';
import { ReactComponent as FilterLogo } from 'assets/icons/filter.svg';
import { ReactComponent as DeleteUserLogo } from 'assets/icons/menu-delete-normal.svg';
import cx from 'classnames';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import { selectListUsers } from './slice/selectors';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../edit-user/components/DeleteModal';
import { useAddUserSlice } from '../add-user/slice';
import SearchBar from 'app/components/Search/SearchBar';
import Loader from 'app/components/Loader';
import TableUsers from './components/TableUsers';
import FilterUsers from './components/FilterUsers';
import { useMediaQuery } from 'usehooks-ts';

export default function ListUser() {
  const { t } = useTranslation();
  const i18nKey = _.listUsers;
  const dispatch = useDispatch();
  let navigate = useHistory().push;
  const { actions } = useListUsersSlice();
  const { actions: addUserAction } = useAddUserSlice();
  const { data } = useSelector(selectListUsers);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchedTerm, setSearchedTerm] = useState<string>('');

  const [filterActive, setFilterActive] = useState<any[]>([]);

  useEffect(() => {
    dispatch(actions.getUsers());
    dispatch(addUserAction.getGroups());
  }, []);

  function handleOpenFilter() {
    setShowFilter(!showFilter);
  }
  function handleClearSearch() {
    setSearchedTerm('');
  }

  return (
    <div className="list-users">
      <div className="list-users-intro">
        <div className="list-users-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="list-users-content">
        <div className="list-users-header">
          <CustomHeader
            addBack
            showLink={true}
            urlBack="/"
            invertedColor={true}
          />
        </div>
        <div className="list-users-result">
          <div className="action-header">
            <h2>{t(i18nKey.title)}</h2>
            <div className="container-cta">
              {!isMobile && (
                <SearchBar
                  placeholder={t(i18nKey.search)}
                  setSearchedTerm={setSearchedTerm}
                  searchedTerm={searchedTerm}
                />
              )}
              <button
                className="b-btn button secondary withBorder"
                onClick={() => handleOpenFilter()}
              >
                {isMobile ? (
                  <FilterLogo
                    className="logo"
                    fill="#14459b"
                    stroke="#14459b"
                  />
                ) : (
                  t(i18nKey.ctaFilter)
                )}
              </button>
              {!isMobile && (
                <button
                  className={cx('b-btn secondary delete-btn button', {
                    disabled: !selectedUsers.length,
                  })}
                  onClick={() => setModalDelete(true)}
                >
                  <DeleteUserLogo
                    className="logo"
                    fill="#BB0505"
                    stroke="#BB0505"
                  />
                  {t(i18nKey.ctaDelete)}
                </button>
              )}
              <button
                className="b-btn button"
                onClick={() => navigate('/add-user')}
              >
                <AddUserLogo width={10} className="logo" />
                {!isMobile && <span>{t(i18nKey.cta)}</span>}
              </button>
            </div>
          </div>
          {showFilter ? (
            <FilterUsers
              filterActive={filterActive}
              setFilterActive={setFilterActive}
            />
          ) : null}
          {isMobile && (
            <div className="search-mobile">
              <SearchBar
                placeholder={t(i18nKey.search)}
                setSearchedTerm={setSearchedTerm}
                searchedTerm={searchedTerm}
              />
            </div>
          )}
          {data ? (
            <TableUsers
              data={data}
              filterActive={filterActive}
              searchedTerm={searchedTerm}
              setSelectedUsers={setSelectedUsers}
            />
          ) : (
            <div className="loader-container">
              <Loader size={40} />
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        isOpenModal={modalDelete}
        setIsOpenModal={setModalDelete}
        users={selectedUsers}
      />
    </div>
  );
}
