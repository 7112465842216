import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { appActions as actions } from '.';
import { PayloadAction } from '@reduxjs/toolkit';

function* getSearchMedia(action: PayloadAction<any>) {
  const requestURL = `/api/media/search/${action.payload}`;
  try {
    const response: any = (yield call(request, requestURL, {
      method: 'GET',
    })).data;

    yield put(actions.getSearchMediaSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getSearchMediaFailed(err));
  }
}

export function* searchSaga() {
  yield takeLatest(actions.getSearchMedia.type, getSearchMedia);
}
