import React, { useEffect } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import elistairLogo from '../../../assets/images/elistair-logo.svg';
import trainingLogo from '../../../assets/icons/training-logo.svg';
import linkIcon from '../../../assets/icons/link-icon.svg';
import CustomHeader from '../../components/CustomHeader';
import BulletMobile from '../../components/BulletMobile';
import cx from 'classnames';
import { gaCustomEvent, gaPageView } from 'utils/ga';

const ELISTAIR_MOODLE_URL = 'https://elistairtraining.moodlecloud.com/';

export default function Training() {
  const { t } = useTranslation();
  const i18nKey = _;

  function openInNewTab(url: string) {
    gaCustomEvent('external_link', 'open_training_page');
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  const [percent, setPercent] = React.useState({
    big: window.innerWidth < 1024 ? 25 : 75,
    small: window.innerWidth < 1024 ? 50 : 75,
  });

  const handleResize = () => {
    setPercent({
      big: window.innerWidth < 1024 ? 25 : 75,
      small: window.innerWidth < 1024 ? 50 : 75,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    setTimeout(() => {
      // This hides the address bar:
      window.scrollTo(0, 1);
    }, 0);
    gaPageView(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="training">
      <div className="training-intro">
        <div className="training-intro-north">
          <img alt="elistair-logo" src={elistairLogo} />
        </div>
        <div className="training-intro-south">
          <h1>{t(i18nKey.dashboard.title)}</h1>
          <p>{t(i18nKey.dashboard.subtitle1)}</p>
          <p>{t(i18nKey.dashboard.subtitle2)}</p>
        </div>
      </div>
      <div className="training-content">
        <div className="training-header">
          <CustomHeader showLink={true} />
        </div>
        <div className="training-content-middle">
          <div
            className="image-content"
            onClick={() => openInNewTab(ELISTAIR_MOODLE_URL)}
          >
            <svg
              viewBox="0 0 36 36"
              className={`circle-content  rotateIn rotateIn1`}
            >
              <path
                className={cx('circle-big')}
                strokeDasharray={percent.big + ', 100'}
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className={cx('circle-small')}
                strokeDasharray={percent.small + ', 100'}
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
            <div className="image-content-control">
              <img alt="training-logo" src={trainingLogo} />
              <h2>{t(i18nKey.training.title)}</h2>
              <p className="description">{t(i18nKey.training.description)}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => openInNewTab(ELISTAIR_MOODLE_URL)}
          className="join"
        >
          {t(i18nKey.training.cta)}
          <img alt="right" src={linkIcon}></img>
        </button>
        <BulletMobile indexSelected={0} />
      </div>
    </div>
  );
}
