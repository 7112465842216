import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import CustomHeader from 'app/components/CustomHeader';
import clsx from 'clsx';
import Sidebar from '../faq/components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { useSupportSlice } from '../slice';
import { selectUserInformationsGet } from 'app/pages/user-informations/slice/selectors';
import { useUserInformationsSlice } from 'app/pages/user-informations/slice';
import { selectJiraRequest } from '../slice/selectors';
import TableRequest from './components/TableRequest';
import Loader from 'app/components/Loader';
import AddIcon from 'assets/icons/add.svg';
import { useMediaQuery } from 'usehooks-ts';
import { useHistory } from 'react-router-dom';
import ClearLogoBlack from 'assets/icons/clear.svg';

export default function Request() {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useHistory().push;
  const isMobile = useMediaQuery('(max-width: 450px)');

  const { actions: actionsSupport } = useSupportSlice();
  const { actions: actionsUser } = useUserInformationsSlice();

  const { data: user } = useSelector(selectUserInformationsGet);
  const { ticketList: jiraRequest, deviceType, loading } = useSelector(
    selectJiraRequest,
  );
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showSearchDropdown, setShowSearchDropDown] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResultData, setSearchResultData] = useState<any[]>([]);

  useEffect(() => {
    if (user) {
      dispatch(actionsSupport.getJiraTicket(user.email));
    } else {
      dispatch(actionsUser.getUserInfos());
    }
    if (!deviceType?.length) {
      dispatch(actionsSupport.getDeviceType());
    }
  }, [user, deviceType]);

  function handleOnSearchChange(search) {
    setSearchValue(search);
    if (jiraRequest) {
      let searchResult = jiraRequest.filter(a => {
        return a.requestFieldValues
          .find(p => p.fieldId === 'summary')
          .value.toLowerCase()
          .includes(search.toLowerCase());
      });
      if (search.length >= 3 && searchResult) {
        setSearchResultData(searchResult);
        setShowSearchDropDown(true);
      } else {
        setShowSearchDropDown(false);
      }
    }
  }

  function handleClickSearchedRequest(s) {
    navigate(`/request/detail/${s.issueKey}`);

    setShowSearchDropDown(false);
    setSearchValue('');
  }

  return (
    <div className={clsx('support', { 'block-scroll': showSidebar })}>
      <Sidebar
        isOpen={showSidebar}
        setIsOpen={setShowSidebar}
        deviceType={deviceType}
      />
      <div className="support-intro">
        <div className="support-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="support-content">
        <div className="support-header">
          <CustomHeader
            addBack
            showLink={true}
            hideSearch
            urlBack="/"
            invertedColor
          />
        </div>
        <div className="support-body">
          <div className="top-part">
            <h1>{t(i18nKey.title)}</h1>
            <div className="request-top">
              <p className="medium-text">{t(i18nKey.requestDesc)}</p>{' '}
              <button
                className="button-notfound"
                onClick={() => {
                  setShowSidebar(true);
                }}
              >
                {isMobile ? (
                  <img src={AddIcon} alt="add-request-icon" />
                ) : (
                  t(i18nKey.addRequest)
                )}
              </button>
            </div>
            <div className="search-article">
              <div className="search-bar">
                <input
                  type="text"
                  className="search-input-faq hide-mobile"
                  placeholder={t(i18nKey.searchRequestPlaceholder) as string}
                  value={searchValue}
                  onChange={event => handleOnSearchChange(event.target.value)}
                />
                {searchValue ? (
                  <img
                    alt="settings"
                    src={ClearLogoBlack}
                    className="clear-btn"
                    onClick={() => {
                      setSearchValue('');
                      setShowSearchDropDown(false);
                    }}
                  />
                ) : null}
              </div>
              {showSearchDropdown ? (
                <ul className="dropdown-search">
                  {searchResultData.length ? (
                    searchResultData.map(s => (
                      <li
                        className="article-search-title"
                        onClick={() => handleClickSearchedRequest(s)}
                      >
                        <p>{s.issueKey}</p>

                        <p>
                          {
                            s.requestFieldValues.find(
                              p => p.fieldId === 'summary',
                            ).value
                          }
                        </p>
                        <p>
                          {new Date(
                            s.currentStatus.statusDate.jira,
                          ).toLocaleDateString()}
                        </p>
                        <p>{s.reporter.displayName}</p>
                        <div
                          className={`${s.currentStatus.status
                            .toLowerCase()
                            .replaceAll(' ', '-')} status`}
                        >
                          {s.currentStatus.status}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>{t(i18nKey.noResult)}</li>
                  )}
                </ul>
              ) : null}
            </div>
          </div>
          {!loading ? (
            jiraRequest.length ? (
              <TableRequest data={jiraRequest} />
            ) : (
              <div className="empty-request">{t(i18nKey.empty)}</div>
            )
          ) : (
            <div className="loader-container">
              <Loader size={40} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
