import CustomHeader from 'app/components/CustomHeader';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import FormField from '../../../components/Form/FormField';
import Select from 'app/components/Form/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddUserCreate, selectAddUserGroups } from './slice/selectors';
import { useAddUserSlice } from './slice';
import { IGroups, IUserCreationPayload } from './slice/types';
import cx from 'classnames';
import Loader from 'app/components/Loader';
import Toogle from 'app/components/Form/ToggleSwitch';
import usePrevious from 'utils/hooks/usePrevious';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { RootState } from 'types';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import Checkbox from 'app/components/Form/Checkbox';
import { IProduct } from 'app/pages/dashboard/slice/types';
import arrowBack from '../../../../assets/icons/arrow-back.svg';

interface IForm {
  companyName: string;
  firstname: string;
  lastname: string;
  guestEmail: string;
  crmId: string;
  groupId: {
    label: string;
    value: string;
  };
  moodle: boolean;
  quote: boolean;
  password?: string;
  lang?: string;
}

export default function AddUser() {
  const i18nKey = _.addUser;
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useAddUserSlice();
  const { actions: actionsDashboard } = useDashboardSlice();

  const [groupsCreate, setGroupsCreate] = useState<IGroups[] | undefined>([]);

  const { data: groups } = useSelector(selectAddUserGroups);
  const { loading, error, success, data } = useSelector(selectAddUserCreate);
  const methods = useForm();
  const { getValues, control, handleSubmit, watch } = methods;
  const [moduleSelected, setModuleSelected] = useState<IProduct[]>([]);

  const productList = useSelector(
    (state: RootState) => state.dashboard?.getProducts.data,
  );

  useEffect(() => {
    const rolesNotValidate = [
      'COMM',
      'INTERNE',
      'ADMIN',
      'Global Administrator',
      'RESTRICTED_ACCESS',
    ];
    const responseValidate =
      groups &&
      groups.filter(item => !rolesNotValidate.includes(item.displayName));
    setGroupsCreate(responseValidate);
  }, [groups]);

  useEffect(() => {
    dispatch(actions.getGroups());
    dispatch(actionsDashboard.getProducts());
  }, []);

  let prevSuccess = usePrevious(success);
  useEffect(() => {
    if (success && success !== prevSuccess && data) {
      toast.success(t(i18nKey.notification));
      if (
        window.confirm(
          "L'utilisateur a bien été créé. Voici son mot de passe pour se connecter : " +
            data.tmpPassword,
        )
      ) {
        history.goBack();
      }
    }
  }, [success]);

  let prevError = usePrevious(error);
  useEffect(() => {
    if (error && error !== prevError) {
      toast.error(
        t(_.global.form.errors.emailAlreadyExists, {
          email: getValues('guestEmail'),
        }),
      );
    }
  }, [error]);

  function onSubmit(data: IForm) {
    let deviceAccess = moduleSelected.map(e => e.id.toString());
    const payload: IUserCreationPayload = {
      companyName: data.companyName,
      firstname: data.firstname,
      lastname: data.lastname,
      guestEmail: data.guestEmail,
      crmId: data.crmId || null,
      groupId: data.groupId.value,
      moodle: data.moodle || false,
      quote: data.quote || false,
      deviceAccess: deviceAccess,
    };
    if (data.moodle) {
      payload.password = data.password;
      payload.lang = data.lang;
    }
    dispatch(actions.createUser(payload));
  }

  let moodle = watch('moodle');

  function handleClickCheckbox(device: IProduct) {
    JSON.stringify(moduleSelected).includes(JSON.stringify(device))
      ? setModuleSelected(moduleSelected.filter(i => i.id !== device.id))
      : setModuleSelected([...moduleSelected, device]);
  }
  function handleSelectAllProduct() {
    if (productList && moduleSelected.length !== productList.length) {
      setModuleSelected(productList);
    } else if (productList && moduleSelected.length === productList.length) {
      setModuleSelected([]);
    }
  }
  function handleGoBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <div className="add-user">
      <CustomHeader isEdit showLink invertedColor />
      <div className="add-user-content-page">
        <button className="back-button" onClick={e => handleGoBack(e)}>
          <img alt="back" src={arrowBack} /> <p>BACK</p>
        </button>
        <h2>{t(i18nKey.title)}</h2>
      </div>
      <div className="form-wrapper">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField fieldName="companyName" />
            <FormField fieldName="firstname" />
            <FormField fieldName="lastname" />
            <FormField fieldName="guestEmail" type="email" />
            <FormField fieldName="crmId" required={false} />
            <div className="field small">
              <fieldset>
                <legend>{t(_.global.form.groupIdLabel)}</legend>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      options={
                        groupsCreate?.map(g => ({
                          label: g.displayName,
                          value: g.id,
                        })) || []
                      }
                      clearable
                      placeholder={t(_.global.form.groupIdLabel)}
                    />
                  )}
                  name="groupId"
                  control={control}
                />
              </fieldset>
            </div>
            {moodle && (
              <>
                <FormField fieldName="password" />
              </>
            )}
            <Controller
              name="moodle"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Toogle
                  label="Moodle"
                  value={value || false}
                  onChange={onChange}
                />
              )}
            />
            <p className="device-title">{t(i18nKey.deviceTitle)}</p>
            <div className="container-checkbox">
              <Checkbox
                key={10000}
                label={t(i18nKey.allDevice)}
                id={`${Date.now()}`}
                onClick={() => handleSelectAllProduct()}
                checked={moduleSelected.length === productList?.length}
              />
              {productList
                ? productList.map(p => {
                    let value = moduleSelected.find(e => e.id === p.id);
                    return (
                      <Checkbox
                        key={p.id}
                        label={p.translations[0].name}
                        id={p.id.toString()}
                        onClick={() => handleClickCheckbox(p)}
                        checked={!!value}
                      />
                    );
                  })
                : null}
            </div>
            <button
              className={cx('submit', { disabled: loading })}
              type="submit"
            >
              {loading ? <Loader size={20} /> : t(i18nKey.cta)}
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
