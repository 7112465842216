import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import AddDocIcon from 'assets/icons/upload-add.svg';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useSupportSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectJiraRequest } from '../../slice/selectors';
import PreviewAttachment from './PreviewAttachment';
import DocumentIcon from 'assets/icons/document-icon.svg';
import CloseSmallIcon from 'assets/icons/close-small.svg';
import Loader from 'app/components/Loader';

interface ICommentProps {
  commentData?: any;
}

export default function CommentEdit({ commentData }: ICommentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { requestId } = useParams<{ requestId: string }>();
  const { actions: actionsSupport } = useSupportSlice();
  const [tmpAttachment, setTmpAttachment] = useState<any[]>([]);
  const [isMultiuploadLoading, setIsMultiuploadLoading] = useState(false);
  const [editComment, setEditComment] = useState<string>('');
  const { attachment, loadingComment } = useSelector(selectJiraRequest);
  const [previewFiles, setPreviewFiles] = useState<any[]>([]);
  useEffect(() => {
    setEditComment(textData);
  }, []);

  async function asyncForEachFile(array, callback) {
    setIsMultiuploadLoading(true);
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    setIsMultiuploadLoading(false);
  }

  async function onDropDocumentation(acceptedFiles) {
    const newVal = acceptedFiles.map(file => {
      return Object.assign(file, {
        preview: file.type.includes('image')
          ? URL.createObjectURL(file)
          : 'none',
      });
    });
    let result = previewFiles.concat(newVal);
    setPreviewFiles(result);
    asyncForEachFile(acceptedFiles, async f => {
      let form = new FormData();
      form.append('file', f, f.name);

      const requestURL = `${process.env.REACT_APP_API_URL}/api/support/request/postAttachment`;
      try {
        const credentials = localStorage.getItem('access_token');
        let response = await fetch(requestURL, {
          method: 'POST',
          body: form,
          credentials: 'include',
          headers: {
            enctype: 'multipart/form-data',
            Authorization: `Bearer ${credentials}`,
          },
        });
        let data = await response.json();
        if (data?.data?.temporaryAttachments?.[0]) {
          setTmpAttachment([
            ...tmpAttachment,
            data.data.temporaryAttachments[0],
          ]);
        } else if (data.errors) {
          let newPreview = previewFiles.filter(p => p.path !== f.name);
          setPreviewFiles(newPreview);
          toast.error(f.name + ': ' + data.errors[0].message);
        }
        let dataAttachment = {
          body: [data?.data?.temporaryAttachments?.[0]],
          issueKey: requestId,
        };
        dispatch(actionsSupport.addAttachment(dataAttachment));
      } catch (e: any) {
        toast.error(f.name + ': ' + e.message);
        console.error('erreur', f, e);
      }
    });
  }

  let commentAttachment = attachment.length
    ? attachment
        ?.map(att => {
          return commentData?.body?.content
            ?.find(e => e.type === 'mediaGroup')
            ?.content.map(c => {
              if (att?.content?.includes(c.attrs.id)) {
                return att;
              }
            });
        })
        .flat()
        .filter(e => e)
    : [];

  function handleEditComment(e) {
    e.preventDefault();

    let commentAttach = commentData?.body?.content
      ?.find(e => e.type === 'mediaGroup')
      ?.content.map(c => {
        return c.attrs.id;
      })
      .join('/');

    let media = commentAttach
      ? tmpAttachment.map(e => e.temporaryAttachmentId).join('/') +
        '/' +
        commentAttach
      : tmpAttachment.map(e => e.temporaryAttachmentId).join('/');

    let data = {
      body: {
        comment: editComment,
        media: media,
      },
      issueKey: requestId,
      commentId: commentData.id,
    };
    dispatch(actionsSupport.editComment(data));
  }

  let textData = commentData?.body?.content?.find(e => e.type === 'paragraph')
    ?.content?.[0]?.text;

  async function deleteAttachment(file) {
    setTmpAttachment(tmpAttachment.filter(f => f.fileName !== file.name));
    setPreviewFiles(previewFiles.filter(p => p.path !== file.name));
  }

  return (
    <div className="field-container">
      <fieldset className="field edit-comment">
        <textarea
          rows={5}
          id="comment"
          onChange={e => {
            setEditComment(e.target.value);
          }}
          defaultValue={textData || ''}
        />
        {commentAttachment ? (
          <PreviewAttachment
            attachment={commentAttachment}
            loadingAttachment={loadingComment}
            type="comment"
          />
        ) : null}
        {previewFiles.length ? (
          <div className="preview-file-container">
            {previewFiles?.map((f, index) => {
              let type = f.type.includes('video')
                ? 'video'
                : f.type.includes('pdf')
                ? 'pdf'
                : 'doc';
              return (
                <div className="preview-card" key={f?.preview + index}>
                  <img
                    src={CloseSmallIcon}
                    className="delete-logo"
                    alt="delete-logo"
                    onClick={() => deleteAttachment(f)}
                    width={30}
                  />
                  {f?.preview !== 'none' ? (
                    <img
                      src={f.preview}
                      width={130}
                      height={100}
                      alt="preview"
                    />
                  ) : (
                    <div className="preview">
                      <img src={DocumentIcon} width={50} alt="icon-pdf" />
                      <h2>{type}</h2>
                    </div>
                  )}
                  <p>{f?.path}</p>
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="cta-edit" onClick={e => handleEditComment(e)}>
          {isMultiuploadLoading || loadingComment ? (
            <Loader size={20} />
          ) : (
            'Save'
          )}
        </div>
      </fieldset>
      <Dropzone
        onDrop={onDropDocumentation}
        accept={'image/*, video/*, .pdf'}
        multiple={true}
      >
        {({
          getRootProps,
          getInputProps,
          isDragAccept,
          isDragReject,
          isDragActive,
        }) => (
          <div
            className={cx('dropzone-documentation', {
              isDragAccept,
              isDragReject,
              isDragActive,
            })}
            {...getRootProps()}
          >
            {isMultiuploadLoading && (
              <div className="loading-multiple">{t(_.support.mediaImport)}</div>
            )}
            <input {...getInputProps()} />
            <img
              alt="upload documentation"
              src={AddDocIcon}
              className="upload-doc"
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
}
