import React, { useEffect, useRef, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import CustomHeader from 'app/components/CustomHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectHelpCenter, selectJiraRequest } from '../slice/selectors';
import Loader from 'app/components/Loader';
import CategorieBtn from './components/CategorieBtn';
import Sidebar from './components/Sidebar';
import clsx from 'clsx';
import { useSupportSlice } from '../slice';
import { useHistory } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';

export default function FAQ() {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useHistory().push;

  const refResultSearch = useRef(null);
  const { categories, articles } = useSelector(selectHelpCenter);
  const [openCategories, setOpenCategories] = useState<string[]>([]);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const { actions: actionsSupport } = useSupportSlice();
  const { deviceType } = useSelector(selectJiraRequest);

  const [showSearchDropdown, setShowSearchDropDown] = useState(false);
  const [searchResultData, setSearchResultData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  function handleOnSearchChange(search) {
    setSearchValue(search);
    if (articles) {
      let allArticles = articles.map(a => a.articles.data).flat();
      let searchResult = allArticles.filter(a =>
        a.title.toLowerCase().includes(search.toLowerCase()),
      );
      if (search.length >= 3 && searchResult) {
        setSearchResultData(searchResult);
        setShowSearchDropDown(true);
      } else {
        setShowSearchDropDown(false);
      }
    }
  }
  function handleClickSearchedArticle(s) {
    navigate('/article', {
      catId: s.categoryId,
      articleId: s.id,
    });
    setShowSearchDropDown(false);
    setSearchValue('');
  }

  useEffect(() => {
    dispatch(actionsSupport.getHelpCenterCategories());
  }, []);

  useEffect(() => {
    if (categories?.length) {
      const catArraytoString = categories?.map(e => e.id).toString();
      dispatch(actionsSupport.getArticlesbyCategories(catArraytoString));
    }
  }, [categories]);

  useEffect(() => {
    if (!deviceType?.length) {
      dispatch(actionsSupport.getDeviceType());
    }
  }, [deviceType]);

  function handleClickCategorie(id: string) {
    openCategories.includes(id)
      ? setOpenCategories(openCategories.filter(c => c !== id))
      : setOpenCategories([...openCategories, id]);
  }

  useOnClickOutside(refResultSearch, () => setShowSearchDropDown(false));

  return (
    <div className={clsx('support', { 'block-scroll': showSidebar })}>
      <Sidebar
        isOpen={showSidebar}
        setIsOpen={setShowSidebar}
        deviceType={deviceType}
      />
      <div className="support-intro">
        <div className="support-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="support-content">
        <div className="support-header">
          <CustomHeader
            addBack
            showLink={true}
            hideSearch
            urlBack="/"
            invertedColor
          />
        </div>
        <div className="support-body">
          <div className="top-part">
            <h1>{t(i18nKey.helpCenter)}</h1>
            <p className="medium-text">{t(i18nKey.desc)}</p>
            <div className="search-article">
              <div className="search-bar">
                <input
                  type="text"
                  className="search-input-faq"
                  placeholder={t(i18nKey.searchPlaceholder) as string}
                  value={searchValue}
                  onChange={event => handleOnSearchChange(event.target.value)}
                />
                {showSearchDropdown ? (
                  <ul className="dropdown-search" ref={refResultSearch}>
                    {searchResultData.length ? (
                      searchResultData.map(s => (
                        <li
                          className="article-search-title"
                          onClick={() => handleClickSearchedArticle(s)}
                        >
                          {s.title}
                        </li>
                      ))
                    ) : (
                      <li>{t(i18nKey.noResult)}</li>
                    )}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
          <div className="block-container">
            {categories ? (
              categories?.map(cat => (
                <CategorieBtn
                  key={cat.id}
                  cat={cat}
                  openCategories={openCategories}
                  handleClickCategorie={handleClickCategorie}
                />
              ))
            ) : (
              <Loader size={20} />
            )}
          </div>
          <div className="card-notfound">
            <p className="medium-text">{t(i18nKey.notFoundAnswer)}</p>
            <button
              className="button-notfound-faq"
              onClick={() => {
                setShowSidebar(true);
              }}
            >
              {t(i18nKey.addRequest)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
