import { call, put, takeLatest } from 'redux-saga/effects';
import { E_USER_ROLE } from 'types';
import request from 'utils/request';
import { appActions as actions } from '.';
import { ISession } from './types';

function* getSession() {
  const requestURL = `/api/login/info`;
  try {
    const response: ISession = (yield call(request, requestURL)).data;
    if (!response.role) {
      response.role =
        response.role === 'admin'
          ? E_USER_ROLE.ADMIN
          : response.role === 'comm'
          ? E_USER_ROLE.COMM
          : response.role === 'client'
          ? E_USER_ROLE.CLIENT
          : response.role === 'integrator'
          ? E_USER_ROLE.INTEGRATOR
          : response.role === 'distrib'
          ? E_USER_ROLE.DISTRIB
          : response.role === 'interne'
          ? E_USER_ROLE.INTERNE
          : E_USER_ROLE.RESTRICTED_ACCESS;
    }
    yield put(actions.getSessionSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getSessionFailed(err));
  }
}

export function* appSaga() {
  yield takeLatest(actions.getSession.type, getSession);
}
