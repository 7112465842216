import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.app || initialState;

export const selectApp = createSelector([selectSlice], state => state);
export const selectGetSession = createSelector(
  [selectSlice],
  state => state.getSession,
);
export const selectSessionUser = createSelector(
  [selectSlice],
  state => state.getSession.data,
);
export const selectSessionUserRole = createSelector(
  [selectSlice],
  state => state.getSession.data?.role,
);
