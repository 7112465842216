import React from 'react';
import { useHistory } from 'react-router';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/pages/dashboard/slice';

interface IMenuItemProps {
  titleKey: string;
  to: string;
  logo: string;
  show?: boolean;
  newProduct?: boolean;
}
export default function MenuItem({
  titleKey,
  to,
  logo,
  show,
  newProduct,
}: IMenuItemProps) {
  const navigate = useHistory().push;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const i18nKey = _.menu;
  if (!show) {
    return null;
  }

  function handleOnClick() {
    if (newProduct) {
      dispatch(actions.newProduct());
    }
    navigate(to);
  }
  return (
    <button className="option" onClick={() => handleOnClick()}>
      <img alt={t(i18nKey[titleKey])} src={logo} />
      <p>{t(i18nKey[titleKey])}</p>
    </button>
  );
}
