import React from 'react';
import ClearLogoBlack from 'assets/icons/clear.svg';
import SearchLogoBlack from 'assets/icons/search-black.svg';
import { useMediaQuery } from 'usehooks-ts';

interface ISearchBar {
  setSearchedTerm: (value: string) => void;
  searchedTerm: string;
  placeholder?: string;
}

export default function SearchBar({
  setSearchedTerm,
  searchedTerm,
  placeholder,
}: ISearchBar) {
  const mobile = useMediaQuery('(max-width: 767px)');
  function handleClearSearch() {
    setSearchedTerm('');
  }

  const handleClickSearchMobile = () => {
    let elements = document.getElementsByClassName('search-bar-input');
    Array.from(elements).forEach(function (element) {
      let el = element as HTMLElement;
      el.style.width = '230px';
      el.style.padding = '7px 10px 0';
    });
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        className="search-bar-input"
        placeholder={placeholder || 'Search by keyword…'}
        value={searchedTerm}
        onChange={e => setSearchedTerm(e.target.value)}
      />
      <div className="search-bar-btn">
        {searchedTerm ? (
          <img
            alt="settings"
            src={ClearLogoBlack}
            className="search-logo"
            onClick={() => handleClearSearch()}
          />
        ) : mobile ? (
          <img
            alt="settings"
            onClick={handleClickSearchMobile}
            className="search-logo"
            src={SearchLogoBlack}
          />
        ) : (
          <img alt="settings" className="search-logo" src={SearchLogoBlack} />
        )}
      </div>
    </div>
  );
}
