/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

interface IFormFieldProps {
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
}
export default function FormMultilineField({
  fieldName,
  disabled,
  required = false,
  rows = 5,
}: IFormFieldProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const i18nKey = _.global.form;

  return (
    <div className="field">
      <fieldset
        className={cx({
          error: errors[fieldName],
        })}
      >
        <legend>
          {t(i18nKey[`${fieldName}Label`])}
          {required ? ' *' : ''}
        </legend>
        <textarea
          rows={rows}
          className={cx('field', { disabled: disabled })}
          id={fieldName}
          disabled={disabled}
          {...register(
            fieldName as '`${string}` | `${string}.${string}` | `${string}.${number}`',
            {
              required: required,
            },
          )}
        />
      </fieldset>
      {errors[fieldName] && errors[fieldName].type === 'required' && (
        <span className="error">{t(i18nKey.errors.required)}</span>
      )}
    </div>
  );
}
