import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import CloseSmallIcon from 'assets/icons/close-small.svg';
import UploadAdd from 'assets/icons/upload-add.svg';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPushProduct,
  selectPublishProduct,
} from 'app/pages/dashboard/slice/selectors';
import ArrowUpIcon from 'assets/icons/arrow-up.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import { useForm } from 'react-hook-form';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import { requestData } from 'utils/request';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

export default function Description(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const i18nKey = _.productEdit;

  let { product } = useSelector(selectPushProduct);
  const [fileModel3d, setFileModel3d] = useState<any>([]);
  const [fileIllustration, setFileIllustration] = useState<any>([]);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(!product);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  let { loading: publishProductLoading } = useSelector(selectPublishProduct);

  useEffect(() => {
    register('visual_3d', product?.visual_3d ? product.visual_3d : undefined);
    register(
      'visual_flat',
      product?.visual_flat ? product.visual_flat : undefined,
    );
    register('mode');
    register('language');
    setValue('mode', product ? 'update' : 'create');
    setValue('language', 'eng');
    if (product?.visual_3d) {
      setFileModel3d([
        {
          preview: product?.visual_3d,
        },
      ]);
    }
    if (product?.visual_flat) {
      setFileIllustration([
        {
          preview: product?.visual_flat,
        },
      ]);
    }
  }, []);

  async function onSubmit(data) {
    delete data.linkText;
    delete data.linkValue;
    dispatch(actions.pushProduct());
    const method = product ? 'PUT' : 'POST';
    const requestURL = `${process.env.REACT_APP_API_URL}/api/products`;
    try {
      if (product) {
        data = {
          ...data,
          id: product.id,
        };
      }
      if (!data.visual_3d) {
        delete data.visual_3d;
      }
      if (!data.visual_flat) {
        delete data.visual_flat;
      }
      const res = (await requestData(requestURL, data, method)).data;
      dispatch(actions.pushProductSuccess(res));
      setIsDescriptionOpen(false);
      props.setIsDocumentationOpen(true);
    } catch (e) {
      dispatch(actions.pushProductFailed());
    }
  }

  function onDropIllustration(acceptedFiles) {
    const newVal = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setFileIllustration(newVal);
    setValue('visual_flat', newVal[0]);
  }

  function onDropModel3D(acceptedFiles) {
    const newVal = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setFileModel3d(newVal);
    setValue('visual_3d', newVal[0]);
  }

  const thumbsModel = fileModel3d.map((file, index) => (
    <img key={file?.name + index} alt="preview" src={file?.preview} />
  ));

  const thumbsIllu = fileIllustration.map((file, index) => (
    <img
      className="illu"
      key={file?.name + index}
      alt="preview"
      src={file?.preview}
    />
  ));

  return (
    <div className="product-edit-content-page-form">
      <div
        className={cx(
          'product-edit-content-page-form-description product-info',
          {
            isDisabled: publishProductLoading,
          },
        )}
      >
        <div className="product-info-header">
          <div className="product-info-header-left">
            <h4>{t(i18nKey.descriptionTitle)}</h4>
            <h5>{t(i18nKey.descriptionSubtitle)}</h5>
          </div>
          <div className="product-info-header-right">
            <img
              alt="arrow"
              src={isDescriptionOpen ? ArrowUpIcon : ArrowDownIcon}
              className="arrow"
              onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
            />
          </div>
        </div>
        {isDescriptionOpen && (
          <div className="product-info-content">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="product-info-detail"
            >
              <div className="field small">
                <fieldset
                  className={cx({
                    error: errors.name,
                  })}
                >
                  <legend>{t(i18nKey.form.name)}</legend>
                  <input
                    id="name"
                    {...register('name', {
                      required: true,
                    })}
                    defaultValue={product?.translations[0]?.name}
                  />
                </fieldset>
                {errors.name && errors.name.type === 'required' && (
                  <span className="error">{t(i18nKey.form.required)}</span>
                )}
              </div>

              <div className="field small">
                <fieldset
                  className={cx({
                    error: errors.productCode,
                  })}
                >
                  <legend>{t(i18nKey.form.code)}</legend>
                  <input
                    id="productCode"
                    {...register('productCode', {
                      required: true,
                    })}
                    defaultValue={product?.product_code}
                  />
                </fieldset>
                {errors.productCode &&
                  errors.productCode.type === 'required' && (
                    <span className="error">{t(i18nKey.form.required)}</span>
                  )}
              </div>

              <div className="field small">
                <fieldset
                  className={cx({
                    error: errors.kind,
                  })}
                >
                  <legend>{t(i18nKey.form.kind)}</legend>
                  <input
                    id="kind"
                    {...register('kind', {
                      required: true,
                    })}
                    defaultValue={product?.translations[0]?.kind}
                  />
                </fieldset>
                {errors.kind && errors.kind.type === 'required' && (
                  <span className="error">{t(i18nKey.form.required)}</span>
                )}
              </div>

              <div className="field">
                <fieldset
                  className={cx({
                    error: errors.subtitle,
                  })}
                >
                  <legend>{t(i18nKey.form.subtitle)}</legend>
                  <input
                    id="kind"
                    {...register('subtitle', {
                      required: true,
                    })}
                    defaultValue={product?.translations[0]?.subtitle}
                  />
                </fieldset>
                {errors.subtitle && errors.subtitle.type === 'required' && (
                  <span className="error">{t(i18nKey.form.required)}</span>
                )}
              </div>

              <div className="field">
                <fieldset
                  className={cx({
                    error: errors.description,
                  })}
                >
                  <legend>{t(i18nKey.form.description)}</legend>
                  <textarea
                    rows={5}
                    id="description"
                    {...register('description', {
                      required: true,
                    })}
                    defaultValue={product?.translations[0]?.description}
                  />
                </fieldset>
                {errors.description &&
                  errors.description.type === 'required' && (
                    <span className="error">{t(i18nKey.form.required)}</span>
                  )}
              </div>
              <div className="upload-file-containers">
                <div className="uploader">
                  {!!fileModel3d.length && (
                    <>
                      <div
                        className="close-uploader"
                        onClick={() => setFileModel3d([])}
                      >
                        <img alt="close" src={CloseSmallIcon} />
                      </div>
                      {thumbsModel}
                    </>
                  )}
                  {!fileModel3d?.length && (
                    <Dropzone
                      onDrop={onDropModel3D}
                      accept={'image/*'}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className={cx('uploader-content')}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <img
                            alt="upload model"
                            src={UploadAdd}
                            className="upload-add-model"
                          />
                          <h4> {t(i18nKey.form.modelAdd)}</h4>
                          <h5> {t(i18nKey.form.modelTitle)}</h5>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                <div className="uploader">
                  {!!fileIllustration.length && (
                    <>
                      <div
                        className="close-uploader"
                        onClick={() => setFileIllustration([])}
                      >
                        <img alt="close" src={CloseSmallIcon} />
                      </div>
                      {thumbsIllu}
                    </>
                  )}
                  {!fileIllustration?.length && (
                    <Dropzone
                      onDrop={onDropIllustration}
                      accept={'image/*'}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className={cx('uploader-content')}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <img
                            alt="upload model"
                            src={UploadAdd}
                            className="upload-add-model"
                          />
                          <h4> {t(i18nKey.form.illuAdd)}</h4>
                          <h5> {t(i18nKey.form.illulTitle)}</h5>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
              </div>
              <div className="notice">{t(i18nKey.form.notice)}</div>
              <button
                className={cx('submit', {
                  disabled:
                    !isValid || !fileIllustration.length || !fileModel3d.length,
                })}
              >
                {t(i18nKey.form.save)}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
