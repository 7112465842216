import React from 'react';
import { translations as _ } from 'locales/translations';
import BinDelete from 'assets/icons/bin-delete.svg';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import { selectPushProduct } from 'app/pages/dashboard/slice/selectors';
import { useTranslation } from 'react-i18next';

export default function DeleteModal({ isOpenModal, setIsOpenModal }) {
  let { product } = useSelector(selectPushProduct);
  const { actions } = useDashboardSlice();
  const i18nKey = _.productEdit;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      contentLabel="Example Modal"
      className="modalDelete"
      overlayClassName="modalDelete-overlay"
    >
      <img alt="upload model" src={BinDelete} className="upload-add-model" />
      <h4>{t(i18nKey.form.deleteMsg)}</h4>
      <p>{t(i18nKey.form.deleteMsg2)}</p>
      <div>
        <button
          className="b-btn secondary"
          onClick={() => {
            setIsOpenModal(false);
          }}
        >
          {t(i18nKey.form.cancel)}
        </button>
        <button
          className="b-btn"
          onClick={() => {
            dispatch(actions.deleteProduct(product.id));
          }}
        >
          {t(i18nKey.form.confirm)}
        </button>
      </div>
    </Modal>
  );
}
