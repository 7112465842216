import React from 'react';
import CheckSVG from './check.svg';
import cx from 'classnames';

export interface ICheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  errored?: boolean;
  indeterminate?: boolean;
  label: string;
  icon?: JSX.Element;
}

const Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      label,
      errored,
      disabled,
      id,
      indeterminate,
      icon,
      ...rest
    }: ICheckboxProps,
    ref,
  ) => {
    return (
      <div className="b-checkbox-container">
        <input
          className={cx('b-i-checkbox', { errored, disabled, indeterminate })}
          type="checkbox"
          id={id}
          ref={ref}
          disabled={disabled}
          {...rest}
        />
        <label
          style={{ content: `url:${CheckSVG}` }}
          htmlFor={id}
          className={cx('b-checkbox-label', {
            errored,
            disabled,
            indeterminate,
          })}
        >
          {icon ? icon : null}
          {label}
        </label>
      </div>
    );
  },
);

export default Checkbox;
