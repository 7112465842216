import React, { useEffect } from 'react';
import NoSorted from './../../../../../assets/icons/no-sorted.svg';
import AscSorted from './../../../../../assets/icons/asc-sorted.svg';
import DscSorted from './../../../../../assets/icons/dsc-sorted.svg';
import { useMediaQuery } from 'usehooks-ts';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { translations as _ } from 'locales/translations';
import Pagination from './Pagination';
import { IRequest } from '../../slice/types';
import { useHistory } from 'react-router-dom';
import ArrowDown from 'assets/icons/arrow-down-small.svg';
import ArrowUp from 'assets/icons/arrow-up-small.svg';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInformationsGet } from 'app/pages/user-informations/slice/selectors';

export default function TableRequest({ data }) {
  let navigate = useHistory().push;
  const { t } = useTranslation();
  const i18nKey = _.support;

  const isMobile = useMediaQuery('(max-width: 450px)');
  const { data: user } = useSelector(selectUserInformationsGet);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [showRowInfo, setShowRowInfo] = React.useState<string>('');

  useEffect(() => {
    if (isMobile) {
      setColumnVisibility({ updateDate: false, assignee: false });
    } else {
      setColumnVisibility({ 'more-info': false });
    }
  }, [isMobile]);

  function handleShowMore(value) {
    if (showRowInfo !== value.issueKey) {
      return setShowRowInfo(value.issueKey);
    }
    if (showRowInfo === value.issueKey) {
      return setShowRowInfo('');
    }
  }

  const columns = React.useMemo<ColumnDef<IRequest>[]>(
    () => [
      {
        accessorFn: row => row.issueKey,
        id: 'ref',
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Reference</span>,
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => row.requestFieldValues[2].value,
        id: 'summary',
        cell: info => {
          let summary: any = info.getValue();
          return (
            <span className="small-mobile hidden-overflow">{summary}</span>
          );
        },
        header: () => <span>Summary</span>,
        footer: props => props.column.id,
        enableSorting: false,
      },
      {
        accessorFn: row => row.currentStatus.statusDate.jira,
        id: 'updateDate',
        hiddenColumns: !isMobile,
        cell: info => {
          let date: any = info.getValue();
          let formatedDate = new Date(date).toLocaleDateString();
          return <div className="small">{formatedDate}</div>;
        },
        header: () => <span>Update date</span>,
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => row.reporter.displayName,
        id: 'requester',
        hiddenColumns: !isMobile,
        cell: info => {
          return <div>{`${user?.firstname} ${user?.lastname}`}</div>;
        },
        header: () => <span>Requester</span>,
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => row.currentStatus.status,
        id: 'status',
        hiddenColumns: !isMobile,
        cell: info => {
          let status: any = info.getValue();
          let classname = status.toLowerCase().replaceAll(' ', '-');
          return <div className={`${classname} status`}>{status}</div>;
        },
        enableSorting: false,
        header: () => <span>Status</span>,
      },
      {
        id: 'more-info',
        show: isMobile,
        cell: info => {
          return (
            <div onClick={() => handleShowMore(info.row.original)}>
              <img
                alt="show-more-option"
                src={
                  showRowInfo === info.row.original.issueKey
                    ? ArrowUp
                    : ArrowDown
                }
                className="button-show-more"
              />
            </div>
          );
        },
        enableSorting: false,
        header: () => <span></span>,
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    filterFns: {
      groupsFilter: () => true,
      createdDateFilter: () => true,
      connectDateFilter: () => true,
    },
  });

  return (
    <div>
      <table className="table-request">
        {!isMobile ? (
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: (
                              <img
                                alt="asc-sorted"
                                src={AscSorted}
                                className="sorted-icn asc-icn hide-mobile"
                              />
                            ),
                            desc: (
                              <img
                                alt="dsc-sorted"
                                src={DscSorted}
                                className="sorted-icn dsc-icn hide-mobile"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ??
                            (header.column.columnDef.enableSorting ? (
                              <img
                                alt="no-sorted"
                                className="hide-mobile"
                                src={NoSorted}
                              />
                            ) : null)}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        ) : null}
        {table
          ? table
              .getRowModel()
              .rows.slice(0, 10)
              .map(row => {
                return !isMobile ? (
                  <tbody key={row.id}>
                    <tr
                      className="row-request"
                      key={row.id}
                      aria-colspan={4}
                      onClick={() =>
                        navigate(`/request/detail/${row.original.issueKey}`)
                      }
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr
                      className="row-request"
                      key={row.id}
                      onClick={() => handleShowMore(row.original)}
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    {showRowInfo === row.original.issueKey ? (
                      <>
                        <tr className="more-info-row-mobile">
                          <td colSpan={2}>{t(i18nKey.updateDate)}</td>
                          <td colSpan={2}>
                            {new Date(
                              row.original.currentStatus.statusDate.jira,
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                        <tr className="more-info-row-mobile">
                          <td colSpan={2}>{t(i18nKey.assignee)}</td>
                          <td colSpan={2}>
                            {row.original.reporter.displayName}
                          </td>
                        </tr>

                        <tr className="more-info-row-mobile">
                          <td colSpan={2}></td>
                          <td colSpan={2}>
                            <button
                              onClick={() =>
                                navigate(
                                  `/request/detail/${row.original.issueKey}`,
                                )
                              }
                              className="b-btn btn-secondary"
                            >
                              detail
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                );
              })
          : null}
      </table>
      <Pagination table={table} />
    </div>
  );
}
