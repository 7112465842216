import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProductDetail,
  selectPushProduct,
  selectUpdateMediaFolderName,
} from 'app/pages/dashboard/slice/selectors';
import ArrowUpIcon from 'assets/icons/arrow-up.svg';
import deleteIcon from 'assets/icons/menu-delete-normal.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import MediaFile from './MediaFile';
import FormField from 'app/components/Form/FormField';
import { FormProvider, useForm } from 'react-hook-form';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import Loader from 'app/components/Loader';
import usePrevious from 'utils/hooks/usePrevious';
import { MediaObject } from 'app/pages/dashboard/slice/types';

export default function MediaFolder({
  mediaFolder,
  onOrderChange,
  onSubfolderOrderChange,
  index,
  setMediaFile,
  setSubmediaFile,
  setMediaFileOrder,
  setDeletingDocs,
  deletingDocs,
  deletingSubFolders,
  setDeletingSubFolders,
  deletingFolders,
  setDeletingFolders,
  styleFolderDelete,
}) {
  const methods = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useDashboardSlice();
  const mediaKeyTranslations = _.mediakey;
  const { getValues } = methods;

  let { product } = useSelector(selectPushProduct);

  const i18nKey = _.productEdit;
  let { data: productDetail, loading: loadingProductDetail } = useSelector(
    selectProductDetail,
  );
  let { error: errorFolderName, loading: loadingFolderName } = useSelector(
    selectUpdateMediaFolderName,
  );

  const [editFolderName, setEditFolderName] = useState(false);
  const [editSubfolderName, setEditSubfolderName] = useState();
  let mediaLength = productDetail?.mediaFolders
    ? Object.keys(productDetail?.mediaFolders).length - 1
    : 0;

  let mediaFolderName =
    t(mediaKeyTranslations[mediaFolder.code?.toLowerCase()]) ||
    mediaFolder?.code?.charAt(0) + mediaFolder.code?.slice(1)?.toLowerCase();

  let prevMediaFolder = usePrevious(mediaFolder);
  useEffect(() => {
    if (
      !loadingFolderName &&
      !errorFolderName &&
      !loadingProductDetail &&
      prevMediaFolder.code !== mediaFolder.code &&
      editFolderName
    ) {
      setEditFolderName(false);
    }
  }, [
    loadingFolderName,
    errorFolderName,
    loadingProductDetail,
    prevMediaFolder,
    mediaFolder,
    editFolderName,
  ]);

  function handleFolderNameClick(event) {
    if (event.detail === 2 && mediaFolderName !== 'Video') {
      setEditFolderName(true);
    }
  }

  function handleUpdateName(event, mediaFolder, submediaFolder) {
    if (event.key === 'Enter') {
      event.preventDefault();
      let value = getValues(mediaFolder.code);
      let payload: any = {
        mediaFolderId: mediaFolder.id,
        productId: product?.product_code,
        newName: value,
      };
      if (submediaFolder) {
        payload = {
          mediaFolderId: mediaFolder.id,
          productId: product?.product_code,
          newName: getValues(submediaFolder.code),
          subFolderId: submediaFolder.id,
        };
      }
      dispatch(actions.updateMediaFolderName(payload));
    }
  }
  function handleSubFolderClick(event, subfolder) {
    if (event.detail === 2) {
      setEditSubfolderName(subfolder.code);
    } else if (event.detail === 3) {
      setEditSubfolderName(undefined);
    }
  }

  function changeFileOrder(
    fileIndex: number,
    media: MediaObject,
    direction: 'up' | 'down',
  ) {
    let coord = direction === 'up' ? fileIndex - 1 : fileIndex + 1;
    let newMediaFolders = [...mediaFolder.medias];
    newMediaFolders.splice(fileIndex, 1);
    newMediaFolders.splice(coord, 0, media);
    const newMF = newMediaFolders?.map((mf, ind) => ({
      ...mf,
      order: ind,
    }));
    const mediaFileOrder = newMediaFolders?.map((m, i) => ({
      id: m.id,
      order: i,
    }));

    setMediaFile({ mFile: newMF, mFIndex: index });
    setMediaFileOrder(mediaFileOrder);
  }

  function changeSubfileOrder(
    fileIndex: number,
    media: MediaObject,
    direction: 'up' | 'down',
    subIndex?: number,
  ) {
    if (subIndex !== undefined) {
      let coord = direction === 'up' ? fileIndex - 1 : fileIndex + 1;
      let newSubmediaFolders = [...mediaFolder.subfolder[subIndex].medias];

      newSubmediaFolders.splice(fileIndex, 1);
      newSubmediaFolders.splice(coord, 0, media);
      const newSMF = newSubmediaFolders?.map((mf, ind) => ({
        ...mf,
        order: ind,
      }));
      const mediaFileOrder = newSubmediaFolders?.map((m, i) => ({
        id: m.id,
        order: i,
      }));

      setSubmediaFile({ mFile: newSMF, mFIndex: index, mSFIndex: subIndex });
      setMediaFileOrder(mediaFileOrder);
    }
  }

  return (
    <FormProvider {...methods}>
      <form className="documentation-files" key={`${mediaFolder.code}-files`}>
        <div className="documentation-files-title">
          <div className="documentation-files-title-container">
            <div className="documentation-files arrow-container">
              <button
                className={cx('arrow-btn', {
                  disabled: index === 0,
                })}
                onClick={() => onOrderChange(index, mediaFolder, 'up')}
                type="button"
              >
                <img src={ArrowUpIcon} alt="arrow up" />
              </button>
              <button
                className={cx('arrow-btn', {
                  disabled: index === mediaLength,
                })}
                onClick={() => onOrderChange(index, mediaFolder, 'down')}
                type="button"
              >
                <img src={ArrowDownIcon} alt="arow down" />
              </button>
            </div>
            {!editFolderName ? (
              <h4
                className={
                  styleFolderDelete
                    ? 'documentation-files-deleting-subFolder-folder'
                    : ''
                }
                onClick={e => handleFolderNameClick(e)}
              >
                {mediaFolderName}
              </h4>
            ) : (
              <div
                onClick={e => handleFolderNameClick(e)}
                className="edit-folder-name-container"
                onKeyPress={e => handleUpdateName(e, mediaFolder, undefined)}
              >
                <div className="edit-folder-name-row">
                  <FormField
                    fieldName={mediaFolder.code}
                    placeholder={mediaFolderName}
                  />
                  {loadingFolderName || loadingProductDetail ? (
                    <Loader size={20} />
                  ) : null}
                </div>
                {errorFolderName ? (
                  <span className="error">
                    {t(i18nKey.documentation.error)}
                  </span>
                ) : null}
              </div>
            )}
          </div>
          {!deletingFolders.includes(mediaFolder.id) ? (
            <img
              onClick={() => {
                if (!deletingFolders.filter(e => e === mediaFolder.id).length) {
                  setDeletingFolders([...deletingFolders, mediaFolder.id]);
                }
              }}
              alt="file"
              src={deleteIcon}
              className="doc pointer trash"
            />
          ) : null}
        </div>
        <div className="documentation-files-list">
          {mediaFolder?.subfolder?.map((item, i) => (
            <div className="subfolder" key={item.code}>
              <div className="documentation-files-title-sub">
                <div className="documentation-files arrow-name-container">
                  <div className="documentation-files arrow-container">
                    <button
                      className={cx('arrow-btn', {
                        disabled: i === 0,
                      })}
                      onClick={() =>
                        onSubfolderOrderChange(i, item, mediaFolder, 'up')
                      }
                      type="button"
                    >
                      <img src={ArrowUpIcon} alt="arrow up" />
                    </button>
                    <button
                      className={cx('arrow-btn', {
                        disabled: i === mediaFolder?.subfolder.length - 1,
                      })}
                      onClick={() =>
                        onSubfolderOrderChange(i, item, mediaFolder, 'down')
                      }
                      type="button"
                    >
                      <img src={ArrowDownIcon} alt="arow down" />
                    </button>
                  </div>
                  {editSubfolderName !== item.code ? (
                    <h4
                      className={
                        deletingSubFolders.includes(item.id) ||
                        deletingFolders.includes(mediaFolder.id)
                          ? 'documentation-files-deleting-subFolder-folder'
                          : ''
                      }
                      onClick={e => handleSubFolderClick(e, item)}
                    >
                      {item.name}
                    </h4>
                  ) : (
                    <div
                      onClick={e => handleSubFolderClick(e, item)}
                      className={cx('edit-folder-name-container')}
                      onKeyPress={e => handleUpdateName(e, mediaFolder, item)}
                    >
                      <div className="edit-folder-name-row">
                        <FormField
                          fieldName={item.code}
                          placeholder={item.name}
                        />
                        {loadingFolderName || loadingProductDetail ? (
                          <Loader size={20} />
                        ) : null}
                      </div>
                      {errorFolderName ? (
                        <span className="error">
                          {t(i18nKey.documentation.error)}
                        </span>
                      ) : null}
                    </div>
                  )}
                </div>
                {!deletingSubFolders.includes(item.id) &&
                !deletingFolders.includes(mediaFolder.id) ? (
                  <img
                    onClick={() => {
                      if (
                        !deletingSubFolders.filter(e => e === item.id).length
                      ) {
                        setDeletingSubFolders([...deletingSubFolders, item.id]);
                      }
                    }}
                    alt="file"
                    src={deleteIcon}
                    className="doc pointer"
                  />
                ) : null}
              </div>

              <div className="submedia-folder">
                {item.medias
                  ? item?.medias?.map((submedia, ind) => (
                      <MediaFile
                        media={submedia}
                        key={ind}
                        index={ind}
                        subIndex={i}
                        mediaFolder={item}
                        setDeletingDocs={setDeletingDocs}
                        isDeletingFolder={deletingFolders.includes(
                          mediaFolder.id,
                        )}
                        isDeletingSubFolder={deletingSubFolders.includes(
                          item.id,
                        )}
                        deletingDocs={deletingDocs}
                        changeFileOrder={changeSubfileOrder}
                      />
                    ))
                  : null}
              </div>
            </div>
          ))}

          {mediaFolder.medias ? (
            mediaFolder.medias.map((media, i) => (
              <MediaFile
                media={media}
                key={i}
                index={i}
                mediaFolder={mediaFolder}
                mediaFolderCode={mediaFolder.code}
                setDeletingDocs={setDeletingDocs}
                isDeletingFolder={deletingFolders.includes(mediaFolder.id)}
                deletingDocs={deletingDocs}
                changeFileOrder={changeFileOrder}
              />
            ))
          ) : !mediaFolder?.subfolder ? (
            <div>{t(i18nKey.nofile)}</div>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
}
