import React from 'react';
import { useSelect } from 'downshift';
import cx from 'classnames';
import ArrowUpIcon from 'assets/icons/arrow-up-small.svg';
import ArrowDownIcon from 'assets/icons/arrow-down-small.svg';
import CloseSmallIcon from 'assets/icons/close-small.svg';

interface OptionType<T = any> {
  label: string;
  value: T;
}

export interface IElistairSelect {
  options: OptionType[];
  label?: string;
  placeholder?: string;
  value?: OptionType;
  disabled?: boolean;
  clearable?: boolean;
  className?: string;
  onChange: (v?: OptionType | null) => void;
  caption?: string;
  errored?: boolean;
  onInputChange?: (v: string) => void;
  onlyValue?: boolean;
}

export default function ElistairSelect({
  options,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  clearable,
  className,
  caption,
  errored,
  onlyValue,
}: IElistairSelect) {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    initialSelectedItem: value || null,
    onSelectedItemChange: changes => onChange(changes.selectedItem),
  });

  function onClear(e: React.MouseEvent<Element, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    onChange(null);
  }

  return (
    <div
      className={cx('b-select2-container', className, {
        'only-value': onlyValue,
      })}
    >
      {label ? <label {...getLabelProps}>{label}</label> : null}
      {onlyValue && value?.label ? (
        <span className="value">{value.label}</span>
      ) : (
        <>
          <button
            className={cx({ disabled, errored })}
            disabled={disabled}
            type="button"
            {...getToggleButtonProps()}
          >
            {value?.label ? <span className="value">{value.label}</span> : null}
            {!value?.label && placeholder && (
              <span className="placeholder">{placeholder}</span>
            )}
            <div className="b-select2-actions">
              {clearable && options.length > 0 && value && value.value && (
                <img
                  alt="arrow"
                  src={CloseSmallIcon}
                  className="clear-icon"
                  onClick={onClear}
                />
              )}
              <img
                alt="arrow"
                src={isOpen ? ArrowUpIcon : ArrowDownIcon}
                className="toggle-icon"
                onClick={() => getToggleButtonProps().onClick}
              />
            </div>
          </button>
        </>
      )}
      {caption ? (
        <span className={cx('b-select-caption', { errored })}>{caption}</span>
      ) : null}
      <ul
        {...getMenuProps()}
        className={cx({ closed: !isOpen, 'has-label': !!label })}
      >
        {isOpen
          ? options.map((item, index) => (
              <li
                className={cx({
                  highlighted: highlightedIndex === index,
                })}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}
