import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { addUserActions as actions } from '.';
import { IGroups, IUserCreation, IUserCreationPayload } from './types';

function* getGroups() {
  const requestURL = `/api/admin/groups`;
  try {
    const response: IGroups[] = (yield call(request, requestURL)).data;
    yield put(actions.getGroupsSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getGroupsFailed(err));
  }
}

function* createUser(action: PayloadAction<IUserCreationPayload>) {
  const requestURL = `/api/admin/guest`;
  try {
    const response: IUserCreation = (yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    })).data;
    yield put(actions.createUserSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.createUserFailed(err));
  }
}

export function* addUserSaga() {
  yield takeLatest(actions.getGroups.type, getGroups);
  yield takeLatest(actions.createUser.type, createUser);
}
