import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { translations as _ } from 'locales/translations';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import {
  selectAddVideo,
  selectPushProduct,
} from 'app/pages/dashboard/slice/selectors';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { requestData } from 'utils/request';
import cx from 'classnames';
import DropDocumentation from 'assets/icons/drop-documentation.svg';
import AddProductImg from '../../../../../../assets/icons/add-product.svg';
import { SelectInstance } from 'react-select';
import { components } from 'react-select';
import { InputProps } from 'react-select';

export default function FolderModal({
  isOpenModal,
  setIsOpenModal,
  mediaOptions,
  methods,
}) {
  let { product } = useSelector(selectPushProduct);
  const { actions } = useDashboardSlice();
  const { loading: loadingVideo } = useSelector(selectAddVideo);
  const i18nKey = _.productEdit;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [medias, setMedias] = useState<File[] | undefined>();
  const [loader, setLoader] = useState<boolean>(false);

  const {
    register,
    getValues,
    watch,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const selectedFolder = watch('folder');
  const selectedSubfolder = watch('subfolder');

  useEffect(() => {
    selectedFolder &&
      selectedFolder.subfolder &&
      selectedFolder.subfolder.push({
        value: 'value',
        label: (
          <div className="new-create">
            <div className="new-create-bloc-img">
              <img src={AddProductImg} alt="Add folder" />
            </div>
            <div>{t(i18nKey.form.createNewSubFolder)}</div>
          </div>
        ),
        order: '',
      });
  }, [selectedFolder]);

  useEffect(() => {
    if (!selectedFolder) {
      setValue('subfolder', '');
    }
  }, [selectedFolder]);

  function addVideoToProduct() {
    // e.preventDefault();
    dispatch(
      actions.addVideo({
        linkValue: getValues('linkValue'),
        linkText: getValues('linkText'),
        language: 'eng',
        productId: product.id,
      }),
    );
    setMedias(undefined);
    setValue('linkValue', undefined);
    setValue('linkText', undefined);
    setIsOpenModal(false);
  }

  async function onDropDocumentation(acceptedFiles) {
    setMedias(acceptedFiles);
  }

  async function asyncForEachFile(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    dispatch(actions.getProductDetail(product.product_code));
  }

  const { handleSubmit } = methods;
  function onSubmitFiles() {
    if (
      selectedFolder.value === 'VIDEO' ||
      selectedFolder.value === 'video' ||
      selectedFolder.value === 'Video'
    ) {
      addVideoToProduct();
    } else {
      asyncForEachFile(medias, async f => {
        let fCopy = new File([f], f.name);
        const data = {
          productId: product.id,
          language: 'eng',
          uploadedFile: fCopy,
          folderCode: selectedFolder.value,
          subfolderCode: selectedSubfolder.value,
        };
        const requestURL = `${process.env.REACT_APP_API_URL}/api/media/upload`;
        try {
          setLoader(true);
          await requestData(requestURL, data, 'POST');
          setLoader(false);
          setMedias(undefined);
          setValue('folder', undefined);
          setValue('subfolder', undefined);
          setIsOpenModal(false);
        } catch (e: any) {
          toast.error(f.name + ': ' + e.message);
          console.error('erreur', f, e);
        }
      });
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      contentLabel="Example Modal"
      className="modalFolder"
      overlayClassName="modalFolder-overlay"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitFiles)}>
          <h4>{t(i18nKey.documentation.modal.desc)}</h4>
          <div className="select-container">
            <div className="field small">
              <fieldset
                className={
                  selectedFolder && selectedFolder.value === 'value'
                    ? 'small-fieldset'
                    : ''
                }
              >
                {selectedFolder && selectedFolder.value === 'value' ? (
                  <legend className="text-btn-red">
                    {t(i18nKey.documentation.modal.enterFolder)}
                  </legend>
                ) : (
                  <legend>{t(i18nKey.documentation.modal.folderLabel)}</legend>
                )}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <CreatableSelect
                      value={
                        selectedFolder && selectedFolder?.value === 'value'
                          ? ''
                          : value
                      }
                      onChange={onChange}
                      options={mediaOptions}
                      isClearable
                      isSearchable={
                        selectedFolder?.value === 'value' ? true : false
                      }
                      placeholder={
                        selectedFolder && selectedFolder.value === 'value'
                          ? t(i18nKey.form.createFolder)
                          : t(i18nKey.form.selectFolder)
                      }
                    />
                  )}
                  name="folder"
                  control={control}
                />
              </fieldset>
            </div>
            <div className="field small">
              <fieldset
                className={
                  selectedSubfolder && selectedSubfolder.value === 'value'
                    ? 'small-fieldset'
                    : ''
                }
              >
                {selectedSubfolder && selectedSubfolder.value === 'value' ? (
                  <legend className="text-btn-red">
                    {t(i18nKey.documentation.modal.enterSubFolder)}
                  </legend>
                ) : (
                  <legend>
                    {t(i18nKey.documentation.modal.subfolderLabel)}
                  </legend>
                )}
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <CreatableSelect
                      value={
                        selectedSubfolder && selectedSubfolder.value === 'value'
                          ? ''
                          : value
                      }
                      onChange={onChange}
                      options={selectedFolder?.subfolder}
                      isClearable
                      isSearchable={
                        selectedSubfolder?.value === 'value' ? true : false
                      }
                      isDisabled={
                        !selectedFolder ||
                        (selectedFolder &&
                          (selectedFolder.value === 'VIDEO' ||
                            selectedFolder.value === 'video'))
                      }
                      placeholder={t(i18nKey.form.selectSubFolder)}
                    />
                  )}
                  name="subfolder"
                  control={control}
                />
              </fieldset>
            </div>
          </div>
          {selectedFolder && (
            <>
              {selectedFolder.value === 'VIDEO' ||
              selectedFolder.value === 'Video' ||
              selectedFolder.value === 'video' ? (
                <div className="container-drop-video">
                  <div className="separator" />
                  <div className="field small">
                    <fieldset
                      className={cx({
                        error: errors.linkValue,
                      })}
                    >
                      <legend>{t(i18nKey.form.url)}</legend>
                      <input
                        id="linkValue"
                        {...register('linkValue', {
                          required: true,
                        })}
                      />
                    </fieldset>
                  </div>
                  <div className="field small">
                    <fieldset
                      className={cx({
                        error: errors.linkText,
                      })}
                    >
                      <legend>{t(i18nKey.form.videoTitle)}</legend>
                      <input
                        id="linkText"
                        {...register('linkText', {
                          required: true,
                        })}
                      />
                    </fieldset>
                  </div>
                </div>
              ) : !medias ? (
                <Dropzone
                  onDrop={onDropDocumentation}
                  multiple={true}
                  accept={'image/*,.pdf,.stp,.step,.igs,.IGS,.apk,.APK,.exe'}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragAccept,
                    isDragReject,
                    isDragActive,
                  }) => (
                    <div
                      className={cx('dropzone-documentation', {
                        isDragAccept,
                        isDragReject,
                        isDragActive,
                      })}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <img
                        alt="upload documentation"
                        src={DropDocumentation}
                        className="upload-doc"
                      />
                      <div className="types-files">
                        <span>.pdf</span>
                        <span>.jpeg</span>
                        <span>.png</span>
                        <span>.stp</span>
                        <span>.step</span>
                        <span>.igs</span>
                        <span>.apk</span>
                        <span>.exe</span>
                      </div>
                      <h4>{t(i18nKey.documentation.drag)}</h4>
                      <h5>{t(i18nKey.documentation.click)}</h5>
                    </div>
                  )}
                </Dropzone>
              ) : (
                <div className="uploaded-file">{medias?.[0]?.name}</div>
              )}
            </>
          )}
          <div>
            <button
              className="b-btn secondary"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              {t(i18nKey.documentation.modal.ctaCancel)}
            </button>
            <button
              className="b-btn"
              type="submit"
              disabled={loadingVideo || loader}
            >
              {loadingVideo || loader
                ? t(i18nKey.documentation.modal.ctaValidating)
                : t(i18nKey.documentation.modal.ctaValidate)}
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
