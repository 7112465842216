import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.support || initialState;

export const selectSupport = createSelector([selectSlice], state => state);
export const selectZohoTickets = createSelector(
  [selectSlice],
  state => state.zohoTickets,
);
export const selectHelpCenter = createSelector(
  [selectSlice],
  state => state.helpCenter,
);
export const selectJiraRequest = createSelector(
  [selectSlice],
  state => state.jiraRequest,
);
