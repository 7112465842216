import React from 'react';
import { translations as _ } from 'locales/translations';
import { useDispatch } from 'react-redux';
import { useSupportSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import Loader from 'app/components/Loader';
import DocumentIcon from 'assets/icons/document-icon.svg';
import CloseSmallIcon from 'assets/icons/close-small.svg';

interface IPrevriewAttachment {
  attachment: any;
  loadingAttachment: boolean;
  type: string;
  setIsOpenPreview?: (value: boolean) => void;
  setMediaSelected?: (value: any) => void;
}
export default function PreviewAttachment({
  attachment,
  loadingAttachment,
  setIsOpenPreview,
  setMediaSelected,
  type,
}: IPrevriewAttachment) {
  const dispatch = useDispatch();
  const { actions: actionsSupport } = useSupportSlice();
  let { requestId } = useParams<{ requestId: string }>();

  function handleMediaPreview(a) {
    if (setMediaSelected && setIsOpenPreview) {
      setMediaSelected(a);
      setIsOpenPreview(true);
    }
  }

  async function deleteAttachment(a) {
    dispatch(
      actionsSupport.deleteAttachment({
        attachmentId: a.id,
        issueKey: requestId,
      }),
    );
  }

  return !loadingAttachment ? (
    attachment.length ? (
      <div className="preview-file-container">
        {!loadingAttachment &&
          attachment?.map((a, i) => {
            if (attachment.length) {
              let type = a.mimeType.includes('video')
                ? 'video'
                : a.mimeType.includes('pdf')
                ? 'pdf'
                : 'doc';

              let media = attachment?.find(f => f.filename === a.filename);

              return (
                <div key={a.filename + i} className="preview-card">
                  <img
                    src={CloseSmallIcon}
                    className="delete-logo"
                    alt="delete-logo"
                    onClick={() => deleteAttachment(a)}
                    width={25}
                  />
                  <div
                    className="preview"
                    onClick={() => handleMediaPreview(media)}
                  >
                    <img
                      src={
                        a?.mimeType?.includes('image')
                          ? media?.content
                          : DocumentIcon
                      }
                      width={!a?.mimeType?.includes('image') ? 40 : 90}
                      height={!a?.mimeType?.includes('image') ? 40 : 70}
                      alt="preview"
                    />
                    {!a?.mimeType?.includes('image') ? <p>{type}</p> : null}
                  </div>
                  <div className="title-container">
                    <p>{a.filename}</p>
                  </div>
                </div>
              );
            }
          })}
      </div>
    ) : null
  ) : (
    <Loader size={30} />
  );
}
