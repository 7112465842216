import React from 'react';
import { translations as _ } from 'locales/translations';
import BinDelete from 'assets/icons/bin-delete.svg';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function CancelModal({ isOpenModal, setIsOpenModal }) {
  const i18nKey = _.productEdit;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      contentLabel="Example Modal"
      className="modalDelete"
      overlayClassName="modalDelete-overlay"
    >
      <img alt="upload model" src={BinDelete} className="upload-add-model" />
      <h4>{t(i18nKey.form.cancelMsg)}</h4>
      <p>{t(i18nKey.form.cancelMsg2)}</p>
      <div>
        <button
          className="b-btn secondary"
          onClick={() => {
            setIsOpenModal(false);
          }}
        >
          {t(i18nKey.form.cancel)}
        </button>
        <button
          className="b-btn"
          onClick={e => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {t(i18nKey.form.confirm)}
        </button>
      </div>
    </Modal>
  );
}
