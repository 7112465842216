import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';

export default function ProductDisplay(props: any) {
  const { productSelected, productIndex } = props;
  const history = useHistory();

  const [percent, setPercent] = React.useState({
    big: window.innerWidth < 1024 ? 25 : 75,
    small: window.innerWidth < 1024 ? 50 : 75,
  });

  const handleResize = () => {
    setPercent({
      big: window.innerWidth < 1024 ? 25 : 75,
      small: window.innerWidth < 1024 ? 50 : 75,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  return (
    <div className="product-display">
      <div className={cx('image-content', 'test-' + (productIndex % 3))}>
        <svg
          viewBox="0 0 36 36"
          className={`circle-content  rotateIn rotateIn${productIndex}`}
        >
          <path
            className={cx('circle-big')}
            strokeDasharray={percent.big + ', 100'}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={cx('circle-small')}
            strokeDasharray={percent.small + ', 100'}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div
          className="image-content-control"
          onClick={() => {
            history.push(
              `/product-detail/${productSelected.product_code
                .toLowerCase()
                .replace('ref.', '')}`,
              {
                productCode: productSelected.product_code,
              },
            );
          }}
        >
          <img
            alt="product display visual 3d"
            src={productSelected.visual_3d}
            className="product-display--img"
          />
          <div className="product-display--title">
            <div className="product-display--title-container">
              <h2>{productSelected.translations[0]?.name}</h2>
              <p>{productSelected.translations[0]?.kind}</p>
            </div>
          </div>
          <div className="product-display--description">
            {productSelected.translations[0]?.subtitle}
          </div>
          <Link
            to={{
              pathname: `/product-detail/${productSelected.product_code
                .toLowerCase()
                .replace('ref.', '')}`,
              state: {
                productCode: productSelected.product_code,
              },
            }}
          >
            <button className="btn-see-more">See more</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
