import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { editUserSaga } from './saga';
import { EditUserState } from './types';

export const initialState: EditUserState = {
  updateUser: {
    loading: false,
    success: false,
    error: false,
    data: undefined,
  },
  deleteUser: {
    loading: false,
    success: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: 'editUser',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      state.updateUser.loading = true;
      state.updateUser.success = false;
      state.updateUser.error = false;
      state.updateUser.data = undefined;
    },
    updateUserSuccess(state, action: PayloadAction<any>) {
      state.updateUser.loading = false;
      state.updateUser.success = true;
      state.updateUser.error = false;
      state.updateUser.data = action.payload;
    },
    updateUserFailed(state, action: PayloadAction<any>) {
      state.updateUser.loading = false;
      state.updateUser.success = false;
      state.updateUser.error = action.payload;
    },
    deleteUser(state, action: PayloadAction<any>) {
      state.deleteUser.loading = true;
      state.deleteUser.success = false;
      state.deleteUser.error = false;
      state.deleteUser.data = undefined;
    },
    deleteUserSuccess(state) {
      state.deleteUser.loading = false;
      state.deleteUser.success = true;
      state.deleteUser.error = false;
    },
    deleteUserFailed(state, action: PayloadAction<any>) {
      state.deleteUser.loading = false;
      state.deleteUser.success = false;
      state.deleteUser.error = true;
    },
  },
});

export const { actions: editUserActions } = slice;

export const useEditUserSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: editUserSaga });
  return { actions: slice.actions };
};
