import React from 'react';
import { useSelect } from 'downshift';
import cx from 'classnames';
import ArrowUpIcon from 'assets/icons/arrow-up-small.svg';
import ArrowDownIcon from 'assets/icons/arrow-down-small.svg';

interface OptionType<T = any> {
  label: string;
  value: T;
}

export interface IElistairSelect {
  options: OptionType[];
  label: string;
  value?: OptionType;
  disabled?: boolean;
  className?: string;
  onChange: (v: any | null) => void;
  errored?: boolean;
  onInputChange?: (v: string) => void;
  onlyValue?: boolean;
}

export default function SelectFilter({
  options,
  label,
  value,
  onChange,
  disabled,
  className,
  errored,
  onlyValue,
}: IElistairSelect) {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    initialSelectedItem: value || null,
    onSelectedItemChange: changes => onChange(changes.selectedItem),
  });

  return (
    <div
      className={cx('b-select2-container', className, {
        'only-value': onlyValue,
      })}
    >
      {onlyValue && value?.label ? (
        <span className="value">{value.label}</span>
      ) : (
        <>
          <button
            className={cx({ disabled, errored }, 'btn-select')}
            disabled={disabled}
            type="button"
            {...getToggleButtonProps()}
          >
            <div className="b-select2-actions">
              <img
                alt="arrow"
                src={isOpen ? ArrowUpIcon : ArrowDownIcon}
                className="toggle-icon"
                width={17}
                onClick={() => getToggleButtonProps().onClick}
              />
            </div>
            {!value?.label && label && <span>{label}</span>}
          </button>
        </>
      )}
      <ul {...getMenuProps()} className={cx({ closed: !isOpen })}>
        {isOpen
          ? options.map((item, index) => (
              <li
                className={cx(
                  {
                    highlighted: highlightedIndex === index,
                  },
                  'select-open',
                )}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}
