import React from 'react';
import cx from 'classnames';
import { translations as _ } from 'locales/translations';

interface IAvatarJiraProps {
  name: string;
  type: 'user' | 'assignee' | string;
}
export default function AvatarJira({ name, type }: IAvatarJiraProps) {
  const Initial = name
    .split(' ')
    .map(i => i.slice(0, 1))
    .join('');

  return (
    <div
      className={cx('avatar-jira', {
        user: type === 'user',
        assignee: type === 'assignee',
      })}
    >
      <p>{Initial}</p>
    </div>
  );
}
