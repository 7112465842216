import React from 'react';

export default function BulletMobile(props: { indexSelected: number }) {
  const { indexSelected } = props;
  return (
    <>
      <div className="mobile-bullets">
        <ul className="list-inline dots">
          <li className={indexSelected === 0 ? 'active' : ''}> </li>
          <li className={indexSelected > 0 ? 'active' : ''}> </li>
        </ul>
      </div>
    </>
  );
}
