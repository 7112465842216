const API_URL = process.env.REACT_APP_API_URL;

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

interface IMessageArguments {
  field: string;
}
interface IErrorMessageDetails {
  code: string;
  field: string;
  message: string;
  messageArgs: {};
  value: string;
}
export class ValidationError extends Error {
  messageDetails: IErrorMessageDetails | IErrorMessageDetails[];
  code: string;
  messageArguments: IMessageArguments;

  constructor(
    message: string,
    messageDetails: IErrorMessageDetails | IErrorMessageDetails[],
    code: string,
    messageArguments: IMessageArguments,
  ) {
    // Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
    super();

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
    this.name = 'ValidationError';
    // Informations de déboguage personnalisées
    this.message = message;
    this.messageDetails = messageDetails;
    this.messageArguments = messageArguments;
    this.code = code;
  }
  toJSON() {
    return {
      name: this.name,
      message: this.message,
      messageDetails: this.messageDetails,
      messageArguments: this.messageArguments,
      code: this.code,
    };
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response: Response) {
  if (response.status === 200 || response.status === 201) {
    try {
      const body = await response.json();
      return body;
    } catch (e) {
      console.warn(e, 'might be better to send a 204 code');
      return null;
    }
  }

  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('text') !== -1) {
    const txt = await response.text();
    return {
      message: txt,
    };
  }

  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 400) {
    const json = await response.json();
    throw new ValidationError(
      json.message,
      json.messageDetails,
      json.code,
      json.messageArguments,
    );
  }
  if (response.status === 403) {
    localStorage.removeItem('access_token');
    sessionStorage.clear();
    window.location.href = '/';
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request<T>(
  url: string,
  options?: RequestInit,
): Promise<T | { err: ResponseError }> {
  const headers = new Headers();
  if (!options) {
    options = {};
  }
  const credentials = localStorage.getItem('access_token');
  if (credentials) {
    headers.set('Authorization', `Bearer ${credentials}`);
  }
  if (options && !options.headers) {
    headers.set('Content-Type', 'application/json');
    headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', '0');
    options.headers = headers;
  } else {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${credentials}`,
    };
  }

  options.credentials = 'include';
  const finalUrl = `${API_URL}${url}`;
  const fetchResponse = await fetch(finalUrl, options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

export async function requestData(url, data, method) {
  const formData = new FormData();
  for (const name in data) {
    formData.append(name, data[name]);
  }
  const credentials = localStorage.getItem('access_token');
  const response = fetch(url, {
    method: method,
    body: formData,
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${credentials}`,
    },
  });

  return response
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error(res.statusText);
      }
    })
    .then(function (data) {
      return data;
    });
}
