import { call, put, takeLatest } from 'redux-saga/effects';
import request, { ValidationError } from 'utils/request';
import { publicLoginActions as actions } from '.';
import { ILoginVersion } from './types';

function* getVersion() {
  const requestURL = `/api/open/login/version`;
  try {
    const response: ILoginVersion = (yield call(request, requestURL)).data;
    yield put(actions.getVersionSuccess(response));
  } catch (err) {
    console.error('error', err);
    if (err instanceof ValidationError) {
      yield put(actions.getVersionFailed(err.toJSON()));
    } else {
      yield put(actions.getVersionFailed(err));
    }
  }
}

export function* publicLoginSaga() {
  yield takeLatest(actions.getVersion.type, getVersion);
}
