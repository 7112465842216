import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import CustomHeader from 'app/components/CustomHeader';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'app/components/Loader';
import { selectHelpCenter } from '../../slice/selectors';
import { useSupportSlice } from '../../slice';
import { useHistory, useLocation } from 'react-router-dom';
import CategorieBtn from './CategorieBtn';

interface propState {
  catId: string;
  articleId: string;
}

export default function Article() {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useHistory().push;
  const { actions } = useSupportSlice();
  const { categories, article, articles } = useSelector(selectHelpCenter);
  const location = useLocation();
  const { actions: actionsSupport } = useSupportSlice();

  const [showSearchDropdown, setShowSearchDropDown] = useState(false);
  const [searchResultData, setSearchResultData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const { catId, articleId } = location.state as propState;
  let catSelected = categories?.filter(item => item.id === catId)[0];

  useEffect(() => {
    dispatch(actionsSupport.getHelpCenterCategories());
  }, []);

  useEffect(() => {
    if (categories?.length) {
      const catArraytoString = categories?.map(e => e.id).toString();
      dispatch(actionsSupport.getArticlesbyCategories(catArraytoString));
    }
  }, [categories]);

  useEffect(() => {
    dispatch(actions.getArticle(articleId));
  }, [articleId]);

  function handleOnSearchChange(search) {
    setSearchValue(search);
    if (articles) {
      let allArticles = articles.map(a => a.articles.data).flat();
      let searchResult = allArticles.filter(a =>
        a.title.toLowerCase().includes(search.toLowerCase()),
      );
      if (search.length > 3 && searchResult) {
        setSearchResultData(searchResult);
        setShowSearchDropDown(true);
      } else {
        setShowSearchDropDown(false);
      }
    }
  }
  function handleClickSearchedArticle(s) {
    navigate('/article', {
      catId: s.categoryId,
      articleId: s.id,
    });
    setShowSearchDropDown(false);
    setSearchValue('');
  }

  return (
    <div className="support">
      <div className="support-intro">
        <div className="support-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="support-content">
        <div className="support-header">
          <CustomHeader
            addBack
            urlBack="/faq"
            showLink
            hideSearch
            invertedColor
          />
        </div>
        <div className="support-body">
          <div className="top-part">
            <h1 className="hide-mobile">{t(i18nKey.helpCenter)}</h1>
            <div className="title-article">
              <h3>{article?.title}</h3>
              <div className="search-article">
                <div className="search-bar">
                  <input
                    type="text"
                    className="search-input-faq hide-mobile"
                    placeholder={t(i18nKey.searchPlaceholder) as string}
                    value={searchValue}
                    onChange={event => handleOnSearchChange(event.target.value)}
                  />
                  {showSearchDropdown ? (
                    <ul className="dropdown-search">
                      {searchResultData.length ? (
                        searchResultData.map(s => (
                          <li
                            className="article-search-title"
                            onClick={() => handleClickSearchedArticle(s)}
                          >
                            {s.title}
                          </li>
                        ))
                      ) : (
                        <li>{t(i18nKey.noResult)}</li>
                      )}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {article ? (
            <div
              dangerouslySetInnerHTML={{ __html: article?.answer }}
              className="article-content"
            />
          ) : (
            <div className="loader-container">
              <Loader size={20} />
            </div>
          )}
          <div className="block-container">
            <p className="text-info">{t(i18nKey.otherArticleSameCat)}</p>
            {catSelected ? (
              <CategorieBtn cat={catSelected} openCategories={[catId]} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
