import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.userInformations || initialState;

export const selectUserInformations = createSelector(
  [selectSlice],
  state => state,
);
export const selectUserInformationsGet = createSelector(
  [selectSlice],
  state => state.getUserInfos,
);
export const selectUserEstimate = createSelector(
  [selectSlice],
  state => state.getUserEstimates,
);
