import { useEffect } from 'react';

export function useMatamo() {
  useEffect(() => {
    const script = document.createElement('script');
    var u = 'https://elistair.matomo.cloud/';
    script.innerHTML = `
    var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    _paq.push(['setTrackerUrl', \`${u}matomo.php\`]);
    _paq.push(['setSiteId', '2']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/elistair.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
    `;
    document.head.appendChild(script);
  }, []);

  return null;
}
