import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userInformationsSaga } from './saga';
import { IUserInfos, UserInformationsState } from './types';

export const initialState: UserInformationsState = {
  getUserInfos: {
    loading: false,
    success: false,
    error: false,
  },
  getUserEstimates: {
    loading: false,
    success: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: 'userInformations',
  initialState,
  reducers: {
    getUserInfos(state) {
      state.getUserInfos.loading = true;
      state.getUserInfos.success = false;
      state.getUserInfos.error = false;
      state.getUserInfos.data = undefined;
    },
    getUserInfosSuccess(state, action: PayloadAction<IUserInfos>) {
      state.getUserInfos.loading = false;
      state.getUserInfos.success = true;
      state.getUserInfos.error = false;
      state.getUserInfos.data = action.payload;
    },
    getUserInfosFailed(state, action: PayloadAction<any>) {
      state.getUserInfos.loading = false;
      state.getUserInfos.success = false;
      state.getUserInfos.error = true;
    },
    getUserEstimates(state, action: any) {
      state.getUserEstimates.loading = true;
      state.getUserEstimates.success = false;
      state.getUserEstimates.error = false;
      state.getUserEstimates.data = undefined;
    },
    getUserEstimatesSuccess(state, action: PayloadAction<IUserInfos>) {
      state.getUserEstimates.loading = false;
      state.getUserEstimates.success = true;
      state.getUserEstimates.error = false;
      state.getUserEstimates.data = action.payload;
    },
    getUserEstimatessFailed(state, action: PayloadAction<any>) {
      state.getUserEstimates.loading = false;
      state.getUserEstimates.success = false;
      state.getUserEstimates.error = true;
    },
  },
});

export const { actions: userInformationsActions } = slice;

export const useUserInformationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userInformationsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useUserInformationsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
