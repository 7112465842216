import React, { useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import AddDocIcon from 'assets/icons/upload-add.svg';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useSupportSlice } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectJiraRequest } from '../../slice/selectors';
import Loader from 'app/components/Loader';
import { useParams } from 'react-router-dom';
import DocumentIcon from 'assets/icons/document-icon.svg';
import CloseSmallIcon from 'assets/icons/close-small.svg';
interface ICommentProps {
  setNewComment: (cId: string) => void;
  newComment: string;
}

export default function CommentInput({
  setNewComment,
  newComment,
}: ICommentProps) {
  const i18nKey = _.support;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { requestId } = useParams<{ requestId: string }>();
  const { actions: actionsSupport } = useSupportSlice();
  const [tmpAttachment, setTmpAttachment] = useState<
    { fileName: string; temporaryAttachmentId: string }[]
  >([]);
  const [isMultiuploadLoading, setIsMultiuploadLoading] = useState(false);
  const { loadingComment, loadingAttachment } = useSelector(selectJiraRequest);
  const [previewFiles, setPreviewFiles] = useState<any[]>([]);

  async function asyncForEachFile(array, callback) {
    setIsMultiuploadLoading(true);
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    setIsMultiuploadLoading(false);
  }

  async function onDropDocumentation(acceptedFiles) {
    const newVal = acceptedFiles.map(file => {
      return Object.assign(file, {
        preview: file.type.includes('image')
          ? URL.createObjectURL(file)
          : 'none',
      });
    });
    let result = previewFiles.concat(newVal);
    setPreviewFiles(result);

    asyncForEachFile(acceptedFiles, async f => {
      let form = new FormData();
      form.append('file', f, f.name);

      const requestURL = `${process.env.REACT_APP_API_URL}/api/support/request/postAttachment`;
      try {
        const credentials = localStorage.getItem('access_token');
        let response = await fetch(requestURL, {
          method: 'POST',
          body: form,
          credentials: 'include',
          headers: {
            enctype: 'multipart/form-data',
            Authorization: `Bearer ${credentials}`,
          },
        });
        let data = await response.json();
        if (data?.data?.temporaryAttachments?.[0]) {
          setTmpAttachment([
            ...tmpAttachment,
            data.data.temporaryAttachments[0],
          ]);
        } else if (data.errors) {
          let newPreview = previewFiles.filter(p => p.path !== f.name);
          setPreviewFiles(newPreview);
          toast.error(f.name + ': ' + data.errors[0].message);
        }
        let dataAttachment = {
          body: [data?.data?.temporaryAttachments?.[0]],
          issueKey: requestId,
        };
        dispatch(actionsSupport.addAttachment(dataAttachment));
      } catch (e: any) {
        toast.error(f.name + ': ' + e.message);
        console.error('erreur', f, e);
      }
    });
  }
  async function deleteAttachment(file) {
    setTmpAttachment(tmpAttachment.filter(f => f.fileName !== file.name));
    setPreviewFiles(previewFiles.filter(p => p.path !== file.name));
  }

  function handleSendComment() {
    let attachement =
      tmpAttachment.map(tmp => `[^${tmp.fileName}]`).join('') || '';
    let data = {
      body: newComment + attachement,
      issueKey: requestId,
    };
    dispatch(actionsSupport.sendComment(data));
    setTmpAttachment([]);
    setPreviewFiles([]);
    setNewComment('');
  }
  return (
    <div className="comment-container">
      <div className="field-container">
        <fieldset className="field">
          <legend>Add a comment</legend>
          <textarea
            rows={5}
            id="comment"
            onChange={e => {
              setNewComment(e.target.value);
            }}
            value={newComment}
          />
          {previewFiles.length ? (
            <div className="preview-file-container">
              {previewFiles?.map((f, index) => {
                let type = f.type.includes('video')
                  ? 'video'
                  : f.type.includes('pdf')
                  ? 'pdf'
                  : 'doc';
                return (
                  <div
                    className="preview-card-comment"
                    key={f?.preview + index}
                  >
                    <img
                      src={CloseSmallIcon}
                      className="delete-logo"
                      alt="delete-logo"
                      onClick={() => deleteAttachment(f)}
                      width={30}
                    />
                    {f?.preview !== 'none' ? (
                      <img
                        src={f.preview}
                        width={50}
                        height={50}
                        alt="preview"
                      />
                    ) : (
                      <div className="preview">
                        <img src={DocumentIcon} width={30} alt="icon-pdf" />
                        <h2>{type}</h2>
                      </div>
                    )}

                    <div className="title-container">
                      <p>{f?.path}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </fieldset>
        <Dropzone
          onDrop={onDropDocumentation}
          accept={'image/*, video/*, .pdf'}
          multiple={true}
        >
          {({
            getRootProps,
            getInputProps,
            isDragAccept,
            isDragReject,
            isDragActive,
          }) => (
            <div
              className={cx('dropzone-documentation', {
                isDragAccept,
                isDragReject,
                isDragActive,
              })}
              {...getRootProps()}
            >
              {isMultiuploadLoading && (
                <div className="loading-multiple">
                  {t(_.support.mediaImport)}
                </div>
              )}
              <input {...getInputProps()} />
              <img
                alt="upload documentation"
                src={AddDocIcon}
                className="upload-doc"
              />
            </div>
          )}
        </Dropzone>
      </div>
      <button
        className="b-btn button secondary withBorder"
        onClick={handleSendComment}
      >
        {loadingComment || isMultiuploadLoading || loadingAttachment ? (
          <Loader size={10} />
        ) : (
          t(i18nKey.ctaSend)
        )}
      </button>
    </div>
  );
}
