import React, { useEffect } from 'react';
import { translations as _ } from 'locales/translations';
import BinDelete from 'assets/icons/bin-delete.svg';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEditUserSlice } from '../slice';
import { selectDeleteUser } from '../slice/selectors';
import { toast } from 'react-toastify';
import usePrevious from 'utils/hooks/usePrevious';
import { useListUsersSlice } from '../../list-user/slice';
import Loader from 'app/components/Loader';
import { useHistory } from 'react-router-dom';

export default function DeleteModal({ isOpenModal, setIsOpenModal, users }) {
  const i18nKey = _.editUser.modalDelete;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions: editActions } = useEditUserSlice();
  const { success, loading } = useSelector(selectDeleteUser);
  const { actions } = useListUsersSlice();
  let navigate = useHistory().push;

  function handleDeleteUser() {
    if (users.length) {
      users.map(user => {
        return dispatch(editActions.deleteUser(user.id));
      });
    }
  }

  let users_name = users
    .map(item => {
      return `${item.firstname} ${item.lastname}`;
    })
    .join(', ');

  let prevSuccess = usePrevious(success);
  useEffect(() => {
    if (success && success !== prevSuccess) {
      toast.success(t(i18nKey.notification));
      dispatch(actions.getUsers());
      setIsOpenModal(false);
      navigate('/list-user');
    }
  }, [success]);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      contentLabel="Example Modal"
      className="modalDelete"
      overlayClassName="modalDelete-overlay"
    >
      <img alt="upload model" src={BinDelete} className="upload-add-model" />
      <h4>{t(i18nKey.title)}</h4>
      <p>{t(i18nKey.users, { users_name: users_name })}</p>
      <p>{t(i18nKey.description)}</p>
      <div>
        <button
          className="b-btn secondary"
          onClick={() => {
            setIsOpenModal(false);
          }}
        >
          {t(i18nKey.ctaCancel)}
        </button>
        <button className="b-btn" onClick={handleDeleteUser}>
          {loading ? <Loader size={20} /> : t(i18nKey.cta)}
        </button>
      </div>
    </Modal>
  );
}
