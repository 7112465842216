import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dashboardSaga } from './saga';
import { DashboardState, IProduct, IVideo, MediasWithCode } from './types';

export const initialState: DashboardState = {
  deleteMedia: {
    loading: false,
    success: false,
    error: undefined,
  },
  publishProduct: {
    loading: false,
    success: false,
    error: undefined,
  },
  deleteProduct: {
    loading: false,
    success: false,
    error: undefined,
  },
  deleteSubFolder: {
    loading: false,
    success: false,
    error: undefined,
  },
  deleteFolder: {
    loading: false,
    success: false,
    error: undefined,
  },
  orderMedia: {
    loading: false,
    success: false,
    error: undefined,
  },
  updateMediaFolderName: {
    loading: false,
    success: false,
    error: undefined,
  },
  pushProduct: {
    loading: false,
    product: null,
    success: false,
    error: false,
  },
  getProducts: {
    loading: false,
    success: false,
    data: undefined,
    error: undefined,
  },
  getProductDetail: {
    loading: false,
    success: false,
    data: undefined,
    error: undefined,
  },
  getGuidelineUrl: {
    loading: false,
    success: false,
    data: undefined,
    error: undefined,
  },
  addVideo: {
    loading: false,
    success: false,
    error: undefined,
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    deleteMedia(
      state,
      action: PayloadAction<{
        productId: string;
        ids: Array<number>;
      }>,
    ) {
      state.deleteMedia.loading = true;
      state.deleteMedia.success = false;
      state.deleteMedia.error = undefined;
    },
    deleteMediaSuccess(state, action: PayloadAction<any>) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = true;
      state.deleteMedia.error = undefined;
    },
    deleteMediaFailed(state) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = false;
      state.deleteMedia.error = true;
    },
    deleteSubFolder(
      state,
      action: PayloadAction<{
        productId: string;
        ids: Array<number>;
      }>,
    ) {
      state.deleteMedia.loading = true;
      state.deleteMedia.success = false;
      state.deleteMedia.error = undefined;
    },
    deleteSubFolderSuccess(state, action: PayloadAction<any>) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = true;
      state.deleteMedia.error = undefined;
    },
    deleteSubFolderFailed(state) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = false;
      state.deleteMedia.error = true;
    },
    deleteFolder(
      state,
      action: PayloadAction<{
        productId: string;
        ids: Array<number>;
      }>,
    ) {
      state.deleteMedia.loading = true;
      state.deleteMedia.success = false;
      state.deleteMedia.error = undefined;
    },
    deleteFolderSuccess(state, action: PayloadAction<any>) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = true;
      state.deleteMedia.error = undefined;
    },
    deleteFolderFailed(state) {
      state.deleteMedia.loading = false;
      state.deleteMedia.success = false;
      state.deleteMedia.error = true;
    },
    orderMedia(state, action: PayloadAction<any>) {
      state.orderMedia.loading = true;
      state.orderMedia.success = false;
      state.orderMedia.error = undefined;
    },
    orderMediaSuccess(state, action: PayloadAction<any>) {
      state.orderMedia.loading = false;
      state.orderMedia.success = true;
      state.orderMedia.error = undefined;
    },
    orderMediaFailed(state) {
      state.orderMedia.loading = false;
      state.orderMedia.success = false;
      state.orderMedia.error = true;
    },
    updateMediaFolderName(state, action: PayloadAction<any>) {
      state.updateMediaFolderName.loading = true;
      state.updateMediaFolderName.success = false;
      state.updateMediaFolderName.error = undefined;
    },
    updateMediaFolderNameSuccess(state, action: PayloadAction<any>) {
      state.updateMediaFolderName.loading = false;
      state.updateMediaFolderName.success = true;
      state.updateMediaFolderName.error = undefined;
    },
    updateMediaFolderNameFailed(state) {
      state.updateMediaFolderName.loading = false;
      state.updateMediaFolderName.success = false;
      state.updateMediaFolderName.error = true;
    },
    publishProduct(
      state,
      action: PayloadAction<{
        id: number;
        mediaFolders: MediasWithCode[];
        shouldUpdate: boolean;
      }>,
    ) {
      state.publishProduct.loading = true;
      state.publishProduct.success = false;
      state.publishProduct.error = undefined;
    },
    publishProductSuccess(state, action: PayloadAction<any>) {
      state.publishProduct.loading = false;
      state.publishProduct.success = true;
      state.publishProduct.error = undefined;
    },
    publishProductFailed(state) {
      state.publishProduct.loading = false;
      state.publishProduct.success = false;
      state.publishProduct.error = true;
    },

    publishProductReset(state) {
      state.publishProduct = {
        ...initialState.publishProduct,
      };
    },
    deleteProductReset(state) {
      state.deleteProduct = {
        ...initialState.deleteProduct,
      };
    },
    deleteProduct(
      state,
      action: PayloadAction<{
        id: number;
      }>,
    ) {
      state.deleteProduct.loading = true;
      state.deleteProduct.success = false;
      state.deleteProduct.error = undefined;
    },
    deleteProductSuccess(state, action: PayloadAction<any>) {
      state.deleteProduct.loading = false;
      state.deleteProduct.success = true;
      state.deleteProduct.error = undefined;
      state.pushProduct.product = null;
    },
    deleteProductFailed(state) {
      state.deleteProduct.loading = false;
      state.deleteProduct.success = false;
      state.deleteProduct.error = true;
    },
    pushProductReset(state) {
      state.pushProduct = {
        ...initialState.pushProduct,
      };
    },
    pushProduct(state) {
      state.pushProduct.loading = true;
      state.pushProduct.success = false;
      state.pushProduct.error = false;
    },
    pushProductSuccess(state, action: PayloadAction<any>) {
      state.pushProduct.loading = false;
      state.pushProduct.success = true;
      state.pushProduct.error = false;
      state.pushProduct.product = action.payload;
    },
    pushProductFailed(state) {
      state.pushProduct.loading = false;
      state.pushProduct.success = false;
      state.pushProduct.error = true;
    },
    getProducts(state) {
      state.getProducts.loading = true;
      state.getProducts.success = false;
    },
    getProductsSuccess(state, action: PayloadAction<Array<IProduct>>) {
      state.getProducts.loading = false;
      state.getProducts.success = true;
      state.getProducts.data = action.payload;
    },
    getProductsFailed(state, action: PayloadAction<any>) {
      state.getProducts.loading = false;
      state.getProducts.success = false;
      state.getProducts.data = undefined;
      state.getProducts.error = action.payload;
    },
    getProductDetail(state, action: PayloadAction<string>) {
      state.getProductDetail.loading = true;
      state.getProductDetail.success = false;
    },
    getProductDetailSuccess(state, action: PayloadAction<IProduct>) {
      state.getProductDetail.loading = false;
      state.getProductDetail.success = true;
      state.getProductDetail.data = action.payload;
    },
    getProductDetailFailed(state, action: PayloadAction<any>) {
      state.getProductDetail.loading = false;
      state.getProductDetail.success = false;
      state.getProductDetail.data = undefined;
      state.getProductDetail.error = action.payload;
    },
    getGuidelineURL(state) {
      state.getGuidelineUrl.loading = true;
      state.getGuidelineUrl.success = false;
    },
    getGuidelineURLSuccess(state, action: PayloadAction<any>) {
      state.getGuidelineUrl.loading = false;
      state.getGuidelineUrl.success = true;
      state.getGuidelineUrl.data = action.payload;
    },
    getGuidelineURLFailed(state, action: PayloadAction<any>) {
      state.getGuidelineUrl.loading = false;
      state.getGuidelineUrl.success = false;
      state.getGuidelineUrl.data = undefined;
      state.getGuidelineUrl.error = action.payload;
    },
    fillProductInEdit(state, action: PayloadAction<IProduct>) {
      state.pushProduct.loading = false;
      state.pushProduct.success = true;
      state.pushProduct.error = false;
      state.pushProduct.product = action.payload;
    },
    newProduct(state) {
      state.getProductDetail.loading = false;
      state.getProductDetail.success = true;
      state.getProductDetail.data = undefined;
      state.pushProduct.product = undefined;
    },
    addVideo(state, action: PayloadAction<IVideo>) {
      state.addVideo.loading = true;
      state.addVideo.success = false;
      state.addVideo.error = false;
    },
    addVideoSuccess(state, action: PayloadAction<IProduct>) {
      state.addVideo.loading = false;
      state.addVideo.success = true;
      state.addVideo.error = false;
      state.pushProduct.product = action.payload;
    },
    addVideoFailed(state, action: PayloadAction<any>) {
      state.addVideo.loading = false;
      state.addVideo.success = false;
      state.addVideo.error = action.payload;
    },
    resetFilesDeleted(state) {
      state.deleteMedia.success = false;
    },
  },
});

export const { actions: dashboardActions } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dashboardSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDashboardSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
