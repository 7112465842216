import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState, ISession } from './types';

export const initialState: AppState = {
  getSession: {
    loading: false,
    success: false,
    error: false,
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getSession(state) {
      state.getSession.loading = true;
      state.getSession.success = false;
      state.getSession.error = false;
      state.getSession.data = undefined;
    },
    getSessionSuccess(state, action: PayloadAction<ISession>) {
      state.getSession.loading = false;
      state.getSession.success = true;
      state.getSession.error = false;
      state.getSession.data = action.payload;
    },
    getSessionFailed(state, action: PayloadAction<any>) {
      state.getSession.loading = false;
      state.getSession.success = false;
      state.getSession.error = true;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAppSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.getSession());
 *   };
 * }
 */
