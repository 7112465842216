import React, { useState, useEffect } from 'react';
import { translations as _ } from '../../../../locales/translations';
import { useTranslation } from 'react-i18next';
import CustomHeader from '../../../components/CustomHeader';
import { useForm } from 'react-hook-form';
import { useDashboardSlice } from '../../dashboard/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectPushProduct,
  selectDeleteProduct,
  selectProductDetail,
  selectPublishProduct,
  selectAddVideo,
} from '../../dashboard/slice/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import arrowBack from '../../../../assets/icons/arrow-back.svg';
import Description from './components/description';
import Documentation from './components/documentation';
import DeleteModal from './components/modals/DeleteModal';
import BottomBar from './components/BottomBar';
import CancelModal from './components/modals/CancelModal';

export default function ProductEdit(props: any) {
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const productList = useSelector(
    (state: RootState) => state.dashboard?.getProducts.data,
  );
  const i18nKey = _.productEdit;
  const [mediaFileOrder, setMediaFileOrder] = useState();

  let { product } = useSelector(selectPushProduct);
  let { data: productDetail } = useSelector(selectProductDetail);
  let { success: productDeletedSuccess } = useSelector(selectDeleteProduct);
  let { success: addVideoSuccess } = useSelector(selectAddVideo);
  let {
    success: publishProductSuccess,
    loading: publishProductLoading,
  } = useSelector(selectPublishProduct);

  const { setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [mediaFolders, setMediaFolder] = useState(productDetail?.mediaFolders);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isDocumentationOpen, setIsDocumentationOpen] = useState(!!product);
  const [deletingDocs, setDeletingDocs] = useState<number[]>([]);
  const [deletingSubFolders, setDeletingSubFolders] = useState<number[]>([]);
  const [deletingFolders, setDeletingFolders] = useState<number[]>([]);

  useEffect(() => {
    setValue('linkValue', '');
    setValue('linkText', '');
  }, [addVideoSuccess, setValue]);

  useEffect(() => {
    if (productDeletedSuccess) {
      toast.info(t(i18nKey.notification.deleted));
      history.push('/');
      dispatch(actions.deleteProductReset());
    }
    if (publishProductSuccess) {
      toast.success(t(i18nKey.notification.created));
      history.push(
        `/product-detail/${product.product_code
          .toLowerCase()
          .replace('ref.', '')}`,
        {
          productCode: product.product_code,
        },
      );
      dispatch(actions.publishProductReset());
    }
  }, [product, productDeletedSuccess, publishProductSuccess]);

  useEffect(() => {
    if (!productList?.length) {
      dispatch(actions.getProducts());
    }
  }, [actions, dispatch, productList]);

  function handleGoBack(e) {
    e.preventDefault();
    history.goBack();
  }

  useEffect(() => {
    if (productDetail?.mediaFolders) {
      setMediaFolder(productDetail.mediaFolders);
    }
  }, [productDetail?.mediaFolders]);

  return (
    <div className="product-edit">
      <div className="product-edit-content">
        <CustomHeader isEdit showLink />
        <div className="product-edit-content-page">
          <button className="back-button" onClick={e => handleGoBack(e)}>
            <img alt="back" src={arrowBack} /> <p>BACK</p>
          </button>
          <h2>{t(i18nKey.title)}</h2>
          <div className="product-edit-content-page-form">
            <Description
              setIsDocumentationOpen={setIsDocumentationOpen}
              isDocumentationOpen={isDocumentationOpen}
            />
            <Documentation
              setIsDocumentationOpen={setIsDocumentationOpen}
              isDocumentationOpen={isDocumentationOpen}
              setMediaFileOrder={setMediaFileOrder}
              setDeletingDocs={setDeletingDocs}
              deletingDocs={deletingDocs}
              deletingSubFolders={deletingSubFolders}
              setDeletingSubFolders={setDeletingSubFolders}
              deletingFolders={deletingFolders}
              setDeletingFolders={setDeletingFolders}
              setMediaFolder={setMediaFolder}
              mediaFolders={mediaFolders}
            />
          </div>
        </div>
      </div>
      {product && (
        <BottomBar
          product={product}
          publishProductLoading={publishProductLoading}
          setIsOpenModal={setIsOpenModal}
          setIsOpenCancelModal={setIsOpenCancelModal}
          mediaFolders={mediaFolders}
          mediaFileOrder={mediaFileOrder}
          deletingDocs={deletingDocs}
          deletingSubFolders={deletingSubFolders}
          deletingFolders={deletingFolders}
        />
      )}
      <DeleteModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
      <CancelModal
        isOpenModal={isOpenCancelModal}
        setIsOpenModal={setIsOpenCancelModal}
      />
    </div>
  );
}
