import React, { useEffect } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import { ReactComponent as Logo } from 'assets/icons/menu-delete-normal.svg';
import usePrevious from 'utils/hooks/usePrevious';

export default function BottomBar({
  product,
  publishProductLoading,
  setIsOpenModal,
  setIsOpenCancelModal,
  mediaFolders,
  mediaFileOrder,
  deletingDocs,
  deletingSubFolders,
  deletingFolders,
}) {
  const i18nKey = _.productEdit;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  let prevMediaFileOrder = usePrevious(mediaFileOrder);
  useEffect(() => {
    if (
      mediaFileOrder?.length &&
      JSON.stringify(prevMediaFileOrder) !== JSON.stringify(mediaFileOrder)
    )
      dispatch(
        actions.orderMedia({
          newOrder: mediaFileOrder,
        }),
      );
  }, [mediaFileOrder]);

  return (
    <div className="product-edit-action">
      {publishProductLoading && <>{t(i18nKey.form.publishing)}</>}
      {!publishProductLoading && (
        <>
          <button
            onClick={() => {
              setIsOpenModal(true);
            }}
            type="button"
            className="b-btn secondary btn-icon"
          >
            <Logo fill="red" stroke="red" />
            <div className="text-btn-red">{t(i18nKey.form.delete)}</div>
          </button>
          <div>
            <button
              className="b-btn secondary"
              type="button"
              onClick={() => {
                setIsOpenCancelModal(true);
              }}
            >
              {t(i18nKey.form.cancel)}
            </button>
            <button
              className="b-btn"
              type="button"
              onClick={() => {
                dispatch(
                  actions.publishProduct({
                    id: product.id,
                    mediaFolders: mediaFolders || [],
                    shouldUpdate: product?.visible,
                  }),
                );

                deletingDocs.length &&
                  dispatch(
                    actions.deleteMedia({
                      productId: product.id,
                      ids: deletingDocs,
                    }),
                  );

                deletingSubFolders.length &&
                  dispatch(
                    actions.deleteSubFolder({
                      productId: product.id,
                      ids: deletingSubFolders,
                    }),
                  );

                deletingFolders.length &&
                  dispatch(
                    actions.deleteFolder({
                      productId: product.id,
                      ids: deletingFolders,
                    }),
                  );
              }}
            >
              {product.visible
                ? t(i18nKey.form.update)
                : t(i18nKey.form.publish)}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
