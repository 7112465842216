import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { supportActions as actions } from '.';

function* getZohoTicket() {
  const requestURL = `/api/support/faq`;
  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getZohoTicketSuccess(response.data));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getZohoTicketFailed(err));
  }
}
function* getHelpCenterList() {
  const requestURL = `/api/support/helpCenter`;
  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getHelpCenterListSuccess(response.data));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getHelpCenterListFailed(err));
  }
}
function* getHelpCenterCategories() {
  const requestURL = `/api/support/helpCenterCat`;

  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getHelpCenterCategoriesSuccess(response.child));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getHelpCenterCategoriesFailed(err));
  }
}
function* getArticlesbyCategories(action) {
  const requestURL = `/api/support/helpCenterCatArticle/${action.payload}`;

  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getArticlesbyCategoriesSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getArticlesbyCategoriesSuccess(err));
  }
}
function* getArticle(action) {
  const requestURL = `/api/support/helpCenterArticle/${action.payload}`;

  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getArticleSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getArticleFailed(err));
  }
}
function* getJiraTicket(action) {
  const requestURL = `/api/support/request/getUserRequest/${action.payload}`;

  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getJiraTicketSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getJiraTicketFailed(err));
  }
}
function* getJiraTicketDetail(action) {
  const requestURL = `/api/support/request/getUserRequestDetail/${action.payload}`;

  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getJiraTicketDetailSuccess(response));
    let actionPayload = {
      payload: response.issueKey,
      type: actions.getAllComments.type,
    };
    yield put(actionPayload);
    if (response.attachments?.values.length) {
      let actionPayload = {
        payload: action.payload,
        type: actions.getAttachment.type,
      };
      yield put(actionPayload);
    }
  } catch (err) {
    console.error('error', err);
    yield put(actions.getJiraTicketDetailFailed(err));
  }
}
function* getAttachment(action) {
  const requestURL = `/api/support/request/getUserRequestAttachment/${action.payload}`;
  try {
    const response = (yield call(request, requestURL)).data;
    yield put(actions.getAttachmentSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getAttachmentFailed(err));
  }
}
function* addAttachment(action) {
  const requestURL = `/api/support/request/addAttachementToRequest/${action.payload.issueKey}`;
  try {
    const response = (yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ body: action.payload.body }),
    })).data;
    yield put(actions.addAttachmentSuccess(response));
    let actionPayload = {
      payload: action.payload.issueKey,
      type: actions.getAttachment.type,
    };
    yield put(actionPayload);
  } catch (err) {
    console.error('error', err);
    yield put(actions.addAttachmentFailed(err));
  }
}
function* deleteAttachment(action) {
  const requestURL = `/api/support/request/deleteAttachment/${action.payload.attachmentId}`;
  try {
    const response = (yield call(request, requestURL, {
      method: 'DELETE',
    })).data;
    yield put(actions.deleteAttachmentSuccess(response));
    let actionPayload = {
      payload: action.payload.issueKey,
      type: actions.getAttachment.type,
    };
    yield put(actionPayload);
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteAttachmentFailed(err));
  }
}

function* getDeviceType() {
  const requestURL = `/api/support/request/getDeviceTypeList`;
  try {
    let response = (yield call(request, requestURL)).data;
    response = response?.map(d => ({
      value: d.label,
      id: d.value,
      self: `https://supportelistair.atlassian.net/rest/api/2/customFieldOption/${d.value}`,
    }));
    yield put(actions.getDeviceTypeSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getDeviceTypeFailed(err));
  }
}

function* createJiraTicket(action) {
  const requestURL = `/api/support/request/createUserRequest`;
  try {
    const response = (yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        companyName: action.payload.companyName,
        summary: action.payload.summary,
        deviceType: action.payload.deviceType,
        serialNumber: action.payload.serialNumber,
        secondSerialNumber: action.payload.secondSerialNumber,
        thirdSerialNumber: action.payload.thirdSerialNumber,
        eventDate: action.payload.eventDate,
        description: action.payload.description,
        userEmail: action.payload.userEmail,
        attachment: action.payload.attachment,
      }),
    })).data;
    yield put(actions.createJiraTicketSuccess(response));
    let actionPayload = {
      payload: action.payload.userEmail,
      type: actions.getJiraTicket.type,
    };
    yield put(actionPayload);
  } catch (err) {
    console.error('error', err);
    yield put(actions.createJiraTicketFailed(err));
  }
}
function* getAllComments(action) {
  const requestURL = `/api/support/request/getAllComments/${action.payload}`;
  try {
    const response = (yield call(request, requestURL, {
      method: 'GET',
    })).data;

    yield put(actions.getAllCommentsSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getAllCommentsFailed(err));
  }
}
function* sendComment(action) {
  const requestURL = `/api/support/request/sendComment/${action.payload.issueKey}`;
  try {
    let response = (yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ body: action.payload.body }),
    })).data;
    yield put(actions.sendCommentSuccess(response));
    try {
      let actionPayload = {
        payload: action.payload.issueKey,
        type: actions.getAllComments.type,
      };

      yield put(actionPayload);
    } catch (e) {
      console.error(e);
    }
  } catch (err) {
    console.error('error', err);
    yield put(actions.sendCommentFailed(err));
  }
}

function* deleteComment(action) {
  const requestURL = `/api/support/request/deleteComment/${action.payload.issueKey}/${action.payload.commentId}`;
  try {
    let response = (yield call(request, requestURL, {
      method: 'DELETE',
    })).data;
    yield put(actions.deleteCommentSuccess(response));
    let actionPayload = {
      payload: action.payload.issueKey,
      type: actions.getAllComments.type,
    };
    yield put(actionPayload);
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteCommentFailed(err));
  }
}
function* editComment(action) {
  const requestURL = `/api/support/request/editComment/${action.payload.issueKey}/${action.payload.commentId}`;
  try {
    const response = (yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({ body: action.payload.body }),
    })).data;
    yield put(actions.editCommentSuccess(response));
    let actionPayload = {
      payload: action.payload.issueKey,
      type: actions.getAllComments.type,
    };
    yield put(actionPayload);
  } catch (err) {
    console.error('error', err);
    yield put(actions.editCommentFailed(err));
  }
}

export function* supportSaga() {
  yield takeLatest(actions.getZohoTicket.type, getZohoTicket);
  yield takeLatest(actions.getHelpCenterList.type, getHelpCenterList);
  yield takeLatest(actions.getArticle.type, getArticle);
  yield takeLatest(
    actions.getArticlesbyCategories.type,
    getArticlesbyCategories,
  );
  yield takeLatest(
    actions.getHelpCenterCategories.type,
    getHelpCenterCategories,
  );
  yield takeLatest(actions.getAttachment.type, getAttachment);
  yield takeLatest(actions.deleteAttachment.type, deleteAttachment);
  yield takeLatest(actions.getJiraTicket.type, getJiraTicket);
  yield takeLatest(actions.getJiraTicketDetail.type, getJiraTicketDetail);
  yield takeLatest(actions.getDeviceType.type, getDeviceType);
  yield takeLatest(actions.createJiraTicket.type, createJiraTicket);
  yield takeLatest(actions.getAllComments.type, getAllComments);
  yield takeLatest(actions.sendComment.type, sendComment);
  yield takeLatest(actions.deleteComment.type, deleteComment);
  yield takeLatest(actions.editComment.type, editComment);
  yield takeLatest(actions.addAttachment.type, addAttachment);
}
