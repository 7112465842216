import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactComponent as EditLogo } from 'assets/icons/menu-edit-black.svg';
import { useHistory } from 'react-router-dom';
import Pagination from './Pagination';
import { IUser } from '../slice/types';
import NoSorted from './../../../../../assets/icons/no-sorted.svg';
import AscSorted from './../../../../../assets/icons/asc-sorted.svg';
import DscSorted from './../../../../../assets/icons/dsc-sorted.svg';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import cx from 'classnames';
import usePrevious from 'utils/hooks/usePrevious';
import { useMediaQuery } from 'usehooks-ts';
import ArrowDown from 'assets/icons/arrow-down-small.svg';
import ArrowUp from 'assets/icons/arrow-up-small.svg';
import { useTranslation } from 'react-i18next';
import { translations as _ } from 'locales/translations';

declare module '@tanstack/table-core' {
  interface FilterFns {
    groupsFilter?: FilterFn<IUser>;
    createdDateFilter?: FilterFn<IUser>;
    connectDateFilter?: FilterFn<IUser>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function TableUsers({
  data,
  filterActive,
  searchedTerm,
  setSelectedUsers,
}) {
  let navigate = useHistory().push;
  const { t } = useTranslation();
  const i18nKey = _.listUsers;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [dataOnPage, setDataOnPage] = useState<any[]>(
    data.map(u => ({ ...u, checked: false })),
  );

  const isMobile = useMediaQuery('(max-width: 767px)');
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [showRowInfo, setShowRowInfo] = React.useState<string>('');

  useEffect(() => {
    if (isMobile) {
      setColumnVisibility({
        email: false,
        groups: false,
        crmId: false,
        create: false,
        connect: false,
        quote: false,
        moodle: false,
        edit: false,
        access: false,
        role: false,
      });
    } else {
      setColumnVisibility({
        'more-info': false,
        role: false,
        access: false,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    setGlobalFilter(searchedTerm);
  }, [searchedTerm]);

  useEffect(() => {
    let filter = filterActive.map(i => {
      if (i?.label?.includes('Yes') || i?.label?.includes('No')) {
        return { id: 'moodle', value: i.value };
      }
      if (
        i?.label?.includes('Restricted') ||
        i?.label?.includes('Unrestricted')
      ) {
        return { id: 'access', value: i.value };
      }
      if (i?.checked) {
        return { id: 'groups', value: i.label };
      }
      if (i?.value?.includes('Creation')) {
        return { id: 'create', value: i.value };
      }
      if (i?.value?.includes('Last connection')) {
        return { id: 'connect', value: i.value };
      }
    });
    setColumnFilters(filter);
  }, [filterActive]);

  const groupsFilter: FilterFn<IUser> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };
  const createdDateFilter: FilterFn<IUser> = (row, columnId, value) => {
    let start = new Date(value.split('Date')[1].split('/')[0])
      .setHours(0, 0, 1)
      .valueOf();
    let end = new Date(value.split('Date')[1].split('/')[1])
      .setHours(23, 59, 59)
      .valueOf();
    let item = new Date(row.getValue(columnId)).valueOf();

    return item >= start && item <= end ? true : false;
  };
  const connectDateFilter: FilterFn<IUser> = (row, columnId, value) => {
    let start = new Date(value.split('Date')[1].split('/')[0])
      .setHours(0, 0, 1)
      .valueOf();
    let end = new Date(value.split('Date')[1].split('/')[1])
      .setHours(23, 59, 59)
      .valueOf();
    let item = new Date(row.getValue(columnId)).valueOf();

    let startNever = value.split('Date')[1].split('/')[0].trim();

    if (startNever === 'NEVER') {
      return row.getValue(columnId) === 'NEVER' ? true : false;
    }

    return item >= start && item <= end ? true : false;
  };

  function handleCheckBoxEvent(value) {
    if (dataOnPage) {
      let newData = dataOnPage.map(u => {
        if (u.idLocal === value) {
          u.checked = !u.checked;
        }
        return u;
      });
      setDataOnPage(newData);
    }
  }

  function handleShowMore(value) {
    if (showRowInfo !== value) {
      return setShowRowInfo(value);
    }
    if (showRowInfo === value) {
      return setShowRowInfo('');
    }
  }

  let prevDataOnPage = usePrevious(dataOnPage);
  useEffect(() => {
    if (dataOnPage && prevDataOnPage !== dataOnPage) {
      let selectedUser = dataOnPage.filter(item => !!item.checked);
      setSelectedUsers(selectedUser);
    }
  }, [handleCheckBoxEvent]);

  const columns = React.useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        id: 'id',
        cell: info => {
          return (
            <div className="small">
              <input
                className="b-i-checkbox"
                type="checkbox"
                id={info.row.original.id}
                onChange={() => handleCheckBoxEvent(info.row.original.idLocal)}
              />
            </div>
          );
        },
        header: () => <div className="small"></div>,
        footer: props => props.column.id,
        enableSorting: false,
      },
      {
        accessorFn: row => row.lastname,
        id: 'lastname',
        cell: info => {
          const row = info.row.original;
          const classNameRoundedRestricted =
            row.role === 'restricted_access' ? 'rounded-restricted' : '';
          const classNameAlignRestricted =
            row.role === 'restricted_access' ? 'align-restricted' : '';
          const value = (info.getValue() !== undefined
            ? info.getValue()
            : null) as string | undefined;
          return (
            <>
              <div className={classNameAlignRestricted}>
                {value}
                <span
                  data-tooltip-place="right"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Restricted access"
                  className={classNameRoundedRestricted}
                />
              </div>
              <Tooltip
                id="my-tooltip"
                style={{ maxWidth: 'none', overflow: 'hidden' }}
              />
            </>
          );
        },
        header: () => (
          <div>
            {!isMobile ? (
              <div>LastName</div>
            ) : (
              <div>
                <span>Last</span>
                <br />
                <span>Name</span>
              </div>
            )}
          </div>
        ),
        footer: props => props.column.id,
        enableSorting: !isMobile,
      },
      {
        accessorFn: row => row.firstname,
        id: 'firstname',
        cell: info => (info.getValue() !== undefined ? info.getValue() : null),
        header: () => (
          <div>
            {!isMobile ? (
              <div>FirstName</div>
            ) : (
              <div>
                <span>First</span>
                <br />
                <span>Name</span>
              </div>
            )}
          </div>
        ),
        footer: props => props.column.id,
        enableSorting: !isMobile,
      },
      {
        accessorFn: row => row.companyName,
        id: 'companyName',
        cell: info => (info.getValue() !== undefined ? info.getValue() : null),
        header: () => <span>Company</span>,
        footer: props => props.column.id,
        enableSorting: !isMobile,
      },
      {
        accessorFn: row => row.role,
        id: 'role',
        cell: info => (info.getValue() !== undefined ? info.getValue() : null),
        header: () => <span>role</span>,
        footer: props => props.column.id,
        enableSorting: false,
      },
      {
        accessorFn: row => row.restricted,
        id: 'access',
        cell: info => (info.getValue() !== undefined ? info.getValue() : null),
        header: () => <span>access</span>,
        footer: props => props.column.id,
        enableSorting: false,
      },
      {
        accessorFn: row => row.email,
        id: 'email',
        cell: info => (info.getValue() !== undefined ? info.getValue() : null),
        header: () => <span>E-mail</span>,
        footer: props => props.column.id,
        enableSorting: true,
        hiddenColumns: !isMobile,
      },
      {
        accessorFn: row => row.groups,
        id: 'groups',
        cell: info => {
          let groups = info.getValue() as [];
          return groups !== undefined ? (
            <div className="small">{groups.join(' / ')}</div>
          ) : null;
        },
        header: () => <span>Groups</span>,
        footer: props => props.column.id,
        filterFn: 'groupsFilter',
        enableSorting: true,
        hiddenColumns: !isMobile,
      },
      {
        accessorFn: row => row.crmId,
        id: 'crmId',
        cell: info => {
          const value = info.getValue() as string | undefined;
          return value !== undefined ? (
            <div className="small">{value || null}</div>
          ) : null;
        },
        header: () => <span>ID</span>,
        footer: props => props.column.id,
        enableSorting: false,
        hiddenColumns: !isMobile,
      },
      {
        accessorFn: row => row.createdDateTime,
        id: 'create',
        cell: info => {
          const create = info.getValue() as string | undefined;
          return create !== undefined
            ? new Date(create).toLocaleDateString()
            : null;
        },
        header: () => <span>Creation</span>,
        footer: props => props.column.id,
        filterFn: 'createdDateFilter',
        enableSorting: false,
        hiddenColumns: !isMobile,
      },
      {
        accessorFn: row => row.lastLoginDate,
        id: 'connect',
        cell: info => {
          let connect = info.getValue() as string | undefined;
          return connect !== undefined
            ? connect === 'NEVER'
              ? 'Never'
              : new Date(connect).toLocaleDateString()
            : null;
        },
        header: () => <span>Last connection</span>,
        footer: props => props.column.id,
        filterFn: 'connectDateFilter',
        enableSorting: false,
        hiddenColumns: !isMobile,
      },
      {
        accessorFn: row => row.quote,
        id: 'quote',
        cell: info => {
          const value = info.getValue();
          return value !== undefined ? (
            <div className="small">
              <div className="block">{value ? 'Yes' : 'No'}</div>
            </div>
          ) : null;
        },
        header: () => (
          <div className="small">
            <span>Quote</span>
          </div>
        ),
        footer: props => props.column.id,
        hiddenColumns: !isMobile,
        enableSorting: false,
      },
      {
        accessorFn: row => row.moodle,
        id: 'moodle',
        cell: info => {
          const value = info.getValue();
          return value !== undefined ? (
            <div className="small">
              <div className="block">{value ? 'Yes' : 'No'}</div>
            </div>
          ) : null;
        },
        header: () => (
          <div className="small">
            <span>Moodle</span>
          </div>
        ),
        footer: props => props.column.id,
        enableSorting: false,
        hiddenColumns: !isMobile,
      },
      {
        id: 'edit',
        cell: info => {
          const original = info.row.original;
          return original !== undefined ? (
            <div className="small">
              <EditLogo
                className="cta"
                onClick={() => navigate('/edit-user', { user: original })}
              />
            </div>
          ) : null;
        },
        header: () => <div className="small"></div>,
        footer: props => props.column.id,
        enableSorting: false,
        hiddenColumns: !isMobile,
      },
      {
        id: 'more-info',
        show: isMobile,
        cell: info => {
          const original = info.row.original;
          return original !== undefined ? (
            <div onClick={() => handleShowMore(original.id)}>
              <img
                alt="show-more-option"
                src={showRowInfo === original.id ? ArrowUp : ArrowDown}
                className="button-show-more"
              />
            </div>
          ) : null;
        },
        enableSorting: false,
        header: () => <span></span>,
      },
    ],
    [],
  );

  const table = useReactTable({
    data: dataOnPage,
    columns,
    initialState: {
      pagination: {
        pageSize: 25,
      },
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    filterFns: {
      groupsFilter: groupsFilter,
      createdDateFilter: createdDateFilter,
      connectDateFilter: connectDateFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className="table">
      <div className="user-count">
        <span>{`${table.getSortedRowModel().rows.length} users`}</span>
      </div>

      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: cx(
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                          ),
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: (
                            <img
                              alt="asc-sorted"
                              src={AscSorted}
                              className="sorted-icn asc-icn"
                            />
                          ),
                          desc: (
                            <img
                              alt="dsc-sorted"
                              src={DscSorted}
                              className="sorted-icn dsc-icn"
                            />
                          ),
                        }[header.column.getIsSorted() as string] ??
                          (header.column.columnDef.enableSorting ? (
                            <img alt="no-sorted" src={NoSorted} />
                          ) : null)}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            ? table
                .getRowModel()
                .rows.slice(0, 25)
                .map(row => {
                  return !isMobile ? (
                    <tr className="row-request" key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ) : (
                    <>
                      <tr
                        key={row.id}
                        className="row-request"
                        onClick={() => handleShowMore(row.original.id)}
                        aria-rowspan={2}
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          );
                        })}
                      </tr>

                      {showRowInfo === row.original.id ? (
                        <>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.email)}</td>
                            <td colSpan={3}>{row.original.email}</td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.group)}</td>
                            <td colSpan={3}>{row.original.groups}</td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.id)}</td>
                            <td colSpan={3}>{row.original.crmId}</td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.create)}</td>
                            <td colSpan={3}>
                              {new Date(
                                row.original.createdDateTime,
                              ).toLocaleDateString()}
                            </td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.connect)}</td>
                            <td colSpan={3}>
                              {new Date(
                                row.original.lastLoginDate,
                              ).toLocaleDateString() !== 'Invalid Date'
                                ? new Date(
                                    row.original.lastLoginDate,
                                  ).toLocaleDateString()
                                : 'NEVER'}
                            </td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.quote)}</td>
                            <td colSpan={3} className="block">
                              <span className="mobile-content">
                                {row.original.quote ? 'Yes' : 'No'}
                              </span>
                            </td>
                          </tr>
                          <tr className="more-info-row-mobile">
                            <td colSpan={2}>{t(i18nKey.table.moodle)}</td>
                            <td colSpan={3} className="block">
                              <span className="mobile-content">
                                {row.original.moodle ? 'Yes' : 'No'}
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : null}
                    </>
                  );
                })
            : null}
        </tbody>
      </table>
      <Pagination table={table} />
    </div>
  );
}
