import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { supportSaga } from './saga';
import { SupportState } from './types';

export const initialState: SupportState = {
  zohoTickets: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
  helpCenter: {
    loading: false,
    success: false,
    error: false,
    articles: [],
    categories: [],
    article: undefined,
  },
  jiraRequest: {
    loading: false,
    loadingComment: false,
    allComments: [],
    status: 'initial',
    success: false,
    error: false,
    deviceType: [],
    ticketDetail: undefined,
    ticketList: [],
    attachment: [],
    loadingAttachment: false,
  },
};

const slice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    getZohoTicket(state) {
      state.zohoTickets.loading = true;
      state.zohoTickets.success = false;
      state.zohoTickets.error = false;
      state.zohoTickets.data = undefined;
    },
    getZohoTicketSuccess(state, action: PayloadAction<SupportState>) {
      state.zohoTickets.loading = false;
      state.zohoTickets.success = true;
      state.zohoTickets.error = false;
      state.zohoTickets.data = action.payload;
    },
    getZohoTicketFailed(state, action: PayloadAction<any>) {
      state.zohoTickets.loading = false;
      state.zohoTickets.success = false;
      state.zohoTickets.error = true;
    },
    getHelpCenterList(state) {
      state.helpCenter.loading = true;
      state.helpCenter.success = false;
      state.helpCenter.error = false;
      state.helpCenter.articles = undefined;
    },
    getHelpCenterListSuccess(state, action: PayloadAction<SupportState>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = true;
      state.helpCenter.error = false;
      state.helpCenter.articles = action.payload;
    },
    getHelpCenterListFailed(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = false;
      state.helpCenter.error = true;
    },
    getHelpCenterCategories(state) {
      state.helpCenter.loading = true;
      state.helpCenter.success = false;
      state.helpCenter.error = false;
      state.helpCenter.categories = undefined;
    },
    getHelpCenterCategoriesSuccess(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = true;
      state.helpCenter.error = false;
      state.helpCenter.categories = action.payload;
    },
    getHelpCenterCategoriesFailed(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = false;
      state.helpCenter.error = true;
    },
    getArticlesbyCategories(state, action: PayloadAction<string>) {
      state.helpCenter.loading = true;
      state.helpCenter.success = false;
      state.helpCenter.error = false;
      state.helpCenter.articles = undefined;
    },
    getArticlesbyCategoriesSuccess(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = true;
      state.helpCenter.error = false;
      state.helpCenter.articles = action.payload;
    },
    getArticlesbyCategoriesFailed(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = false;
      state.helpCenter.error = true;
    },
    getArticle(state, action: PayloadAction<string>) {
      state.helpCenter.loading = true;
      state.helpCenter.success = false;
      state.helpCenter.error = false;
      state.helpCenter.article = undefined;
    },
    getArticleSuccess(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = true;
      state.helpCenter.error = false;
      state.helpCenter.article = action.payload;
    },
    getArticleFailed(state, action: PayloadAction<any>) {
      state.helpCenter.loading = false;
      state.helpCenter.success = false;
      state.helpCenter.error = true;
    },
    getJiraTicket(state, action: PayloadAction<string>) {
      state.jiraRequest.loading = true;
      state.jiraRequest.error = false;
    },
    getJiraTicketSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.error = false;
      state.jiraRequest.ticketList = action.payload;
    },
    getJiraTicketFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.error = true;
    },
    getJiraTicketDetail(state, action: PayloadAction<string>) {
      state.jiraRequest.loading = true;
      state.jiraRequest.error = false;
    },
    getJiraTicketDetailSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.status = 'initial';
      state.jiraRequest.error = false;
      state.jiraRequest.ticketDetail = action.payload;
    },
    getJiraTicketDetailFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    getAttachment(state, action: PayloadAction<string>) {
      state.jiraRequest.loadingAttachment = true;
      state.jiraRequest.error = false;
    },
    getAttachmentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.status = 'initial';
      state.jiraRequest.error = false;
      state.jiraRequest.attachment = action.payload;
    },
    getAttachmentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    deleteAttachment(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = true;
      state.jiraRequest.error = false;
    },
    deleteAttachmentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.status = 'initial';
      state.jiraRequest.error = false;
      state.jiraRequest.attachment = action.payload;
    },
    deleteAttachmentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    addAttachment(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = true;
      state.jiraRequest.error = false;
    },
    addAttachmentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.status = 'initial';
      state.jiraRequest.error = false;
      state.jiraRequest.attachment = action.payload;
    },
    addAttachmentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingAttachment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    getDeviceType(state) {
      state.jiraRequest.loading = true;
      state.jiraRequest.error = false;
    },
    getDeviceTypeSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.error = false;
      state.jiraRequest.deviceType = action.payload;
    },
    getDeviceTypeFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.error = true;
    },
    createJiraTicket(state, action: PayloadAction<string>) {
      state.jiraRequest.loading = true;
      state.jiraRequest.success = false;
      state.jiraRequest.error = false;
    },
    createJiraTicketSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.success = true;
      state.jiraRequest.status = 'new';
      state.jiraRequest.error = false;
    },
    createJiraTicketFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loading = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    getAllComments(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = true;
      state.jiraRequest.success = false;
      state.jiraRequest.error = false;
    },
    getAllCommentsSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.allComments = action.payload;
      state.jiraRequest.success = true;
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.error = false;
    },
    getAllCommentsFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    sendComment(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = true;
      state.jiraRequest.success = false;
      state.jiraRequest.error = false;
    },
    sendCommentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = true;
      state.jiraRequest.status = 'new';
      state.jiraRequest.error = false;
    },
    sendCommentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    deleteComment(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = true;
      state.jiraRequest.success = false;
      state.jiraRequest.error = false;
    },
    deleteCommentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = true;
      state.jiraRequest.status = 'new';
      state.jiraRequest.error = false;
    },
    deleteCommentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
    editComment(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = true;
      state.jiraRequest.success = false;
      state.jiraRequest.error = false;
    },
    editCommentSuccess(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = true;
      state.jiraRequest.status = 'new';
      state.jiraRequest.error = false;
    },
    editCommentFailed(state, action: PayloadAction<any>) {
      state.jiraRequest.loadingComment = false;
      state.jiraRequest.success = false;
      state.jiraRequest.error = true;
    },
  },
});

export const { actions: supportActions } = slice;

export const useSupportSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: supportSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useUserInformationsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
