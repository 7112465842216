import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { searchSaga } from './saga';
import { SearchState } from './types';

export const initialState: SearchState = {
  loading: false,
  success: false,
  error: false,
  data: undefined,
  search: undefined,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch(state) {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.search = undefined;
    },
    getSearchMedia(state, action: PayloadAction<any>) {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.search = action.payload;
    },
    getSearchMediaSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data = action.payload;
    },
    getSearchMediaFailed(state, action: PayloadAction<any>) {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: appActions } = slice;

export const useSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: searchSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.getSession());
 *   };
 * }
 */
