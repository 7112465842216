import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.dashboard || initialState;

export const selectDashboard = createSelector([selectSlice], state => state);

export const selectPushProduct = createSelector(
  [selectSlice],
  state => state.pushProduct,
);

export const selectProductDetail = createSelector(
  [selectSlice],
  state => state.getProductDetail,
);

export const selectGuidelineURL = createSelector(
  [selectSlice],
  state => state.getGuidelineUrl,
);

export const selectDeleteMedia = createSelector(
  [selectSlice],
  state => state.deleteMedia,
);

export const selectDeleteSubFolder = createSelector(
  [selectSlice],
  state => state.deleteSubFolder,
);

export const selectDeleteFolder = createSelector(
  [selectSlice],
  state => state.deleteFolder,
);

export const selectAddVideo = createSelector(
  [selectSlice],
  state => state.addVideo,
);

export const selectDeleteProduct = createSelector(
  [selectSlice],
  state => state.deleteProduct,
);

export const selectPublishProduct = createSelector(
  [selectSlice],
  state => state.publishProduct,
);
export const selectUpdateMediaFolderName = createSelector(
  [selectSlice],
  state => state.updateMediaFolderName,
);
