import React, { useEffect, useRef } from 'react';
import elistairLogo from '../../../assets/images/elistair-logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import { translations as _ } from '../../../locales/translations';
import CustomHeader from '../../components/CustomHeader';
import DocumentIconWhite from 'assets/icons/document-icon-white.svg';
import DownloadIconWhite from 'assets/icons/download-icon-white.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSessionUserRole } from '../../slice/selectors';
import { E_USER_ROLE } from '../../../types';

export default function Restricted() {
  const { t } = useTranslation();
  let history = useHistory();
  const i18nKey = _.dashboard;
  const i18nKeyRestricted = _.restricted;
  const role = useSelector(selectSessionUserRole);
  const anchorRef1 = useRef<HTMLDivElement | null>(null);
  const anchorRef2 = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#anchor-form-1' && anchorRef1.current) {
      anchorRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#anchor-form-2' && anchorRef2.current) {
      anchorRef2.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.hash]);

  return (
    <div className="restricted">
      <div className="restricted-intro">
        <div className="restricted-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
        <div className="restricted-intro-south">
          <h1>{t(i18nKey.titleLogin)}</h1>
          <p>{t(i18nKey.subtitle1)}</p>
          <p>{t(i18nKey.subtitle2)}</p>
        </div>
      </div>
      <div className="restricted-content">
        <CustomHeader showLink={true} hideSearch={true} invertedColor={true} />
        <div className="restricted-content-info">
          <h1>{t(i18nKey.titleLogin)}</h1>
          <div className="restricted-content-info-support">
            <Trans
              i18nKey="restricted.ticket"
              components={{ 1: <Link to="/request" /> }}
            />
          </div>
          <div>
            <p>{t(i18nKeyRestricted.download)}</p>
            <a
              href={'https://elistair.com/app.apk'}
              className="restricted-content-info-download"
            >
              <img src={DocumentIconWhite} alt="document" />
              <span>T-Monitor App</span>
              <img src={DownloadIconWhite} alt="download" />
            </a>
          </div>
          <div>
            <p ref={anchorRef1} id="anchor-form-1">
              {t(i18nKeyRestricted.request)}
            </p>
            <div ref={anchorRef2} id="anchor-form-2"></div>
            <iframe
              id="JotFormIFrame-241973095896373"
              title="TEST POUR PORTAIL - V4 - FR & EN"
              allowTransparency={true}
              allow="geolocation; microphone; camera; fullscreen"
              src="https://form.jotform.com/241973095896373"
              frameBorder="0"
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                height: '539px',
                border: 'none',
              }}
              scrolling="no"
            ></iframe>
            <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
            <script>
              window.jotformEmbedHandler("iframe[id='JotFormIFrame-241973095896373']",
              "https://form.jotform.com/")
            </script>
          </div>
        </div>
      </div>
    </div>
  );
}
