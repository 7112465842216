import React, { useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSupportSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import AvatarJira from './AvatarJira';
import { selectUserInformationsGet } from 'app/pages/user-informations/slice/selectors';
import cx from 'classnames';
import deleteIcon from 'assets/icons/menu-delete-normal.svg';
import editIcon from 'assets/icons/menu-edit-black.svg';
import CommentEdit from './CommentEdit';
import { selectJiraRequest } from '../../slice/selectors';
import PreviewMedia from './PreviewMedia';
import PreviewAttachment from './PreviewAttachment';

export default function CommentList() {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: actionsSupport } = useSupportSlice();
  let { requestId } = useParams<{ requestId: string }>();
  const { data: user } = useSelector(selectUserInformationsGet);
  const [editCommentId, setEditCommentId] = useState<string>('');
  const { attachment, allComments, loadingComment } = useSelector(
    selectJiraRequest,
  );

  const [mediaSelected, setMediaSelected] = useState();
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  function handleDeleteComment(comment) {
    let data = { commentId: comment.id, issueKey: requestId };
    dispatch(actionsSupport.deleteComment(data));
  }

  return (
    <div className="comment-container">
      <div className="activity-text">{t(i18nKey.activity)}</div>

      {allComments?.map((c, i) => {
        let text = c?.body?.content
          ?.find(e => e.type === 'paragraph')
          ?.content?.filter(e => e.type === 'text');

        let isMark = c?.body?.content?.find(e => e.type === 'paragraph')
          ?.content?.[1]?.marks?.length;

        let commentAttachment = attachment.length
          ? attachment
              ?.map(att => {
                return c?.body?.content
                  ?.find(e => e.type === 'mediaGroup')
                  ?.content.map(c => {
                    if (att?.content?.includes(c.attrs.id)) {
                      return att;
                    }
                  });
              })
              .flat()
              .filter(e => e)
          : [];

        if ((!commentAttachment?.length && !text?.length) || isMark) {
          return null;
        }

        return (
          <div
            key={c.created + i}
            className={cx('comment-global', {
              user: c.author.displayName === 'Portal SAV Requests',
              assignee: c.author.displayName !== 'Portal SAV Requests',
            })}
          >
            <div className="info-comment-container">
              <div className="row">
                <AvatarJira
                  name={
                    c.author.displayName === 'Portal SAV Requests'
                      ? `${user?.firstname} ${user?.lastname}`
                      : `${c.author.displayName}`
                  }
                  type={
                    c.author.displayName === 'Portal SAV Requests'
                      ? 'user'
                      : 'assignee'
                  }
                />
                <span>
                  {c.author.displayName === 'Portal SAV Requests'
                    ? `${user?.firstname} ${user?.lastname} at ${new Date(
                        c.created,
                      ).toLocaleString()}`
                    : `${c.author.displayName} at ${new Date(
                        c.created,
                      ).toLocaleString()}`}
                </span>
              </div>
              <div className={cx('row actions')}>
                <img
                  onClick={() => {
                    editCommentId !== c.id
                      ? setEditCommentId(c.id)
                      : setEditCommentId('');
                  }}
                  alt="edit-comment"
                  src={editIcon}
                  className="icon edit"
                />
                <img
                  onClick={() => {
                    handleDeleteComment(c);
                  }}
                  alt="delete-comment"
                  src={deleteIcon}
                  className="icon"
                />
              </div>
            </div>
            {editCommentId === c.id ? (
              <>
                <CommentEdit commentData={c} />
              </>
            ) : (
              <div>
                {text
                  ? text?.map(g => {
                      return (
                        <div className="comment-body">{g.text.trim()}</div>
                      );
                    })
                  : null}
                {commentAttachment ? (
                  <PreviewAttachment
                    attachment={commentAttachment}
                    loadingAttachment={loadingComment}
                    setMediaSelected={setMediaSelected}
                    setIsOpenPreview={setIsOpenPreview}
                    type="comment"
                  />
                ) : null}
              </div>
            )}
          </div>
        );
      })}
      <PreviewMedia
        currentElement={mediaSelected}
        isOpen={isOpenPreview}
        setIsOpen={setIsOpenPreview}
      />
    </div>
  );
}
