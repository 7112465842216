import { IUserInfos } from 'app/pages/user-informations/slice/types';
import request, { ResponseError } from 'utils/request';
import { IRequest } from '../../slice/types';

export async function isAllowed(requestId: string, navigate) {
  try {
    const requestUseInfoURL = `/api/login/info`;

    let userData: { data: IUserInfos } | { err: ResponseError } = await request(
      requestUseInfoURL,
    );
    if ('err' in userData) {
      console.error(userData.err.message);
      navigate(`/request`);
      return false;
    }

    if (userData?.data.email) {
      const requestURL = `/api/support/request/getUserRequest/${userData?.data.email}`;
      let allowedTicket:
        | { data: IRequest[] }
        | {
            err: ResponseError;
          } = await request(requestURL);

      if ('err' in allowedTicket) {
        console.error(allowedTicket.err.message);
        navigate(`/request`);
        return false;
      }

      if (allowedTicket.data.filter(t => t.issueKey === requestId)?.length) {
        return true;
      }
    }
    navigate(`/request`);
    return false;
  } catch (e) {
    console.error(e);
    navigate(`/request`);
    return false;
  }
}
