import React from 'react';

export default function Toogle({ label, value, onChange }) {
  return (
    <div className="toggle-container">
      <label className="switch">
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="toggle-slider round"></span>
      </label>
      {label}
    </div>
  );
}
