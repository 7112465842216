import React from 'react';
import { ReactComponent as NextLogo } from 'assets/icons/next.svg';
import { ReactComponent as PreviousLogo } from 'assets/icons/previous.svg';

const Pagination = ({ table }) => {
  return (
    <nav>
      <ul className="pagination">
        <li
          className={!table.getCanPreviousPage() ? 'disabled' : ''}
          onClick={() => table.previousPage()}
        >
          <PreviousLogo />
        </li>

        <span>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
        <li
          className={!table.getCanNextPage() ? 'disabled' : ''}
          onClick={() => table.nextPage()}
        >
          <NextLogo />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
