import React, { useRef, useState } from 'react';
import VideoIcon from 'assets/icons/video-icon.svg';
import VideoIconWhite from 'assets/icons/video-icon-white.svg';
import DocumentIcon from 'assets/icons/document-icon.svg';
import ImgIcon from 'assets/icons/img-icon.svg';
import ImgIconWhite from 'assets/icons/img-icon-white.svg';
import DocumentIconWhite from 'assets/icons/document-icon-white.svg';
import ArrowBackWhite from 'assets/icons/arrow-back-white.svg';
import Modal from 'react-modal';
import { Document, pdfjs, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { translations as _ } from 'locales/translations';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Modal.setAppElement(document.getElementById('root'));

export default function PreviewMedia(props: any) {
  const { currentElement, isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const i18nKey = _.productDetail;
  const vidRef = useRef(null);
  const [numPages, setNumPages] = useState(null);

  function getImageType(name, isWhite = false) {
    switch (name?.split('.').pop().toLowerCase()) {
      case 'pdf':
        return isWhite ? DocumentIconWhite : DocumentIcon;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return isWhite ? ImgIconWhite : ImgIcon;
      default:
        return isWhite ? VideoIconWhite : VideoIcon;
    }
  }

  function returnExtention(element) {
    return element?.split('.')?.pop().toLowerCase();
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { innerWidth: width } = window;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Example Modal"
      className="modalDetail"
      overlayClassName="modalDetail-overlay"
    >
      <div className="modalDetail-north">
        <div className="modalDetail-north-name">
          <img
            onClick={() => setIsOpen(false)}
            alt="back"
            src={ArrowBackWhite}
          />
          <img
            alt="download"
            id="document"
            src={getImageType(currentElement?.filename, true)}
          />
          {currentElement?.filename}
        </div>
      </div>
      <div
        className={`modalDetail-south ${
          currentElement?.mimeType.includes('image') &&
          returnExtention(currentElement?.filename) !== 'pdf'
            ? 'picture'
            : ''
        }`}
      >
        {currentElement?.mimeType.includes('image') && (
          <img src={currentElement?.content} alt="" />
        )}
        {currentElement?.mimeType.includes('pdf') && (
          <div className="modalDetail-south-middle">
            <Document
              loading={t(i18nKey.loading)}
              file={currentElement?.content}
              className="π_su"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {[...Array(numPages)].map((el, i) => {
                return (
                  <Page
                    key={'page' + i}
                    loading=""
                    pageNumber={i + 1}
                    scale={0.65}
                    width={width * 0.8}
                  />
                );
              })}
            </Document>
          </div>
        )}
        {currentElement?.mimeType.includes('video') && (
          <video
            ref={vidRef}
            style={{ width: '90vw', height: '80vh' }}
            src={currentElement?.content}
            controls
          />
        )}
      </div>
    </Modal>
  );
}
