import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDeleteMedia,
  selectProductDetail,
  selectPublishProduct,
  selectPushProduct,
} from 'app/pages/dashboard/slice/selectors';
import ArrowUpIcon from 'assets/icons/arrow-up.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MediaFolder from './components/MediaFolder';
import FolderModal from '../modals/FolderModal';
import {
  MediasWithCode,
  orderedMediaFiles,
} from 'app/pages/dashboard/slice/types';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import AddProductImg from 'assets/icons/add-product.svg';

interface MediaOption {
  value: string;
  label: React.ReactNode;
  order: string | number;
  subfolder?: {
    value: string;
    order: string | number;
    label: string;
  }[];
}
export default function Documentation({
  setMediaFileOrder,
  setIsDocumentationOpen,
  isDocumentationOpen,
  setDeletingDocs,
  deletingDocs,
  deletingSubFolders,
  setDeletingSubFolders,
  deletingFolders,
  setDeletingFolders,
  setMediaFolder,
  mediaFolders,
}) {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  let { data: productDetail } = useSelector(selectProductDetail);
  let { loading: publishProductLoading } = useSelector(selectPublishProduct);
  let { success: mediaDeletedSuccess } = useSelector(selectDeleteMedia);

  const i18nKey = _.productEdit;
  let { product } = useSelector(selectPushProduct);
  const [mediaOptions, setMediaOptions] = useState<MediaOption[]>();
  const [showFolderPopin, setShowFolderPopin] = useState(false);
  const [orderedMediaFile, setOrderedMediaFile] = useState<orderedMediaFiles>({
    mFile: [],
    mFIndex: 0,
  });
  const [orderedSubmediaFile, setOrderedSubmediaFile] = useState<
    orderedMediaFiles
  >({
    mFile: [],
    mFIndex: 0,
    mSFIndex: 0,
  });
  useEffect(() => {
    if (product) {
      let newMedias: MediaOption[] = [];
      if (mediaFolders) {
        newMedias = mediaFolders.map((m, i) => {
          return {
            value: m.code,
            label: m.code.charAt(0) + m.code.slice(1).toLowerCase(),
            order: m.order,
            subfolder: m?.subfolder?.map(e => ({
              value: e.code,
              order: e.order,
              label: e.code.charAt(0) + e.code.slice(1).toLowerCase(),
            })),
          };
        });
      }
      newMedias.push({
        value: 'value',
        label: (
          <div className="new-create">
            <div className="new-create-bloc-img">
              <img src={AddProductImg} alt="Add folder" />
            </div>
            <div>{t(i18nKey.form.createFolder)}</div>
          </div>
        ),
        order: '',
      });
      setMediaOptions(newMedias);
      dispatch(actions.getProductDetail(product.product_code));
    }
  }, [actions, dispatch, product, mediaDeletedSuccess]);

  useEffect(() => {
    if (orderedMediaFile.mFile.length && productDetail) {
      let newMediaFolder: any = [...mediaFolders];
      let mediaFileIndex: number = orderedMediaFile.mFIndex;
      newMediaFolder[mediaFileIndex] = {
        ...newMediaFolder[mediaFileIndex],
        medias: orderedMediaFile.mFile,
      };

      setMediaFolder(newMediaFolder);
    }
  }, [orderedMediaFile]);

  useEffect(() => {
    if (
      orderedSubmediaFile.mFile.length &&
      productDetail &&
      orderedSubmediaFile.mSFIndex !== undefined
    ) {
      let newMediaFolder: any[] = [...mediaFolders];

      let mediaFileIndex: number = orderedSubmediaFile.mFIndex;
      let mediaSubfileIndex: number = orderedSubmediaFile.mSFIndex;

      let newSubmediaFolder: any = {
        ...mediaFolders[mediaFileIndex].subfolder[mediaSubfileIndex],
        medias: orderedSubmediaFile.mFile,
      };

      let newSubfolder = [...newMediaFolder[mediaFileIndex].subfolder];

      newSubfolder.splice(mediaSubfileIndex, 1, newSubmediaFolder);

      newMediaFolder[mediaFileIndex] = {
        ...newMediaFolder[mediaFileIndex],
        subfolder: newSubfolder,
      };
      setMediaFolder(newMediaFolder);
    }
  }, [orderedSubmediaFile]);

  function onOrderChange(
    index: number,
    element: MediasWithCode,
    direction: 'up' | 'down',
  ) {
    let coord = direction === 'up' ? index - 1 : index + 1;
    if (mediaFolders) {
      let newMediaFolders = [...mediaFolders];
      newMediaFolders.splice(index, 1);
      newMediaFolders.splice(coord, 0, element);
      const newMF = newMediaFolders.map((m, i) => ({ ...m, order: i }));
      setMediaFolder(newMF);
    }
  }

  function onSubfolderOrderChange(
    index: number,
    element: MediasWithCode,
    parentMF: MediasWithCode,
    direction: 'up' | 'down',
  ) {
    let coord = direction === 'up' ? index - 1 : index + 1;

    if (mediaFolders && parentMF.subfolder) {
      let newMediaSubolders = [...parentMF.subfolder];

      newMediaSubolders.splice(index, 1);
      newMediaSubolders.splice(coord, 0, element);

      const newMFSubfolder = newMediaSubolders.map((m, i) => ({
        ...m,
        order: i,
      }));

      let copyMediaFolder = [...mediaFolders];
      let copyParentMF = { ...parentMF };

      copyParentMF.subfolder = newMFSubfolder;

      let newMF = copyMediaFolder.map(u =>
        u.code !== parentMF.code ? u : copyParentMF,
      );

      setMediaFolder(newMF);
    }
  }

  return (
    <div
      className={cx('product-edit-content-page-form-documentation', {
        isDisabled: !product || publishProductLoading,
      })}
    >
      <div className="product-info-header">
        <div className="product-info-header-left">
          <h4>{t(i18nKey.documentationTitle)}</h4>
          <h5>{t(i18nKey.documentationSubtitle)}</h5>
        </div>
        {product && (
          <div className="product-info-header-right">
            <img
              alt="arrow"
              src={isDocumentationOpen ? ArrowUpIcon : ArrowDownIcon}
              className="arrow"
              onClick={() => setIsDocumentationOpen(!isDocumentationOpen)}
            />
          </div>
        )}
      </div>
      {isDocumentationOpen && product && (
        <div className="product-info-content">
          <button className="b-btn" onClick={() => setShowFolderPopin(true)}>
            {t(i18nKey.ctaAddDocument)}
          </button>

          {mediaFolders?.map((mediaFolder, index) => (
            <>
              <MediaFolder
                onOrderChange={onOrderChange}
                onSubfolderOrderChange={onSubfolderOrderChange}
                mediaFolder={mediaFolder}
                setMediaFile={setOrderedMediaFile}
                setSubmediaFile={setOrderedSubmediaFile}
                index={index}
                key={index}
                styleFolderDelete={deletingFolders.includes(mediaFolder.id)}
                setMediaFileOrder={setMediaFileOrder}
                setDeletingDocs={setDeletingDocs}
                deletingDocs={deletingDocs}
                deletingSubFolders={deletingSubFolders}
                setDeletingSubFolders={setDeletingSubFolders}
                deletingFolders={deletingFolders}
                setDeletingFolders={setDeletingFolders}
              />
              <div className="separator" />
            </>
          ))}
        </div>
      )}
      <FolderModal
        setIsOpenModal={setShowFolderPopin}
        isOpenModal={showFolderPopin}
        mediaOptions={mediaOptions}
        methods={methods}
      />
    </div>
  );
}
