import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import ArrowUpIcon from 'assets/icons/arrow-up-small.svg';
import ArrowDownIcon from 'assets/icons/arrow-down-small.svg';
import { addDays, startOfDay, endOfDay } from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import { useMediaQuery } from 'usehooks-ts';

export interface IDatePicker {
  label: string;
  value: string | Date;
  onChange: (v: any) => void;
  type: 'connect' | 'create';
}
export default function DatePicker({
  label,
  value,
  onChange,
  type,
}: IDatePicker) {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:767px)');

  function handleOnChange(value) {
    onChange(value);
    setShowDatePicker(false);
  }

  const Ranges: RangeType[] = [
    {
      label: 'today',
      value: [startOfDay(new Date()), endOfDay(new Date())],
      closeOverlay: true,
    },
    {
      label: 'yesterday',
      value: [addDays(new Date(), -1), new Date()],
      closeOverlay: true,
    },
    {
      label: 'Last 7 days',
      value: [addDays(new Date(), -7), new Date()],
      closeOverlay: true,
    },
    {
      label: 'Never',
      value: [('NEVER' as unknown) as Date, ('NEVER' as unknown) as Date],
      closeOverlay: true,
    },
  ];
  return (
    <>
      <div className="b-select2-container">
        <button
          className="btn-select"
          type="button"
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <div className="b-select2-actions">
            <img
              alt="arrow"
              src={true ? ArrowUpIcon : ArrowDownIcon}
              className="toggle-icon"
              width={17}
            />
          </div>
          <span>{label}</span>
        </button>
      </div>
      {showDatePicker ? (
        <DateRangePicker
          placement={isMobile ? 'auto' : 'bottomEnd'}
          defaultOpen
          onChange={handleOnChange}
          format="dd-MM-yyyy"
          character=" to "
          size="xs"
          showOneCalendar
          ranges={Ranges}
        />
      ) : null}
    </>
  );
}
