/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMsal } from '@azure/msal-react';
import React, { useRef, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import logoutIcon from '../../../assets/icons/logout-icon.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import arrowBack from '../../../assets/icons/arrow-back.svg';
import faq from '../../../assets/icons/faq.svg';
import ticket from '../../../assets/icons/ticket.svg';
import elistairLogo from '../../../assets/icons/elistair-logo-small.svg';
import elistairLogoColor from '../../../assets/icons/elistair-logo-color-small.svg';
import menuMobile from '../../../assets/icons/menu-mobile.svg';
import menuMobileBlack from '../../../assets/icons/menu-mobile-black.svg';
import closeMenuMobile from '../../../assets/icons/close-menu-mobile.svg';
import documentIcon from '../../../assets/icons/document-icon.svg';
import trainingMenu from '../../../assets/icons/training-menu.svg';
import menuInfo from '../../../assets/icons/menu-info.svg';
import SettingsBtn from '../SettingsBtn';
import cx from 'classnames';
import HeaderSearchBar from '../Search/HeaderSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch } from '../Search/HeaderSearchBar/slice/selectors';
import { useSearchSlice } from '../Search/HeaderSearchBar/slice';
import { useOnClickOutside } from 'usehooks-ts';
import ListUserIcon from '../../../assets/icons/list-users.svg';
import { selectSessionUserRole } from '../../slice/selectors';
import { E_USER_ROLE } from '../../../types';

export default function CustomHeader(props: {
  addBack?: Boolean;
  isEdit?: Boolean;
  showLink: Boolean;
  hideSearch?: Boolean;
  urlBack?: string;
  invertedColor?: boolean;
}) {
  const refDropdown = useRef(null);
  const [isAdminView] = useState(true);
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const [openSupportDropdown, setOpenSupportDropdown] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { instance } = useMsal();
  const { actions } = useSearchSlice();
  const dispatch = useDispatch();
  const i18nKey = _.dashboard;
  const i18nKeyMenu = _.menu;
  const history = useHistory();
  const role = useSelector(selectSessionUserRole);
  const { search: searchedString } = useSelector(selectSearch);

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    anchor: string,
  ) => {
    if (role === E_USER_ROLE.RESTRICTED_ACCESS) {
      if (
        location.pathname !== '/request' &&
        location.pathname !== '/faq' &&
        location.pathname !== '/article'
      ) {
        e.preventDefault();
        if (location.hash === `#${anchor}`) {
          history.replace(`#`);
          setTimeout(() => {
            history.replace(`#${anchor}`);
          }, 1);
        } else {
          history.replace(`#${anchor}`);
        }
      }
    }
  };

  function handleLogout() {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  }

  function goBack(e) {
    e.preventDefault();
    history.goBack();
    if (searchedString) {
      dispatch(actions.getSearchMedia(''));
    }
  }

  useOnClickOutside(refDropdown, () => setOpenSupportDropdown(false));

  const logo = props.invertedColor ? elistairLogoColor : elistairLogo;
  const menu = props.invertedColor ? menuMobileBlack : menuMobile;
  return (
    <>
      <div className="custom-header-mobile">
        <div className="custom-header-mobile-left">
          <img alt="elistair-logo" src={logo} className="logo" />
          {props.addBack && (
            <button
              className="back-button"
              onClick={e => {
                if (props.urlBack) {
                  history.push(props.urlBack);
                } else {
                  goBack(e);
                }
              }}
            >
              <img alt="back" src={arrowBack} /> <p>BACK</p>
            </button>
          )}
        </div>
        <div className="custom-header-mobile-center">
          {location.pathname === '/training'
            ? t(i18nKey.ctaTraining)
            : location.pathname === '/'
            ? t(i18nKey.ctaDoc)
            : null}
        </div>
        <div
          className={cx(
            !props.invertedColor
              ? 'custom-header-mobile-right'
              : 'custom-header-mobile-right-light',
          )}
          onClick={() => setisMobileMenuOpen(!isMobileMenuOpen)}
        >
          <img alt="elistair-logo" src={menu} className="menu-mobile" />
        </div>
      </div>

      <div className="custom-header">
        <div className="custom-header-left">
          {props.isEdit && (
            <img alt="elistair-logo" src={elistairLogoColor} className="logo" />
          )}
          {props.addBack && (
            <button
              className="back-button"
              onClick={e => {
                if (props.urlBack) {
                  history.push(props.urlBack);
                } else {
                  goBack(e);
                }
              }}
            >
              <img alt="back" src={arrowBack} /> <p>BACK</p>
            </button>
          )}
        </div>
        <div className="custom-header-right">
          {props.showLink && (
            <>
              <Link to="/" onClick={e => handleLinkClick(e, 'anchor-form-1')}>
                <p
                  className={cx(
                    {
                      'custom-header-right-disable':
                        role === E_USER_ROLE.RESTRICTED_ACCESS,
                    },
                    (location.pathname === '/' ||
                      location.pathname === '/product-detail') &&
                      !openSupportDropdown
                      ? 'current'
                      : '',
                  )}
                >
                  {t(i18nKey.ctaDoc)}
                </p>
              </Link>
              <Link
                to={role !== E_USER_ROLE.RESTRICTED_ACCESS ? '/training' : '/'}
                onClick={e => handleLinkClick(e, 'anchor-form-2')}
              >
                <p
                  className={cx(
                    {
                      'custom-header-right-disable':
                        role === E_USER_ROLE.RESTRICTED_ACCESS,
                    },
                    location.pathname === '/training' && !openSupportDropdown
                      ? 'current'
                      : '',
                  )}
                >
                  {t(i18nKey.ctaTraining)}
                </p>
              </Link>
              <div className="dropdown" ref={refDropdown}>
                <div
                  onClick={() => setOpenSupportDropdown(!openSupportDropdown)}
                >
                  <p
                    className={
                      openSupportDropdown ||
                      location.pathname === '/faq' ||
                      location.pathname === '/request'
                        ? 'current'
                        : ''
                    }
                  >
                    {t(i18nKey.ctaSupport)}
                  </p>
                </div>
                {openSupportDropdown ? (
                  <ul className="menu">
                    <li>
                      <Link to="/faq" className="menu-item">
                        <img alt="faq" src={faq} />
                        <p>{t(i18nKey.ctaFAQ)}</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/request" className="menu-item">
                        <img alt="ticket" src={ticket} />
                        <p>{t(i18nKey.ctaRequest)}</p>
                      </Link>
                    </li>
                  </ul>
                ) : null}
              </div>
            </>
          )}

          {!props.hideSearch && <HeaderSearchBar />}
          <SettingsBtn
            invertedColors={props.invertedColor}
            isAdminView={isAdminView}
          />
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="menu-mobile-content">
          <div
            className="menu-mobile-content-header"
            onClick={() => setisMobileMenuOpen(!isMobileMenuOpen)}
          >
            <img
              alt="elistair-logo"
              src={closeMenuMobile}
              className="menu-mobile"
            />
          </div>
          <div className="menu-mobile-content-south">
            {role === E_USER_ROLE.ADMIN || role === E_USER_ROLE.COMM ? (
              <div
                className={cx('menu-mobile-content-south-item', {
                  current: location.pathname === '/',
                })}
              >
                <Link to="/">
                  <img alt="documentation" src={documentIcon} />
                  <p>{t(i18nKeyMenu.documentation)}</p>
                </Link>
              </div>
            ) : null}
            {role === E_USER_ROLE.ADMIN || role === E_USER_ROLE.COMM ? (
              <div className="menu-mobile-content-south-item">
                <Link to="/training">
                  <img alt="training" src={trainingMenu} />
                  <p>{t(i18nKeyMenu.training)}</p>
                </Link>
              </div>
            ) : null}

            {role === E_USER_ROLE.ADMIN || role === E_USER_ROLE.COMM ? (
              <div className="menu-mobile-content-south-item">
                <Link to="/list-user">
                  <img alt="users" src={ListUserIcon} />
                  <p>{t(i18nKeyMenu.listUsers)}</p>
                </Link>
              </div>
            ) : null}
            <div className="menu-mobile-content-south-item support-group-item">
              <div
                className={cx('support-button-mobile', {
                  current:
                    location.pathname === '/faq' ||
                    location.pathname === '/request',
                })}
                onClick={() => setOpenSupportDropdown(!openSupportDropdown)}
              >
                <img alt="training" src={faq} />
                <p>{t(i18nKeyMenu.support)}</p>
              </div>
              {openSupportDropdown ? (
                <div className="support-group-mobile">
                  <Link
                    to="/request"
                    className={cx({
                      current: location.pathname === '/request',
                    })}
                  >
                    <img alt="request" src={ticket} />
                    <p>{t(i18nKeyMenu.request)}</p>
                  </Link>
                  <Link
                    to="/faq"
                    className={cx({
                      current: location.pathname === '/faq',
                    })}
                  >
                    <img alt="faq" src={faq} />
                    <p>{t(i18nKeyMenu.faq)}</p>
                  </Link>
                </div>
              ) : null}
            </div>

            <div className="menu-mobile-content-south-item">
              <Link to="/user-informations">
                <img alt="infos" src={menuInfo} />
                <p>{t(i18nKeyMenu.informations)}</p>
              </Link>
            </div>
            {role === E_USER_ROLE.ADMIN ||
              (role === E_USER_ROLE.COMM && (
                <div className="menu-mobile-content-south-item">
                  <Link to="/list-user">
                    <img alt="infos" src={ListUserIcon} />
                    <p>{t(i18nKeyMenu.listUsers)}</p>
                  </Link>
                </div>
              ))}
            <div
              className="menu-mobile-content-south-item"
              onClick={handleLogout}
            >
              <img alt="sign out" src={logoutIcon} />
              {t(i18nKeyMenu.signout)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
