import CustomHeader from 'app/components/CustomHeader';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import Select from 'app/components/Form/Select';
import { useDispatch, useSelector } from 'react-redux';
import { IUserUpdatePayload } from './slice/types';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toogle from 'app/components/Form/ToggleSwitch';
import { selectAddUserGroups } from '../add-user/slice/selectors';
import Loader from 'app/components/Loader';
import { selectUpdateUser } from './slice/selectors';
import usePrevious from 'utils/hooks/usePrevious';
import { useEditUserSlice } from './slice';
import DeleteModal from './components/DeleteModal';
import { ReactComponent as DeleteUserLogo } from '../../../../assets/icons/menu-delete-normal.svg';
import FormField from 'app/components/Form/FormField';
import { IProduct } from 'app/pages/dashboard/slice/types';
import { RootState } from 'types';
import Checkbox from 'app/components/Form/Checkbox';
import { useDashboardSlice } from 'app/pages/dashboard/slice';
import arrowBack from '../../../../assets/icons/arrow-back.svg';
import { useAddUserSlice } from '../add-user/slice';
import { IGroups } from '../add-user/slice/types';

interface IForm {
  companyName: string;
  firstname: string;
  lastname: string;
  guestEmail: string;
  crmId: string;
  groupId: {
    label: string;
    value: string;
  };
  moodle: boolean;
  quote: boolean;
  deviceAccess: string[];
}

export default function EditUser() {
  const { t } = useTranslation();
  const i18nKey = _.editUser;
  const dispatch = useDispatch();
  const { actions: editUserActions } = useEditUserSlice();
  const [groupsCreate, setGroupsCreate] = useState<IGroups[] | undefined>([]);

  const { data: groups } = useSelector(selectAddUserGroups);
  const { loading, error, success } = useSelector(selectUpdateUser);
  let { user }: any = useLocation()?.state;

  useEffect(() => {
    const validateGroups = [
      'RESTRICTED_ACCESS',
      'INTERNE',
      'ADMIN',
      'COMM',
      'Global Administrator',
    ];
    const groupValidate = groups?.filter(i => {
      return !validateGroups.includes(i.displayName);
    });
    setGroupsCreate(groupValidate);
  }, [groups]);

  let deviceAccess = user?.deviceAccess
    ? user?.deviceAccess?.includes('/')
      ? user.deviceAccess.split('/')
      : [user?.deviceAccess]
    : [];

  const [moduleSelected, setModuleSelected] = useState<string[]>(deviceAccess);

  const productList = useSelector(
    (state: RootState) => state.dashboard?.getProducts.data,
  );

  let groupDefaultValue = groups?.find(
    g => g.displayName === user?.groups?.[0],
  );

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: user.firstname,
      lastname: user.lastname,
      companyName: user.companyName,
      guestEmail: user.email,
      crmId: user.crmId || '',
      quote: user.quote,
      moodle: user.moodle,
      deviceAccess: deviceAccess,
      groupId: {
        value: groupDefaultValue?.id || '',
        label: groupDefaultValue?.displayName || '',
      },
    },
  });
  const {
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = methods;
  let history = useHistory();
  const { actions: actionsDashboard } = useDashboardSlice();
  const { actions: actionsUser } = useAddUserSlice();
  const [modalDelete, setModalDelete] = useState<boolean>(false);

  useEffect(() => {
    reset();
    dispatch(actionsUser.getGroups());
    dispatch(actionsDashboard.getProducts());
  }, []);

  let prevSuccess = usePrevious(success);
  useEffect(() => {
    if (success && success !== prevSuccess) {
      toast.success(t(i18nKey.notification));
      history.goBack();
    }
  }, [success]);

  let prevError = usePrevious(error);
  useEffect(() => {
    if (error && error !== prevError) {
      toast.error(t(_.global.form.errors.error));
    }
  }, [error]);

  function onSubmit(data: IForm) {
    const payload: IUserUpdatePayload = {
      id: user.id,
      companyName: data.companyName,
      firstname: data.firstname,
      guestEmail: user.email,
      lastname: data.lastname,
      crmId: data.crmId || '',
      groupId: data?.groupId?.value,
      moodle: data.moodle || false,
      quote: data.quote || false,
      deviceAccess: moduleSelected,
    };

    dispatch(editUserActions.updateUser(payload));
  }

  let moodle = watch('moodle');

  function handleClickCheckbox(device: IProduct) {
    if (moduleSelected) {
      moduleSelected?.find(m => m === device?.id.toString())
        ? setModuleSelected(
            moduleSelected.filter(i => i !== device.id.toString()),
          )
        : setModuleSelected([...moduleSelected, device.id.toString()]);
    } else {
      setModuleSelected([device.id.toString()]);
    }
    setValue('deviceAccess', ['dirty'], { shouldDirty: true });
  }

  function handleSelectAllProduct() {
    if (productList && moduleSelected.length !== productList.length) {
      let allProduct = productList.map(p => p.id.toString());
      setModuleSelected(allProduct);
    } else if (productList && moduleSelected.length === productList.length) {
      setModuleSelected([]);
    }
    setValue('deviceAccess', ['dirty'], { shouldDirty: true });
  }

  let allCheckedValue = () => {
    let productListId = productList?.map(e => +e.id);
    let moduleSelectedId = moduleSelected.map(m => +m);
    // if no module selected
    if (!moduleSelectedId.length) {
      return false;
    }
    // if all product list is include in module selected
    let result = productListId?.map(p => {
      if (!moduleSelectedId?.includes(p)) {
        return false;
      }
    });
    return result?.includes(false) ? false : true;
  };

  function handleGoBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <div className="add-user">
      <CustomHeader isEdit showLink />
      <div className="add-user-content-page">
        <button className="back-button" onClick={e => handleGoBack(e)}>
          <img alt="back" src={arrowBack} /> <p>BACK</p>
        </button>
        <h2>{t(i18nKey.title)}</h2>
      </div>
      <div className="form-wrapper">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField fieldName="companyName" />
            <FormField fieldName="firstname" />
            <FormField fieldName="lastname" />
            <FormField fieldName="guestEmail" type="email" disabled />
            <FormField fieldName="crmId" required={false} />
            <div className="field small">
              <fieldset>
                <legend>{t(_.global.form.groupIdLabel)}</legend>
                <Controller
                  name="groupId"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      options={
                        groupsCreate?.map(g => ({
                          label: g.displayName,
                          value: g.id,
                        })) || []
                      }
                      clearable
                      placeholder={t(_.global.form.groupIdLabel)}
                    />
                  )}
                />
              </fieldset>
            </div>
            {moodle && (
              <>
                <FormField fieldName="password" />
              </>
            )}
            {/* //TODO a reactivé plus tard */}
            {/* <Controller
              name="quote"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Toogle
                  label="QuoteView"
                  value={value || false}
                  onChange={onChange}
                />
              )}
            /> */}
            <Controller
              name="moodle"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Toogle
                  label="Moodle"
                  value={value || false}
                  onChange={onChange}
                />
              )}
            />
            <p className="device-title">{t(_.addUser.deviceTitle)}</p>
            <div className="container-checkbox">
              <Checkbox
                key={10000}
                label={t(_.addUser.allDevice)}
                id={'all'}
                onClick={() => handleSelectAllProduct()}
                checked={allCheckedValue()}
              />
              {productList
                ? productList?.map(p => {
                    let value = moduleSelected?.includes(p?.id.toString());
                    return (
                      <Checkbox
                        key={p?.id}
                        label={p.translations[0].name}
                        id={p?.id?.toString()}
                        onClick={() => handleClickCheckbox(p)}
                        checked={!!value}
                        name="deviceAccess"
                      />
                    );
                  })
                : null}
            </div>
            <div className="btn-container">
              <button
                className={cx('b-btn', {
                  disabled: loading || !isValid || !isDirty,
                })}
                type="submit"
              >
                {loading ? <Loader size={20} /> : t(i18nKey.cta)}
              </button>
              <button
                className={cx('b-btn secondary delete-btn', {
                  disabled: loading,
                })}
                onClick={() => setModalDelete(true)}
                type="button"
              >
                <DeleteUserLogo
                  className="logo"
                  fill="#BB0505"
                  stroke="#BB0505"
                />
                {t(i18nKey.ctaDelete)}
              </button>
            </div>
          </form>
        </FormProvider>
        <DeleteModal
          isOpenModal={modalDelete}
          setIsOpenModal={setModalDelete}
          users={[user]}
        />
      </div>
    </div>
  );
}
