import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { userInformationsActions as actions } from '.';
import { IUserInfos } from './types';

function* getUserInfos() {
  const requestURL = `/api/login/info`;
  try {
    const response: IUserInfos = (yield call(request, requestURL)).data;
    yield put(actions.getUserInfosSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getUserInfosFailed(err));
  }
}
function* getUserEstimates(data) {
  const requestURL = `/api/media/estimate/${data.payload}`;
  try {
    const response: any = (yield call(request, requestURL)).data;
    yield put(actions.getUserEstimatesSuccess(response));
  } catch (err) {
    console.error('error', err);
    yield put(actions.getUserEstimatessFailed(err));
  }
}
export function* userInformationsSaga() {
  yield takeLatest(actions.getUserInfos.type, getUserInfos);
  yield takeLatest(actions.getUserEstimates.type, getUserEstimates);
}
