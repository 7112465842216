import React, { useState } from 'react';
import ArrowUpIcon from 'assets/icons/arrow-up.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import DownloadIcon from 'assets/icons/download-icon.svg';
import DownloadIconWhite from 'assets/icons/download-icon-white.svg';
import ViewIcon from 'assets/icons/view-icon.svg';
import VideoIcon from 'assets/icons/video-icon.svg';
import VideoIconWhite from 'assets/icons/video-icon-white.svg';
import DocumentIcon from 'assets/icons/document-icon.svg';
import ImgIcon from 'assets/icons/img-icon.svg';
import ImgIconWhite from 'assets/icons/img-icon-white.svg';
import DocumentIconWhite from 'assets/icons/document-icon-white.svg';
import ArrowBackWhite from 'assets/icons/arrow-back-white.svg';
import Modal from 'react-modal';
import navLeft from 'assets/icons/navigate-left.svg';
import navRight from 'assets/icons/navigate-right.svg';
import { MEDIA_TYPES } from 'types';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { translations as _ } from 'locales/translations';
import Vimeo from '@u-wave/react-vimeo';
import Ellipsis from 'react-ellipsis-component';
import { MediaSearchObject } from 'app/pages/dashboard/slice/types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Modal.setAppElement(document.getElementById('root'));

export default function MediaGroup(props: any) {
  const { mediaKey, mediaElements } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [indexNav, setIndexNav] = useState(0);
  const [
    currentElement,
    setCurrentElement,
  ] = useState<MediaSearchObject | null>(null);
  const [numPages, setNumPages] = useState(null);
  const { t } = useTranslation();
  const i18nKey = _.productDetail;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function navigateModal(index) {
    setIndexNav(index);
    setCurrentElement(mediaElements.doc[index]);
  }

  function getIDVideo(url) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    } else {
      return -1;
    }
  }

  function showPrev() {
    return indexNav > 0;
  }

  function showNext() {
    return indexNav < mediaElements.doc.length - 1;
  }

  function getImageType(name, isWhite = false) {
    switch (name.split('.').pop().toLowerCase()) {
      case 'pdf':
        return isWhite ? DocumentIconWhite : DocumentIcon;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return isWhite ? ImgIconWhite : ImgIcon;
      default:
        return isWhite ? VideoIconWhite : VideoIcon;
    }
  }

  function returnExtention(element) {
    return element.split('.').pop().toLowerCase();
  }

  const { innerWidth: width } = window;

  return (
    <div className="media-item">
      <div className="media-item-container">
        <div className="media-item-header">
          <div className="media-item-header-content">
            <img
              alt="arrow"
              src={isOpen ? ArrowUpIcon : ArrowDownIcon}
              className="arrow"
              onClick={() => setIsOpen(!isOpen)}
            />
            <h4 onClick={() => setIsOpen(!isOpen)}>
              {mediaKey}
              <span className="count-files">
                ({mediaElements?.doc?.length})
              </span>
            </h4>
            <div className="details">
              <p className="files">{mediaElements?.doc?.length} files</p>
            </div>
          </div>
        </div>
        {isOpen && (
          <>
            {mediaElements.doc.map((element, index) => {
              return (
                <div className="media-item-content" key={'content-' + index}>
                  <div className="media-item-content-detail">
                    <img
                      alt="documentType"
                      className="document"
                      src={getImageType(element.name)}
                    />
                    <div className="elipsis">
                      <Ellipsis ellipsis text={element.name} maxLine={2} />
                    </div>
                    <img
                      onClick={() => {
                        setCurrentElement(element);
                        setIndexNav(index);
                        setIsOpenModal(true);
                      }}
                      alt="download"
                      src={ViewIcon}
                      className="viewIcon"
                    />
                    {mediaKey !== MEDIA_TYPES.VIDEO && (
                      <a href={element.link} download>
                        <img
                          alt="download"
                          className="download"
                          src={DownloadIcon}
                        />
                      </a>
                    )}
                    {mediaKey === MEDIA_TYPES.VIDEO && (
                      <a
                        href={element.link}
                        target={'_BLANK'}
                        rel="noreferrer"
                        className="link-vimeo-mobile"
                      >
                        <img
                          alt="link vimeo"
                          className="link vimeo"
                          src={ViewIcon}
                        />
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
        {currentElement && (
          <Modal
            isOpen={isOpenModal}
            onRequestClose={() => setIsOpenModal(false)}
            contentLabel="Example Modal"
            className="modalDetail"
            overlayClassName="modalDetail-overlay"
          >
            <div className="modalDetail-north">
              <div className="modalDetail-north-name">
                <img
                  onClick={() => setIsOpenModal(false)}
                  alt="back"
                  src={ArrowBackWhite}
                />
                <img
                  alt="download"
                  id="document"
                  src={getImageType(currentElement?.name, true)}
                />
                {currentElement?.name} ({indexNav + 1}/
                {mediaElements?.doc?.length})
              </div>
              {mediaKey !== MEDIA_TYPES.VIDEO && (
                <div className="modalDetail-north-action">
                  <a
                    className="modalDetail-north-download"
                    href={currentElement?.link}
                    download
                  >
                    <img alt="download" id="download" src={DownloadIconWhite} />{' '}
                    {t(i18nKey.download)}
                  </a>
                </div>
              )}
              {mediaKey === MEDIA_TYPES.VIDEO && (
                <div className="modalDetail-north-action">
                  <a
                    className="modalDetail-north-download"
                    href={currentElement?.link}
                    target={'_BLANK'}
                    rel="noreferrer"
                  >
                    <img
                      alt="download"
                      className="video"
                      src={VideoIconWhite}
                    />{' '}
                    {t(i18nKey.vimeo)}
                  </a>
                </div>
              )}
            </div>
            <div
              className={`modalDetail-south ${
                mediaKey === MEDIA_TYPES.PICTURE &&
                returnExtention(currentElement.name) !== 'pdf'
                  ? 'picture'
                  : ''
              }`}
            >
              <div className="modalDetail-south-left">
                {showPrev() && (
                  <img
                    onClick={() => {
                      navigateModal(indexNav - 1);
                    }}
                    alt="left"
                    src={navLeft}
                  />
                )}
              </div>
              <div className="modalDetail-south-middle">
                {mediaKey === MEDIA_TYPES.PICTURE &&
                  returnExtention(currentElement.name) !== 'pdf' && (
                    <img src={currentElement.link} alt="" />
                  )}
                {mediaKey !== (MEDIA_TYPES.PICTURE && MEDIA_TYPES.VIDEO) &&
                  returnExtention(currentElement.name) === 'pdf' && (
                    <>
                      <Document
                        loading={t(i18nKey.loading)}
                        file={currentElement.link}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="doc-pdf-container"
                      >
                        {[...Array(numPages)].map((el, i) => {
                          return (
                            <Page
                              key={'page' + i}
                              loading=""
                              pageNumber={i + 1}
                              scale={0.65}
                              width={width * 0.8}
                            />
                          );
                        })}
                      </Document>
                    </>
                  )}
                {mediaKey === MEDIA_TYPES.VIDEO && (
                  <Vimeo
                    video={getIDVideo(currentElement.link)}
                    autoplay
                    height={500}
                    volume={0}
                  />
                )}
              </div>
              <div className="modalDetail-south-right">
                {showNext() && (
                  <img
                    onClick={() => {
                      navigateModal(indexNav + 1);
                    }}
                    alt="left"
                    src={navRight}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
