import React, { useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ArrowRight from 'assets/icons/arrow-right-black.svg';
import ArrowDown from 'assets/icons/arrow-down-small.svg';
import { useSelector } from 'react-redux';
import { selectHelpCenter } from '../../slice/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { Categorie } from '../type';

interface CatBtnProps {
  cat: Categorie;
  handleClickCategorie?: (id: string) => void;
  openCategories: string[];
}
export default function CategorieBtn({
  cat,
  handleClickCategorie,
  openCategories,
}: CatBtnProps) {
  const i18nKey = _.support;
  const { t } = useTranslation();
  let navigate = useHistory().push;
  let location = useLocation();
  const [showCatArrow, setShowCatArrow] = useState<string | undefined>();
  const { articles } = useSelector(selectHelpCenter);

  function getArticles(catId: string) {
    let result = articles?.filter(
      art => art.categorieId.toString() === catId.toString(),
    );
    return result?.[0]?.articles.data;
  }

  return (
    <div
      key={cat.id}
      className="block"
      onClick={() => handleClickCategorie && handleClickCategorie(cat.id)}
      onMouseOver={() => setShowCatArrow(cat.id)}
      onMouseOut={() => setShowCatArrow(undefined)}
    >
      <div className="categorie-layout">
        <div>
          <p className="categorie-title">{cat.translatedName}</p>
          <p className="medium-text">
            {t(i18nKey.descCat, {
              categorie: cat.translatedName.toLowerCase(),
            })}
          </p>
          {!openCategories.includes(cat.id) ||
          location.pathname.includes('article') ? (
            <p className="categorie-article">
              {t(i18nKey.articlesCount, {
                nbArticles: cat.allArticleCount,
              })}
            </p>
          ) : null}
        </div>
        {!openCategories.includes(cat.id) ? (
          <img
            alt="arrow-down"
            src={ArrowDown}
            width={30}
            className="arrow-img"
          />
        ) : null}
      </div>
      {openCategories.includes(cat.id) ? (
        <div className="container-article">
          {getArticles(cat.id)?.map(item => (
            <div
              className="article-cta"
              key={item.title}
              onClick={() =>
                navigate('/article', { catId: cat.id, articleId: item.id })
              }
            >
              {item.title}
              <img alt="arrow-right" src={ArrowRight} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
