import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.editUser || initialState;

export const selectUpdateUser = createSelector(
  [selectSlice],
  state => state.updateUser,
);
export const selectDeleteUser = createSelector(
  [selectSlice],
  state => state.deleteUser,
);
