import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { editUserActions as actions } from '.';
import { IUserCreation, IUserUpdatePayload, IUserDeletePayload } from './types';

function* updateUser(action: PayloadAction<IUserUpdatePayload>) {
  const requestURL = `/api/admin/users/update/${action.payload.id}`;
  try {
    const response: IUserCreation = (yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        companyName: action.payload.companyName,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        crmId: action.payload.crmId,
        groupId: action.payload.groupId,
        guestEmail: action.payload.guestEmail,
        quote: action.payload.quote,
        moodle: action.payload.moodle,
        deviceAccess: action.payload.deviceAccess,
      }),
    })).data;
    yield put(actions.updateUserSuccess(response));
  } catch (err) {
    yield put(actions.updateUserFailed(err));
  }
}
function* deleteUser(action: PayloadAction<IUserDeletePayload>) {
  const requestURL = `/api/admin/users/delete/${action.payload}`;

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(actions.deleteUserSuccess());
  } catch (err) {
    console.error('error', err);
    yield put(actions.deleteUserFailed(err));
  }
}

export function* editUserSaga() {
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.deleteUser.type, deleteUser);
}
