import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomHeader from 'app/components/CustomHeader';
import { translations as _ } from '../../../locales/translations';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import { selectSearch } from 'app/components/Search/HeaderSearchBar/slice/selectors';
import MediaGroup from './components/MediaGroup';

export default function Search(props: any) {
  const { t } = useTranslation();
  const i18nKey = _.search;
  const { data, search } = useSelector(selectSearch);

  return (
    <div className="search">
      <div className="search-intro">
        <div className="search-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="search-content">
        <div className="search-header">
          <CustomHeader addBack showLink={true} urlBack="/" />
        </div>
        <div className="search-result">
          <div className="search-result-header-left">
            <h2>{t(i18nKey.title)}</h2>
          </div>
          {!!data?.length ? (
            data?.map((item, index) => {
              return (
                <MediaGroup
                  key={index}
                  mediaKey={item.product}
                  mediaElements={item}
                />
              );
            })
          ) : (
            <>
              <div>Aucun résultat pour “{search}”</div>
              <span>Essayez de changer votre recherche</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
